import React from 'react';
import styled from 'styled-components';

import { Container } from '@/atoms/index';

export const FooterGuest: React.FC = () => (
  <StyledFooter>
    <Container>
      <p>&copy;OceanEyes Co., Ltd All Rights Reserved.</p>
    </Container>
  </StyledFooter>
);

const StyledFooter = styled.div`
  padding: ${(props) => props.theme.pxToRem(30)} 0;
`;
