import React from 'react';
import styled from 'styled-components';

import { MenuItem } from '../MenuItem/MenuItem';

interface Props {
  menu: State.MenuData[];
  onClick: (disabled: boolean) => void;
}

export const ServiceSelectionMenu: React.FC<Props> = ({ menu, onClick }) => {
  return (
    <StyledList role="menu">
      {menu.map((item) => (
        <MenuItem key={item.name} {...item} onClick={onClick} />
      ))}
    </StyledList>
  );
};

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
`;
