import React from 'react';
import { Sidebar as SemanticUISidebar } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  isVisible: boolean;
}

export const Sidebar: React.FC<Props> = ({ children, isVisible }) => {
  return (
    <SemanticUISidebar
      inverted
      vertical
      animation="overlay"
      as={StyledSidebar}
      direction="right"
      visible={isVisible}
      width="wide"
    >
      <StyledSidebarWrapper>{children}</StyledSidebarWrapper>
    </SemanticUISidebar>
  );
};

const StyledSidebar = styled.div`
  background-color: ${(props) => props.theme.colors.neutralBrown};
  padding: ${(props) => props.theme.pxToRem(20)};
`;

const StyledSidebarWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
