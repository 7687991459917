import React from 'react';
import { connect } from 'react-redux';

import { PageHome } from '@/pages/PageHome';
import { AppDispatch, RootState } from '@/state/store';
import { logout } from '@/state/user/actions';
import {
  errorMessageSelector,
  isAuthenticatedSelector,
  isLoadingSelector,
  isUserSetupCompletedSelector,
} from '@/state/user/selectors';

const mapActionCreators = (dispatch: AppDispatch) => ({
  async logout(): Promise<void> {
    await dispatch(logout());
  },
});

const mapStateToProps = (state: RootState) => ({
  errorMessage: errorMessageSelector(state),
  isAuth: isAuthenticatedSelector(state),
  isLoading: isLoadingSelector(state),
  isUserSetupCompleted: isUserSetupCompletedSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageHome);
