import { createSlice } from '@reduxjs/toolkit';

import { initialize } from './actions';

const initialState: State.App = {
  errorMessage: '',
  isInit: false,
  isLoading: false,
  successMessage: '',
};

const appSlice = createSlice({
  extraReducers: {
    // Initialize
    [`${initialize.pending}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${initialize.fulfilled}`]: (state) => ({
      ...state,
      isInit: true,
      isLoading: false,
    }),
    [`${initialize.rejected}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState,
  name: 'app',
  reducers: {},
});

export { appSlice };
