import { DefaultTheme } from 'styled-components';

const htmlFontSize: number = 16;

const fnavi: DefaultTheme = {
  colors: {
    neutralBlack: '#2c3333',
    neutralBlackForIcons: '#BFC1C1',
    neutralBlackFroDescriptions: '#707474',
    neutralBrown: '#f5f2e7',
    neutralGreen: '#56CD67',
    neutralGreenLight: '#b5e8bc',
    neutralGreyForBorders: '#DDDBD6',
    neutralGreyForButtons: '#E0DED4',
    neutralGreyForCardBorders: '#DFDCCF',
    neutralGreyForCheckboxes: '#D0CDC0',
    neutralGreyForHeaders: '#AEA998',
    neutralGreyForInputs: '#D3D3D3',
    neutralGreyForLabels: '#707474',
    neutralGreyForListItemBorders: '#E0DDD1',
    neutralGreyForListItems: '#FCFAF2',
    neutralRed: '#F56161',
    neutralRedLight: '#F5E3DA',
    neutralWhite: '#ffffff',
    primary: '#2666cf',
  },
  fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
  fontSizes: {
    l: '1.5rem',
    m: '1.125rem',
    s: '1rem',
    xl: '2rem',
    xs: '0.875rem',
    xxl: '2.5rem',
    xxs: '0.75rem',
  },
  fontWeights: {
    black: 900,
    bold: 700,
    light: 300,
    medium: 500,
    regular: 400,
    thin: 100,
  },
  htmlFontSize,

  pxToRem: (px: number): string => `${px / htmlFontSize}rem`,
};

export { fnavi };
