import React from 'react';
import { connect } from 'react-redux';

import { PageUserAddContract } from '@/pages/PageUserAddContract';
import { cardListSelector } from '@/state/card/selectors';
import { redirectToCheckout } from '@/state/contract/actions';
import { isLoadingSelector as isContractLoadingSelector } from '@/state/contract/selectors';
import { getProduct, setProductData } from '@/state/product/actions';
import { isLoadingSelector, productDataSelector, productUnPurchasedListSelector } from '@/state/product/selectors';
import { AppDispatch, RootState } from '@/state/store';

const mapActionCreators = (dispatch: AppDispatch) => ({
  clearProduct(): void {
    dispatch(setProductData(undefined));
  },
  getProduct(uid: string): void {
    dispatch(getProduct(uid));
  },
  redirectToCheckout(params: { productId: string; prices: State.PriceData[] }): void {
    dispatch(redirectToCheckout(params));
  },
});

const mapStateToProps = (state: RootState) => ({
  cardList: cardListSelector(state),
  isContractLoading: isContractLoadingSelector(state),
  isLoading: isLoadingSelector(state),
  productData: productDataSelector(state),
  unPurchasedProductList: productUnPurchasedListSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageUserAddContract);
