import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal as SemanticUIModal, ModalProps } from 'semantic-ui-react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';

import { Button, ButtonVariants } from '@/atoms/index';
import { Translations } from '@/constants/index';

export enum ModalVariants {
  PRIMARY = 'primary',
  DANGER = 'danger',
}

interface Props extends ModalProps {
  testId: string;
  description?: string;
  icon?: string;
  isAction?: boolean;
  title?: string;
  variant?: ModalVariants;
  cancelNode?: React.ReactNode;
  confirmNode?: React.ReactNode;
  descriptionNode?: React.ReactNode;
  onCloseModal?: () => void;
}

export const Modal: React.FC<Props> = ({ testId, ...rest }) => {
  const { t } = useTranslation();
  const { cancelNode, confirmNode, description, descriptionNode, icon, isAction, title, onCloseModal, ...props } = rest;

  const renderConfirmNode = (): React.ReactNode => {
    if (confirmNode) {
      return confirmNode;
    }

    return (
      <Button
        testId="confirm-modal"
        variant={props.variant === ModalVariants.PRIMARY ? ButtonVariants.PRIMARY : ButtonVariants.DANGER}
        onClick={onCloseModal}
      >
        {t(Translations.BUTTON_OK)}
      </Button>
    );
  };

  return (
    <StyledModal {...props}>
      <StyledTitleWrapper variant={props.variant}>
        {icon && <img alt="icon" src={icon} />}
        {title && <p>{title}</p>}
      </StyledTitleWrapper>

      <StyledDescriptionWrapper>
        {description && <StyledDescription>{description}</StyledDescription>}

        {Boolean(descriptionNode) && descriptionNode}
      </StyledDescriptionWrapper>

      {Boolean(isAction) && (
        <StyledModal.Actions>
          {Boolean(cancelNode) && cancelNode}

          {renderConfirmNode()}
        </StyledModal.Actions>
      )}

      {!isAction && (
        <StyledButtonWrapper>
          {Boolean(cancelNode) && cancelNode}

          {renderConfirmNode()}
        </StyledButtonWrapper>
      )}
    </StyledModal>
  );
};

const StyledModal = styled(SemanticUIModal)<ThemedStyledProps<Props, DefaultTheme>>`
  &&& {
    ${(props) =>
      props?.variant === ModalVariants.PRIMARY
        ? `
    border-top: ${props.theme.pxToRem(7)} solid ${props.theme.colors.primary};
    `
        : ``};

    ${(props) =>
      props?.variant === ModalVariants.DANGER
        ? `
    border-top: ${props.theme.pxToRem(7)} solid ${props.theme.colors.neutralRed};
    `
        : ``};

    background-color: ${(props) => props.theme.colors.neutralBrown};
    padding: ${(props) => props.theme.pxToRem(25)};

    .actions {
      background-color: transparent;
      border-top: ${(props) => `${props.theme.pxToRem(2)} solid ${props.theme.colors.neutralGreyForListItemBorders}`};
      margin-left: ${(props) => props.theme.pxToRem(-25)};
      margin-right: ${(props) => props.theme.pxToRem(-25)};
      margin-top: ${(props) => props.theme.pxToRem(30)};
      padding-left: ${(props) => props.theme.pxToRem(25)} !important;
      padding-right: ${(props) => props.theme.pxToRem(25)} !important;
      padding-top: ${(props) => props.theme.pxToRem(25)} !important;

      button {
        margin: 0;

        :first-child {
          margin-right: ${(props) => props.theme.pxToRem(10)};
        }
      }
    }
  }
`;

const StyledTitleWrapper = styled.div<{ variant?: ModalVariants }>`
  margin-bottom: ${(props) => props.theme.pxToRem(40)};
  ${(props) => `
    color: ${
      props?.variant === ModalVariants.PRIMARY
        ? props.theme.colors.primary
        : props?.variant === ModalVariants.DANGER
        ? props.theme.colors.neutralRed
        : props.theme.colors.neutralBlack
    };
  `}

  img,
  p {
    display: inline-block;
    vertical-align: bottom;
  }

  img {
    margin-right: ${(props) => props.theme.pxToRem(10)};
  }

  p {
    font-size: ${(props) => props.theme.fontSizes.m};
    font-weight: ${(props) => props.theme.fontWeights.black};
  }
`;

const StyledDescriptionWrapper = styled.div``;

const StyledDescription = styled.p`
  color: ${(props) => props.theme.colors.neutralBlack};
  font-size: ${(props) => props.theme.fontSizes.m};
`;

const StyledButtonWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(40)};
  text-align: right;
`;
