import { createSelector } from '@reduxjs/toolkit';

import { contractListSelector } from '../contract/selectors';
import { RootState } from '../store';

const rootSelector = createSelector(
  (state: RootState): State.Product => state.product,
  (product: State.Product) => product
);

export const productListSelector = createSelector(
  rootSelector,
  (product: State.Product): State.ProductData[] => product.list
);

export const productDataSelector = createSelector(
  rootSelector,
  (product: State.Product): State.ProductData | undefined => product.data
);

export const productUnPurchasedListSelector = createSelector(
  [productListSelector, contractListSelector],
  (productList: State.ProductData[], contractList: State.ContractData[]) =>
    productList.filter((product) => !contractList.find((contract) => product.id === contract?.product?.id))
);

export const errorMessageSelector = createSelector(
  rootSelector,
  (product: State.Product): string => product.errorMessage
);

export const isLoadingSelector = createSelector(rootSelector, (product: State.Product): boolean => product.isLoading);

export const successMessageSelector = createSelector(
  rootSelector,
  (product: State.Product): string => product.successMessage
);

export const imageSelector = createSelector(rootSelector, (product: State.Product): string => product.imageFile);
