import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const rootSelector = createSelector(
  (state: RootState): State.Invoice => state.invoice,
  (invoice: State.Invoice) => invoice
);

export const invoiceListSelector = createSelector(
  rootSelector,
  (invoice: State.Invoice): State.InvoiceData[] => invoice.list
);

export const errorMessageSelector = createSelector(
  rootSelector,
  (invoice: State.Invoice): string => invoice.errorMessage
);

export const isLoadingSelector = createSelector(rootSelector, (invoice: State.Invoice): boolean => invoice.isLoading);

export const successMessageSelector = createSelector(
  rootSelector,
  (invoice: State.Invoice): string => invoice.successMessage
);
