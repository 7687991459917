import { createAsyncThunk } from '@reduxjs/toolkit';

import firebase from '@/firebase/index';
import { Invoice as InvoiceService } from '@/services/index';

import { InvoiceActions } from './constants';

export const getInvoices = createAsyncThunk(InvoiceActions.GET_INVOICE_LIST, async (_, thunkAPI) => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
      throw new Error('Failed to retrieve current user');
    }

    const invoiceRes = await InvoiceService.list(token);
    if (invoiceRes.status !== 200) {
      throw new Error('Failed to retrieve invoices');
    }

    // remove invoices with trial period
    const result = invoiceRes.data.filter((invoice) => Boolean(invoice.total));

    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject();
  }
});
