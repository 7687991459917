import React from 'react';
import { useTranslation } from 'react-i18next';

import { Translations } from '@/constants/index';
import ContainerServiceSelectionSidebar from '@/containers/ContainerServiceSelectionSidebar';
import { CardUsages } from '@/molecules/index';
import { FooterAuth, HeaderWithNav } from '@/organisms/index';
import { PageWithSidebar, SingleColumn } from '@/templates/index';

interface Props {
  openSidebar: () => void;
}

export const PageUserUsages: React.FC<Props> = ({ openSidebar }) => {
  const { t } = useTranslation();

  return (
    <PageWithSidebar>
      <ContainerServiceSelectionSidebar />
      <SingleColumn>
        <>
          <HeaderWithNav title={t(Translations.TITLE_USAGE)} onClickBurger={openSidebar} />
        </>
        <>
          <CardUsages />
        </>
        <>
          <FooterAuth />
        </>
        /.
      </SingleColumn>
    </PageWithSidebar>
  );
};
