import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { ButtonLink, ButtonVariants, Title } from '@/atoms/index';
import { Routes } from '@/constants/index';
import { HomeIcon } from '@/resources/icons';
import { getPath } from '@/utils/index';

interface Props {
  title: string;
}

export const HeaderWithHome: React.FC<Props> = ({ title }) => (
  <>
    <StyledGrid columns={2}>
      <StyledGridColumn textAlign="left">
        <Title as="h2">{title}</Title>
      </StyledGridColumn>
      <StyledGridColumn textAlign="right">
        <StyledHelper />

        <StyledButtonLink testId="back" to={getPath(Routes.HOME)} variant={ButtonVariants.BOXED}>
          <StyledHelper />
          <StyledImage alt="back" src={HomeIcon} />
        </StyledButtonLink>
      </StyledGridColumn>
    </StyledGrid>
  </>
);

const StyledImage = styled.img`
  cursor: pointer;
  vertical-align: middle;
`;

const StyledGrid = styled(Grid)`
  && {
    margin: 0;
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&& {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`;

const StyledButtonLink = styled(ButtonLink)`
  &&& {
    padding: ${(props) => props.theme.pxToRem(8)};
    margin: 0;
  }
`;
