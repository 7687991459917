import React from 'react';
import { createGlobalStyle, ThemeProvider as StyledThemeProvider } from 'styled-components';

import { fnavi } from './fnavi';
import { global } from './global';
import { AppMedia, media } from './media';
import { normalize } from './normalize';

interface Props {
  children: React.ReactChild;
}

const CSSReset = createGlobalStyle`${normalize} ${global} ${media}`;
const { MediaContextProvider } = AppMedia;

export const ThemeProvider: React.FC<Props> = ({ children }) => (
  <MediaContextProvider>
    <StyledThemeProvider theme={fnavi}>
      <CSSReset /> <>{children}</>
    </StyledThemeProvider>
  </MediaContextProvider>
);
