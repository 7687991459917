import { createSlice } from '@reduxjs/toolkit';

import { setSelectedRegion } from './actions';
import { ServiceMenu } from './constants';

const initialState: State.Menu = {
  list: ServiceMenu,
  selectedRegion: '',
};

const menuSlice = createSlice({
  extraReducers: {
    // Set Selected Region
    [`${setSelectedRegion.type}`]: (state, action: { payload: string }) => ({
      ...state,
      selectedRegion: action.payload,
    }),
  },
  initialState,
  name: 'menu',
  reducers: {},
});

export { menuSlice };
