import React from 'react';
import { Card as SemanticUICard, CardProps } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props extends CardProps {
  testId: string;
  right?: React.ReactNode;
}

export const Card: React.FC<Props> = ({ testId, ...props }) => {
  const { description, header, meta, right, ...rest } = props;

  return (
    <StyledCard data-testid={`card-${testId}`} {...rest}>
      <SemanticUICard.Content>
        {right && <StyledRightContent>{right}</StyledRightContent>}

        {header && <StyledCardHeader>{header}</StyledCardHeader>}
        {meta && <StyledCardMeta>{meta}</StyledCardMeta>}
        {description && <StyledCard.Description>{description}</StyledCard.Description>}
      </SemanticUICard.Content>
    </StyledCard>
  );
};

const StyledCard = styled(SemanticUICard)`
  &&& {
    background-color: ${(props) => props.theme.colors.neutralGreyForListItems};
    border: ${(props) => `${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGreyForCardBorders}`};
    box-shadow: none;

    .content {
      .header,
      .meta {
        color: ${(props) => props.theme.colors.neutralBlack};
      }

      .description {
        color: ${(props) => props.theme.colors.neutralBlackFroDescriptions};
        font-size: ${(props) => props.theme.fontSizes.s};
      }

      .meta {
        font-weight: ${(props) => props.theme.fontWeights.black};
      }
    }
  }
`;

const StyledCardHeader = styled(SemanticUICard.Header)`
  &&& {
    color: ${(props) => props.theme.colors.neutralBlack};
  }
`;

const StyledCardMeta = styled(SemanticUICard.Meta)`
  &&& {
    color: ${(props) => props.theme.colors.neutralBlack};
  }
`;

const StyledRightContent = styled.div`
  float: right;
`;
