import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import i18next from 'i18next';

import { Translations } from '@/constants/index';
import firebase from '@/firebase/index';
import { Intent as IntentService, PaymentMethod as PaymentMethodService, Session } from '@/services/index';

import { RootState } from '../store';
import { userDataSelector } from '../user/selectors';
import { CardActions } from './constants';

export const setCardDateRetrieved = createAction<string | undefined>(CardActions.SET_CARD_DATE_RETRIEVED);

export const setCardList = createAction<State.CardData[]>(CardActions.SET_CARD_LIST);

export const setCardErrorMessage = createAction<string>(CardActions.SET_CARD_ERROR_MESSAGE);

export const setCardSuccessMessage = createAction<string>(CardActions.SET_CARD_SUCCESS_MESSAGE);

export const createCard = createAsyncThunk(
  CardActions.CREATE_CARD,
  async (
    params: {
      name: string;
      stripe: Stripe;
      elementComponent: StripeCardNumberElement;
      clearForm: () => void;
      billing: {
        city: string;
        country: string;
        line1: string;
        line2?: string;
        state?: string;
      };
    },
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const userData = userDataSelector(state);

      const token = await firebase.auth().currentUser?.getIdToken();
      if (!token) {
        throw new Error('User does not exist');
      }

      const paymentIntentRes = await IntentService.create(token);
      if (paymentIntentRes.status !== 201) {
        throw new Error('Failed to create setup intent');
      }

      if (paymentIntentRes.data.client_secret) {
        const { stripe, elementComponent } = params;
        const confirmedIntent = await stripe.confirmCardSetup(paymentIntentRes.data.client_secret, {
          payment_method: {
            billing_details: {
              address: {
                city: params.billing.city,
                country: params.billing.country || 'JP',
                line1: params.billing.line1,
                line2: params.billing?.line2,
                state: params.billing?.state,
              },
              email: userData?.email,
              name: params.name,
            },
            card: elementComponent,
          },
        });

        if (confirmedIntent.error) {
          throw new Error(i18next.t(Translations.ERROR_CARD_INVALID));
        }

        const checkDuplicateRes = await PaymentMethodService.deleteDuplicate({
          id: confirmedIntent.setupIntent.payment_method as string,
          token,
        });
        if (checkDuplicateRes.status !== 200) {
          throw new Error('Failed to create setup intent');
        }

        if (checkDuplicateRes.data.isDuplicate) {
          throw new Error(i18next.t(Translations.ERROR_CARD_IS_ALREADY_REGISTERED));
        }
      }
      // clear form
      params.clearForm();

      await thunkAPI.dispatch(getCards());

      return Promise.resolve({ message: i18next.t(Translations.SUCCESS_CARD_REGISTRATION) });
    } catch (error) {
      return Promise.reject(i18next.t(Translations.ERROR_CONTRACT_FAILED_TO_UPDATE));
    }
  }
);

export const deleteCard = createAsyncThunk(CardActions.DELETE_CARD, async (params: { id: string }, thunkAPI) => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
      throw new Error('Failed to retrieve current user');
    }

    const paymentMethodRes = await PaymentMethodService.deleteCard({ id: params.id, token });
    if (paymentMethodRes.status !== 200) {
      throw new Error('Failed to delete the card');
    }

    await thunkAPI.dispatch(getCards());

    return Promise.resolve({ message: 'Card successfully deleted' });
  } catch (error) {
    return Promise.reject();
  }
});

export const getCards = createAsyncThunk(CardActions.GET_CARD_LIST, async () => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
      throw new Error('Failed to retrieve current user');
    }

    const paymentMethodRes = await PaymentMethodService.list(token);
    if (paymentMethodRes.status !== 200) {
      throw new Error('Failed to retrieve payment methods');
    }

    return Promise.resolve(paymentMethodRes.data);
  } catch (error) {
    return Promise.reject();
  }
});

export const redirectToCheckout = createAsyncThunk(CardActions.REDIRECT_TO_CHECKOUT, async (_) => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken();
    if (!token) {
      throw new Error('Failed to retrieve current user');
    }

    const session = await Session.createSetup({
      cancelUrl: `${window.location.origin}/user/cards`,
      locale: i18next.language === 'jp' ? 'ja' : 'auto',
      successUrl: `${window.location.origin}/user/cards`,
      token,
    });

    if (session.status !== 200) {
      throw new Error('Failed to create setup session');
    }

    window.location.assign(session.data.url);

    return Promise.resolve();
  } catch (error) {
    return Promise.reject();
  }
});
