import { createMedia } from '@artsy/fresnel';

enum AppBreakpoints {
  Desktop = 'desktop',
  LargeScreen = 'largeScreen',
  Mobile = 'mobile',
  Tablet = 'tablet',
  Widescreen = 'wideScreen',
}

const AppMedia = createMedia({
  breakpoints: {
    [AppBreakpoints.Desktop]: 992,
    [AppBreakpoints.LargeScreen]: 1200,
    [AppBreakpoints.Mobile]: 320,
    [AppBreakpoints.Tablet]: 768,
    [AppBreakpoints.Widescreen]: 1920,
  },
});

const media = AppMedia.createMediaStyle();

export { AppBreakpoints, AppMedia, media };
