import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { Title } from '@/atoms/index';

interface Props {
  successMessage?: string;
  errorMessage?: string;
}

export const CheckoutResult: React.FC<Props> = ({ successMessage, errorMessage }) => {
  return (
    <Grid style={{ height: '70vh' }} textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <StyledTextWrapper>
          {Boolean(successMessage) && (
            <>
              <Title>Successfully Checkout</Title>
              <p>{successMessage}</p>
            </>
          )}
          {Boolean(errorMessage) && (
            <>
              <Title>There is a problem with your checkout</Title>
              <p>{errorMessage}</p>
            </>
          )}
        </StyledTextWrapper>
      </Grid.Column>
    </Grid>
  );
};

const StyledTextWrapper = styled.div`
  p {
    color: ${(props) => props.theme.colors.neutralBlackForIcons};
  }
`;
