import React from 'react';
import { Navigate } from 'react-router-dom';

import { Routes as FNaviRoutes } from '@/constants/index';
import { getPath } from '@/utils/index';

interface Props {
  isAuth: boolean;
  children: React.ReactElement;
  isUserSetupCompleted?: boolean;
}

export const PrivateRoute: React.FC<Props> = ({ isAuth, children }) => {
  return isAuth ? children : <Navigate replace to={getPath(FNaviRoutes.LOGIN)} />;
};

export const PublicRoute: React.FC<Props> = ({ isAuth, children, isUserSetupCompleted }) => {
  if (!isAuth) {
    return children;
  }

  const path = isUserSetupCompleted ? getPath(FNaviRoutes.USER_USAGES) : getPath(FNaviRoutes.USER_SETTINGS);

  return <Navigate replace to={path} />;
};
