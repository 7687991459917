import { Translations } from '@/constants/index';

export const id = {
  translation: {
    [Translations.BUTTON_ADD_CARD]: 'Tambah Kartu',
    [Translations.BUTTON_BACK]: 'Kembali',
    [Translations.BUTTON_CANCEL]: 'Batal',
    [Translations.BUTTON_CONTRACTS]: 'Setelan / Pembelian',
    [Translations.BUTTON_LOG_IN]: 'Masuk',
    [Translations.BUTTON_NEXT]: 'Berikutnya',
    [Translations.BUTTON_REGISTER]: 'Daftar',
    [Translations.BUTTON_RETURN]: 'Kembali',
    [Translations.BUTTON_OK]: 'OK',
    [Translations.BUTTON_OKAY]: 'OK',
    [Translations.BUTTON_PURCHASE]: 'Beli',
    [Translations.BUTTON_SAVE]: 'Simpan',
    [Translations.BUTTON_UPDATE]: 'Pembaruan',

    [Translations.DESCRIPTION_DELETE_CARD]: 'Anda yakin ingin menghapus kartu Anda?',
    [Translations.DESCRIPTION_LEGAL_AGREEMENTS]: 'Jika Anda melanjutkan, Anda menyetujui _ dan _kami',
    [Translations.DESCRIPTION_NO_CONTRACTS]: 'Tambahkan sekarang untuk melihat kontrak info detail Anda di sini',
    [Translations.DESCRIPTION_NO_REGISTERED_CARD]: 'Silakan daftarkan kartu kredit Anda di halaman Pengaturan.',
    [Translations.DESCRIPTION_UNPURCHASED_PRODUCT]: 'Silakan beli opsi dari Pengaturan/ Pembelian di Halaman Atas.',

    [Translations.ERROR_CARD_INVALID]:
      'Maaf, ada masalah dengan kartu Anda. Silakan hubungi penerbit kartu Anda',
    [Translations.ERROR_CARD_IS_ALREADY_REGISTERED]: 'Kartu sudah terdaftar. Silakan gunakan kartu lain',
    [Translations.ERROR_CONTRACT_FAILED_TO_UPDATE]: 'Kontrak gagal diperbarui',
    [Translations.ERROR_ADDRESS_MAX_CHARACTERS]: 'Alamat hanya boleh maksimal 128 karakter.',
    [Translations.ERROR_EMAIL_IS_ALREADY_TAKEN]: 'Email sudah diambil',
    [Translations.ERROR_INVALID_CREDENTIALS]: 'Kredensial tidak valid',
    [Translations.ERROR_INVALID_PHONE_NO]: 'Harap cantumkan nomor telepon yang valid.',
    [Translations.ERROR_FIELD_IS_REQUIRED]: 'wajib.',
    [Translations.ERROR_FULL_WIDTH_DIGIT]: 'Digit lebar penuh tidak diizinkan',
    [Translations.ERROR_NAME_MAX_CHARACTERS]: 'Nama lengkap maksimal hanya 128 karakter.',
    [Translations.ERROR_NO_ACCESS]: 'Tidak Ada Akses',
    [Translations.ERROR_NO_CARD]: 'Tidak Ada Kartu',
    [Translations.ERROR_ORG_MAX_CHARACTERS]: 'Organisasi hanya boleh maksimal 128 karakter.',
    [Translations.ERROR_PASSWORD_MINIMUM_CHARACTERS]: 'Kata sandi harus minimal 8 karakter.',
    [Translations.ERROR_PHONE_NO_MAX_DIGITS]: 'Nomor telepon. harus maksimal 11 digit saja.',
    [Translations.ERROR_PLEASE_RE_LOGIN]: 'Silakan masuk kembali',
    [Translations.ERROR_SOMETHING_WENT_WRONG]: 'Ada yang tidak beres',
    [Translations.ERROR_USER_DOES_NOT_EXIST]: 'Pengguna tidak ada',
    [Translations.ERROR_USER_IS_ALREADY_EXISTING]: 'Pengguna sudah ada',

    [Translations.LABEL_ADDITIONAL]: 'Tambahan',
    [Translations.LABEL_ADDRESS]: 'Alamat',
    [Translations.LABEL_AUTO_UPDATE]: 'Pembaruan Otomatis',
    [Translations.LABEL_BASIC]: 'Dasar',
    [Translations.LABEL_BILLING]: 'Penagihan',
    [Translations.LABEL_BUILDING]: 'Bangunan',
    [Translations.LABEL_CARD_BILLING_ADDRESS]: 'Alamat Penagihan',
    [Translations.LABEL_CARD_BILLING_CITY]: 'Kota',
    [Translations.LABEL_CARD_BILLING_COUNTRY]: 'Negara',
    [Translations.LABEL_CARD_BILLING_LINE_ONE]: 'Baris Satu',
    [Translations.LABEL_CARD_BILLING_LINE_TWO]: 'Baris Dua',
    [Translations.LABEL_CARD_BILLING_STATE]: 'Negara Bagian',
    [Translations.LABEL_CARD_CVC]: 'Kartu Cvc',
    [Translations.LABEL_CARD_DETAILS]: 'Detail Kartu',
    [Translations.LABEL_CARD_EXPIRATION]: 'Tanggal Kedaluwarsa Kartu',
    [Translations.LABEL_CARD_NOT_REGISTERED]: 'Tidak Ada Kartu yang Terdaftar',
    [Translations.LABEL_CARD_NUMBER]: 'Nomor Kartu',
    [Translations.LABEL_CREATE_CONTACT]: 'Tambahkan Area Laut',
    [Translations.LABEL_DATE]: 'Tanggal',
    [Translations.LABEL_DELETE_CARD]: 'Hapus Kartu',
    [Translations.LABEL_EDIT_BILLING]: 'Edit Penagihan',
    [Translations.LABEL_EMAIL]: 'E-mail',
    [Translations.LABEL_EMAIL_ADDRESS]: 'Alamat Email',
    [Translations.LABEL_ITEM]: 'Item',
    [Translations.LABEL_FULL_NAME]: 'Nama lengkap',
    [Translations.LABEL_MUNICIPALITIES]: 'Kotamadya',
    [Translations.LABEL_NAME]: 'Nama',
    [Translations.LABEL_NEW_ACCOUNT]: 'Akun Baru',
    [Translations.LABEL_NEXT_UPDATE]: 'Pembaruan Berikutnya',
    [Translations.LABEL_LOGOUT]: 'Keluar',
    [Translations.LABEL_ORGANIZATION]: 'Organisasi',
    [Translations.LABEL_PHONE_NO]: 'Telepon',
    [Translations.LABEL_PASSWORD]: 'Kata Sandi',
    [Translations.LABEL_PRICE]: 'Harga',
    [Translations.LABEL_POST_CODE]: 'Kode Pos',
    [Translations.LABEL_PREFECTURES]: 'Prefektur',
    [Translations.LABEL_PURCHASE_ITEMS]: 'Item yang Dibeli',
    [Translations.LABEL_PURCHASE_CONFIRMATION_DETAILS]: 'Konfirmasi detail pembelian',
    [Translations.LABEL_REGION]: 'Wilayah',
    [Translations.LABEL_REGION_NAME]: 'Nama Wilayah',
    [Translations.LABEL_SEA_AREA_SELECTION]: 'Pemilihan Wilayah Laut',
    [Translations.LABEL_SERVICE]: 'Layanan',
    [Translations.LABEL_SERVICE_SELECTION]: 'Pemilihan Layanan',
    [Translations.LABEL_SETTINGS]: 'Setelan',
    [Translations.LABEL_TELEPHONE_NO]: 'Nomor Telepon',
    [Translations.LABEL_TOP_PAGE]: 'Buka Halaman Atas',
    [Translations.LABEL_TOTAL]: 'Total',
    [Translations.LABEL_USAGE]: 'Penggunaan',
    [Translations.LABEL_UNPURCHASED_PRODUCT]: 'Opsi Produk yang Tidak Dibeli',
    [Translations.LABEL_YEN_MONTH]: 'yen/bulan',

    [Translations.MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST]: 'SST Penghapusan Cloud Saat Ini',
    [Translations.MENU_LABEL_CURRENT_FRONT]: 'Depan Saat Ini',
    [Translations.MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP]: 'Suhu air tingkat menengah saat ini',
    [Translations.MENU_LABEL_CURRENT_SATELLITE_SST]: 'SST Satelit Saat Ini',
    [Translations.MENU_LABEL_CURRENT_TIDE]: 'Gelombang Saat Ini',
    [Translations.MENU_LABEL_TOMORROWS_FRONT]: "Besok Depan",
    [Translations.MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP]: "Suhu tengah laut besok",
    [Translations.MENU_LABEL_TOMORROWS_TIDE]: "Gelombang Besok",
    [Translations.MENU_LABEL_TOM_SEA_SURFACE_TEMP]: "Suhu Air Permukaan Laut Besok",

    [Translations.MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST_DESC]:
      'Suhu permukaan air laut dengan area tertutup awan dilengkapi dengan teknologi AI',
    [Translations.MENU_LABEL_CURRENT_FRONT_DESC]:
      'Perkiraan arus depan berdasarkan data yang dihitung oleh superkomputer',
    [Translations.MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP_DESC]:
      'Suhu air tingkat menengah saat ini seperti yang dihitung oleh superkomputer (20m)',
    [Translations.MENU_LABEL_CURRENT_SATELLITE_SST_DESC]:
      'Suhu permukaan air laut dari satelit geostasioner Himawari',
    [Translations.MENU_LABEL_CURRENT_TIDE_DESC]: 'Gelombang saat ini seperti yang dihitung oleh superkomputer (0m or 20m)',
    [Translations.MENU_LABEL_TOMORROWS_FRONT_DESC]:
      "Perkiraan masa depan berdasarkan data yang dihitung oleh superkomputer",
    [Translations.MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP_DESC]:
      "Suhu air tingkat menengah besok seperti yang dihitung oleh superkomputer (20m)",
    [Translations.MENU_LABEL_TOMORROWS_TIDE_DESC]: "Gelombang besok seperti yang dihitung oleh superkomputer (0m atau 20m)",
    [Translations.MENU_LABEL_TOM_SEA_SURFACE_TEMP_DESC]:
      "Suhu permukaan air laut besok seperti yang dihitung oleh superkomputer",

    [Translations.LINK_CONTACT]: 'Kontak',
    [Translations.LINK_FORGOT_PASSWORD]: 'Lupa kata sandi Anda?',
    [Translations.LINK_PRIVACY_POLICY]: 'Kebijakan Privasi',
    [Translations.LINK_TERMS_OF_SERVICE]: 'Persyaratan Layanan',

    [Translations.PLACEHOLDER_EMAIL]: 'Masukkan Email',
    [Translations.PLACEHOLDER_NAME]: 'Masukkan Nama',
    [Translations.PLACEHOLDER_PASSWORD]: 'Masukkan Kata Sandi',
    [Translations.PLACEHOLDER_SELECT_PRODUCT]: 'Pilih produk',

    [Translations.PRODUCT_ITEM_BASIC]: 'Dasar',
    [Translations.PRODUCT_ITEM_FRONT]: 'Depan',
    [Translations.PRODUCT_ITEM_MIDDLE_LAYER_WATER_TEMPERATURE]: 'Suhu air lapisan tengah',
    [Translations.PRODUCT_ITEM_TIDE]: 'Pasang',

    [Translations.PRODUCT_NAME_KYOTO]: 'Kyoto',
    [Translations.PRODUCT_NAME_SAGA_GENKAI]: 'Saga Genkai',
    [Translations.PRODUCT_NAME_KAGAWA]: 'Kagawa',
    [Translations.PRODUCT_NAME_CHIBA_SATOBO]: 'Chiba Sotobo',
    [Translations.PRODUCT_NAME_CHIBA_UCHIBO]: 'Chiba Uchibo',
    [Translations.PRODUCT_NAME_CHIBA_KUJUKURI]: 'Chiba Kujukuri',
    [Translations.PRODUCT_NAME_ARIAKE]: 'Ariake',
    [Translations.PRODUCT_NAME_YATSUSHIRO]: 'Yatsushiro',
    [Translations.PRODUCT_NAME_KAGOSHIMA_WEST]: 'Kagoshima West',
    [Translations.PRODUCT_NAME_SAGAMIWAN]: 'Sagamiwan Bay',
    [Translations.PRODUCT_NAME_OSHIKA]: 'Oshika',
    [Translations.PRODUCT_NAME_ENSYUNADA]: 'Ensyunada',
    [Translations.PRODUCT_NAME_SURUGAWAN]: 'Surugawan',
    [Translations.PRODUCT_NAME_NAGASAKI]: 'Nagasaki',
    [Translations.PRODUCT_NAME_YAMAGUCHI]: 'Yamaguchi',
    [Translations.PRODUCT_NAME_KOCHI]: 'Kochi',
    [Translations.PRODUCT_NAME_WAKAYAMA]: 'Wakayama',
    [Translations.PRODUCT_NAME_HACHIJO]: 'Hachijo',
    [Translations.PRODUCT_NAME_TSUSHIMA]: 'Tsushima',
    [Translations.PRODUCT_NAME_CHIBA_KATSUURA]: 'Chiba Katsuura',
    [Translations.PRODUCT_NAME_IBARAKI]: 'Ibaraki',
    [Translations.PRODUCT_NAME_KOCHI_WEST]: 'Kochi West',
    [Translations.PRODUCT_NAME_IZU_EAST]: 'Izu East',
    [Translations.PRODUCT_NAME_IZU_SOUTH]: 'Izu South',
    [Translations.PRODUCT_NAME_MIYAKEJIMA]: 'Miyakejima',
    [Translations.PRODUCT_NAME_TOKYOWAN]: 'Tokyowan',
    [Translations.PRODUCT_NAME_OSAKA]: 'Osaka',
    [Translations.PRODUCT_NAME_AICHI]: 'Aichi',
    [Translations.PRODUCT_NAME_MIE]: 'Mie',
    [Translations.PRODUCT_NAME_TOKYOCHIBA]: 'Tokyochiba',

    [Translations.SUCCESS_CARD_REGISTRATION]: 'Kartu berhasil didaftarkan',
    [Translations.SUCCESS_CONTRACT_UPDATED]: 'Kontrak berhasil diperbarui',
    [Translations.SUCCESS_PASSWORD_RESET_LINK]: 'Tautan setel ulang sandi berhasil dikirim!',

    [Translations.TITLE_ADD_CONTRACT]: 'Tambah Kontrak',
    [Translations.TITLE_CARD]: 'Kartu',
    [Translations.TITLE_CHECK_EMAIL]: 'Periksa Email',
    [Translations.TITLE_CONTRACTS]: 'Informasi Kontrak',
    [Translations.TITLE_CREDIT_CARD_REGISTRATION]: 'Pendaftaran Kartu Kredit',
    [Translations.TITLE_EDIT_CONTRACT]: 'Edit Kontrak',
    [Translations.TITLE_LOG_IN]: 'Masuk',
    [Translations.TITLE_PASSWORD_RESET]: 'Setel Ulang Kata Sandi',
    [Translations.TITLE_PURCHASE_HISTORY]: 'Riwayat Pembelian',
    [Translations.TITLE_REGISTER]: 'Daftar',
    [Translations.TITLE_SETTINGS]: 'Setelan',
    [Translations.TITLE_USAGE]: 'Penggunaan',
  },
};
