import axios, { AxiosResponse } from 'axios';
import Stripe from 'stripe';

const host: string = process.env.REACT_APP_FIREBASE_FUNCTION_HOST!;
const url = `${host}/setupIntents`;

export const create = async (token: string) =>
  (await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: 'POST',
    url,
  })) as unknown as AxiosResponse<Stripe.SetupIntent>;
