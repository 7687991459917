import React from 'react';
import { connect } from 'react-redux';

import { PageUserViewProduct } from '@/pages/PageUserViewProduct';
import { isInitSelector } from '@/state/app/selectors';
import { setSidebarVisibility } from '@/state/layout/actions';
import { getProductDataDisplay } from '@/state/product/actions';
import { errorMessageSelector, imageSelector } from '@/state/product/selectors';
import { AppDispatch, RootState } from '@/state/store';

const mapActionCreators = (dispatch: AppDispatch) => ({
  getProductDataDisplay(): void {
    dispatch(getProductDataDisplay());
  },
  openSidebar(): void {
    dispatch(setSidebarVisibility(true));
  },
});

const mapStateToProps = (state: RootState) => ({
  errorMessage: errorMessageSelector(state),
  image: imageSelector(state),
  isInit: isInitSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageUserViewProduct);
