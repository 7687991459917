import React from 'react';
import { connect } from 'react-redux';

import { App } from '@/components/app/App';
import { initialize } from '@/state/app/actions';
import { isLoadingSelector } from '@/state/app/selectors';
import { AppDispatch, RootState } from '@/state/store';
import { isAuthenticatedSelector, isUserSetupCompletedSelector } from '@/state/user/selectors';

const mapActionCreators = (dispatch: AppDispatch) => ({
  appInit(): void {
    dispatch(initialize());
  },
});

const mapStateToProps = (state: RootState) => ({
  isAuth: isAuthenticatedSelector(state),
  isLoading: isLoadingSelector(state),
  isUserSetupCompleted: isUserSetupCompletedSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(App);
