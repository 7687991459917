import { createSlice } from '@reduxjs/toolkit';

import { getInvoices } from './actions';

const initialState: State.Invoice = {
  errorMessage: '',
  isLoading: false,
  list: [],
  successMessage: '',
};

const invoiceSlice = createSlice({
  extraReducers: {
    // Get Card List
    [`${getInvoices.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
    }),
    [`${getInvoices.fulfilled}`]: (state, action: { payload: State.InvoiceData[] }) => ({
      ...state,
      isLoading: false,
      list: action.payload,
    }),
    [`${getInvoices.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),
  },
  initialState,
  name: 'invoice',
  reducers: {},
});

export { invoiceSlice };
