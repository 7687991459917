import firebase from '@/firebase/index';

export default class Product {
  private collection = firebase.firestore().collection('products');

  public async getProduct(uid: string): Promise<State.ProductData> {
    const productsSnapshot = await this.collection.doc(uid).get();
    if (!productsSnapshot.exists) {
      throw new Error('Product does not exist');
    }

    return productsSnapshot.data() as State.ProductData;
  }

  public async getProductWithPrices(uid: string): Promise<State.ProductData> {
    // get main product snapshot
    const mainProductSnapshot = await this.collection.doc(uid).get();
    if (!mainProductSnapshot.exists) {
      throw new Error('Product does not exist');
    }

    // get the attached prices for main product
    const mainPricesSnapshot = await mainProductSnapshot.ref.collection('prices').get();
    const mainPrices = mainPricesSnapshot.docs.map((price) => ({
      ...price.data(),
      id: price.id,
    })) as State.PriceData[];

    // get all child product
    const childProductsSnapshot = await this.collection
      .where('active', '==', true)
      .where('metadata.parentId', '==', uid)
      .get();

    const childPrices = await Promise.all(
      childProductsSnapshot.docs.map(async (childProductDoc) => {
        // get the attached prices for child products
        const childPricesSnapshot = await childProductDoc.ref.collection('prices').get();
        const childPrices = childPricesSnapshot.docs.map((price) => ({
          ...price.data(),
          id: price.id,
        })) as State.PriceData[];

        return childPrices[0];
      })
    );

    const prices: State.PriceData[] = [
      // main price will be always on the first index cause it's used for basic plan,
      mainPrices[0],
      ...childPrices,
    ];

    return {
      ...mainProductSnapshot.data(),
      prices,
    } as State.ProductData;
  }

  public async getProducts(): Promise<State.ProductData[]> {
    const productsSnapshot = await this.collection
      .where('active', '==', true)
      .where('metadata.isParent', '==', 'true')
      .get();
    const products = productsSnapshot.docs.map((productDoc) => ({
      ...productDoc.data(),
      id: productDoc.id,
    })) as State.ProductData[];

    return products;
  }
}
