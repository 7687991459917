import React from 'react';
import { InputProps as SemanticInputProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { Input } from '../Input/Input';

interface Props extends SemanticInputProps {
  label: string;
  testId: string;
}

export const InputWithLabel: React.FC<Props> = ({ label, testId, ...props }) => {
  return (
    <StyledInput disabled={props?.disabled}>
      <Input {...props} testId={testId} />
      <label>{label}</label>
    </StyledInput>
  );
};

const StyledInput = styled.div<{ disabled?: boolean }>`
  &&& {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 0;

    input {
      height: ${(props) => props.theme.pxToRem(70)} !important;
      padding-top: ${(props) => props.theme.pxToRem(30)} !important;
      background: transparent;
      border: 1px solid #dad7ca;
    }

    label {
      ${(props) => (props.disabled ? `opacity: 0.7;` : ``)};

      color: ${(props) => props.theme.colors.primary};
      font-size: ${(props) => props.theme.fontSizes.s};
      font-weight: ${(props) => props.theme.fontWeights.black};
      left: ${(props) => props.theme.pxToRem(16)};
      top: ${(props) => props.theme.pxToRem(10)};
      position: absolute;
      transform-origin: top left;
    }
  }
`;
