import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalVariants } from '@/atoms/index';
import { Translations } from '@/constants/index';
import { WarningIcon } from '@/resources/icons';

interface Props {
  isVisible: boolean;
  onCloseModal: () => void;
}

export const ModalUnPurchasedProductOption: React.FC<Props> = ({ isVisible, onCloseModal }) => {
  const { t } = useTranslation();

  return (
    <Modal
      description={t(Translations.DESCRIPTION_UNPURCHASED_PRODUCT)}
      icon={WarningIcon}
      open={isVisible}
      testId="unpurchased-product-option"
      title={t(Translations.LABEL_UNPURCHASED_PRODUCT)}
      variant={ModalVariants.DANGER}
      onCloseModal={onCloseModal}
    />
  );
};
