/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Card } from '@/atoms/index';
import { Translations } from '@/constants/index';

export const CardUsages: React.FC = () => {
  const { t } = useTranslation();

  const usages = [
    {
      label: t(Translations.MENU_LABEL_CURRENT_SATELLITE_SST),
      name: 'current-cloud-sst',
      description: t(Translations.MENU_LABEL_CURRENT_SATELLITE_SST_DESC),
    },
    {
      label: t(Translations.MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST),
      name: 'current-cloud-removal',
      description: t(Translations.MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST_DESC),
    },
    {
      label: t(Translations.MENU_LABEL_TOM_SEA_SURFACE_TEMP),
      name: 'tomorrows-surface-water-temperature',
      description: t(Translations.MENU_LABEL_TOM_SEA_SURFACE_TEMP_DESC),
    },
    {
      label: t(Translations.MENU_LABEL_CURRENT_TIDE),
      name: 'current-tide-flow',
      description: t(Translations.MENU_LABEL_CURRENT_TIDE_DESC),
    },
    {
      label: t(Translations.MENU_LABEL_TOMORROWS_TIDE),
      name: 'tomorrows-tide-flow',
      description: t(Translations.MENU_LABEL_TOMORROWS_TIDE_DESC),
    },
    {
      label: t(Translations.MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP),
      name: 'current-middle-layer-water-temperature-20m',
      description: t(Translations.MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP_DESC),
    },
    {
      label: t(Translations.MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP),
      name: 'tomorrows-middle-layer-water-temperature-20m',
      description: t(Translations.MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP_DESC),
    },
    {
      label: t(Translations.MENU_LABEL_CURRENT_FRONT),
      name: 'current-front',
      description: t(Translations.MENU_LABEL_CURRENT_FRONT_DESC),
    },
    {
      label: t(Translations.MENU_LABEL_TOMORROWS_FRONT),
      name: 'tomorrows-front',
      description: t(Translations.MENU_LABEL_TOMORROWS_FRONT_DESC),
    },
  ];

  return (
    <>
      {usages.map((usage) => (
        <StyledCardWrapper key={usage.name}>
          <Card fluid description={usage.description} meta={usage.label} testId={usage.label} />
        </StyledCardWrapper>
      ))}
    </>
  );
};

const StyledCardWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(10)};
`;
