import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { Title } from '@/atoms/index';
import { MenuIcon } from '@/resources/icons';
import { extractProductName } from '@/utils/index';

interface Props {
  title: string;
  onClickBurger: () => void;
}

export const HeaderWithNav: React.FC<Props> = ({ title, onClickBurger }) => (
  <>
    <StyledGrid columns={3}>
      <StyledGridColumn textAlign="left">
        {/* <Link testId="settings" to={getPath(Routes.USER_SETTINGS)}>
          <StyledHelper />
          <StyledImage alt="cog" src={SettingsIcon} />
        </Link> */}
      </StyledGridColumn>
      <StyledGridColumn textAlign="center">
        <Title as="h2">{extractProductName(title)}</Title>
      </StyledGridColumn>
      <StyledGridColumn textAlign="right">
        <StyledHelper />
        <StyledImage alt="burger" src={MenuIcon} onClick={onClickBurger} />
      </StyledGridColumn>
    </StyledGrid>
  </>
);

const StyledImage = styled.img`
  cursor: pointer;
  vertical-align: middle;
`;

const StyledGrid = styled(Grid)`
  && {
    margin: 0;
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&& {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledHelper = styled.span`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`;
