import React from 'react';
import { Message as SemanticUIMessage } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props {
  content: string;
  error?: boolean;
  title?: string;
  success?: boolean;
}

export const Message: React.FC<Props> = ({ content, error, title, success }) => (
  <StyledMessage error={error} success={success}>
    {title && <SemanticUIMessage.Header>{title}</SemanticUIMessage.Header>}
    <SemanticUIMessage.Content>{content}</SemanticUIMessage.Content>
  </StyledMessage>
);

const StyledMessage = styled(SemanticUIMessage)<{ error?: boolean }>`
  &&& {
    ${(props) =>
      props.error
        ? `
      color: ${props.theme.colors.neutralRed};
      background-color: ${props.theme.colors.neutralRedLight};
      box-shadow: none;
    `
        : ``};

    ${(props) =>
      props.success
        ? `
          color: ${props.theme.colors.neutralGreen};
          background-color: ${props.theme.colors.neutralGreenLight};
          box-shadow: none;
        `
        : ``};

    padding: ${(props) => props.theme.pxToRem(15)};
  }
  &&&& {
    display: block;
  }
`;
