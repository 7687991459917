import React from 'react';
import { connect } from 'react-redux';

import { PageAuthPasswordReset } from '@/components/pages/PageAuthPasswordReset';
import { AppDispatch, RootState } from '@/state/store';
import { passwordReset, setUserErrorMessage, setUserSuccessMessage } from '@/state/user/actions';
import { errorMessageSelector, isLoadingSelector, successMessageSelector } from '@/state/user/selectors';

const mapActionCreators = (dispatch: AppDispatch) => ({
  passwordReset(params: { email: string }): void {
    dispatch(passwordReset(params));
  },
  setUserErrorMessage(error: string): void {
    dispatch(setUserErrorMessage(error));
  },
  setUserSuccessMessage(message: string): void {
    dispatch(setUserSuccessMessage(message));
  },
});

const mapStateToProps = (state: RootState) => ({
  errorMessage: errorMessageSelector(state),
  isLoading: isLoadingSelector(state),
  successMessage: successMessageSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageAuthPasswordReset);
