import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, ButtonLink, ButtonVariants } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { FormUpdateUserDetails, ModalUnregisteredCard } from '@/molecules/index';
import { HeaderWithHome } from '@/organisms/index';
import { FooterAuth } from '@/organisms/index';
import { SingleColumn } from '@/templates/index';
import { getPath } from '@/utils/index';

interface Props {
  cardList: State.CardData[];
  userData: State.UserData | undefined;
  errorMessage: string;
  isLoading: boolean;
  isUserSetupCompleted: boolean;
  successMessage: string;
  setUserErrorMessage: (error: string) => void;
  setUserSuccessMessage: (success: string) => void;
  updateUserData: (params: Omit<State.UserData, 'id' | 'email'>) => void;
}

export const PageUserSettings: React.FC<Props> = ({
  cardList,
  userData,
  errorMessage,
  isLoading,
  isUserSetupCompleted,
  successMessage,
  setUserErrorMessage,
  setUserSuccessMessage,
  updateUserData,
}) => {
  const { t } = useTranslation();

  const [isWarningModalVisible, setIsWarningModalVisible] = React.useState(false);

  const handleToggleModalVisibility = () => setIsWarningModalVisible(!isWarningModalVisible);

  // Clear error in form
  React.useEffect(() => {
    setUserErrorMessage('');
    setUserSuccessMessage('');
  }, [setUserErrorMessage, setUserSuccessMessage]);

  return (
    <SingleColumn>
      <>
        <HeaderWithHome title={t(Translations.TITLE_SETTINGS)} />
      </>
      <>
        <FormUpdateUserDetails
          errorMessage={errorMessage}
          isLoading={isLoading}
          isUserSetupCompleted={isUserSetupCompleted}
          successMessage={successMessage}
          userData={userData}
          onUpdateUserData={updateUserData}
        />

        {!userData?.organization && (
          <StyledButtonWrapper>
            <Button disabled fluid testId="contracts" variant={ButtonVariants.DEFAULT}>
              {t(Translations.BUTTON_CONTRACTS)}
            </Button>

            <Button disabled fluid testId="contracts" variant={ButtonVariants.DEFAULT}>
              {t(Translations.TITLE_CREDIT_CARD_REGISTRATION)}
            </Button>

            <Button disabled fluid testId="contracts" variant={ButtonVariants.DEFAULT}>
              {t(Translations.TITLE_PURCHASE_HISTORY)}
            </Button>
          </StyledButtonWrapper>
        )}

        {Boolean(userData?.organization) && (
          <StyledButtonWrapper>
            {!cardList.length && (
              <Button fluid testId="contracts" variant={ButtonVariants.DEFAULT} onClick={handleToggleModalVisibility}>
                {t(Translations.BUTTON_CONTRACTS)}
              </Button>
            )}
            {Boolean(cardList.length) && (
              <ButtonLink testId="contract" to={getPath(Routes.USER_CONTRACTS)} variant={ButtonVariants.DEFAULT}>
                {t(Translations.BUTTON_CONTRACTS)}
              </ButtonLink>
            )}
            <ButtonLink
              testId="credit-card-registration"
              to={getPath(Routes.USER_CARDS)}
              variant={ButtonVariants.DEFAULT}
            >
              {t(Translations.TITLE_CREDIT_CARD_REGISTRATION)}
            </ButtonLink>
            <ButtonLink testId="contract" to={getPath(Routes.USER_PURCHASES)} variant={ButtonVariants.DEFAULT}>
              {t(Translations.TITLE_PURCHASE_HISTORY)}
            </ButtonLink>
          </StyledButtonWrapper>
        )}

        <ModalUnregisteredCard isVisible={isWarningModalVisible} onCloseModal={handleToggleModalVisibility} />
      </>
      <>
        <FooterAuth />
      </>
      /.
    </SingleColumn>
  );
};

const StyledButtonWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(30)};

  &&& {
    a {
      display: block;
      margin-bottom: ${(props) => props.theme.pxToRem(10)};
      margin-left: 0;
      margin-right: 0;
      padding: ${(props) => props.theme.pxToRem(20)};
    }

    button {
      margin-bottom: ${(props) => props.theme.pxToRem(10)};
      padding: ${(props) => props.theme.pxToRem(20)};
    }
  }
`;
