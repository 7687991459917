import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';

import { Container, Link } from '@/atoms/index';
import { ExternalLinks, Translations } from '@/constants/index';
import { LineAccountEnImage, LineAccountJpImage, OceanEyesLogoFooter } from '@/resources/images';

export const FooterAuth: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <Container>
        <Grid columns={2}>
          <Grid.Column textAlign="left">
            <Image size="small" src={OceanEyesLogoFooter} />
          </Grid.Column>

          <Grid.Column textAlign="right">
            <StyledEmailLink isExternal testId="email" to={ExternalLinks.CONTACT}>
              {t(Translations.LINK_CONTACT)}
            </StyledEmailLink>
            <StyledLineLink isExternal testId="line-account" to={ExternalLinks.LINE_ACCOUNT}>
              <Image
                floated="right"
                size="small"
                src={i18next.language === 'jp' ? LineAccountJpImage : LineAccountEnImage}
              />
            </StyledLineLink>
          </Grid.Column>
        </Grid>
      </Container>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  background-color: #e5e1d3;
  padding: ${(props) => props.theme.pxToRem(20)} 0;
`;

const StyledEmailLink = styled(Link)`
  &&& {
    font-size: ${(props) => props.theme.fontSizes.s};
    text-decoration: underline;
  }
`;

const StyledLineLink = styled(Link)`
  display: block;
  margin-top: ${(props) => props.theme.pxToRem(10)};
`;
