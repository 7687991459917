import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button, ButtonLink, ButtonVariants, Input, InputPassword, LegalAgreement, Message } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { getPath, isEmail, isJapaneseCharacter } from '@/utils/index';

interface Props {
  email?: string;
  errorMessage: string;
  isLoading: boolean;
  onRegister: (params: { email: string; name: string; password: string }) => void;
}

export const FormRegister: React.FC<Props> = ({ email: userDataEmail, errorMessage, isLoading, onRegister }) => {
  const { t } = useTranslation();

  const [errors, setErrors] = React.useState({
    password: '',
  });
  const isFormValidated = errors.password !== '';
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [password, setPassword] = React.useState('');

  const isSubmitButtonDisabled =
    !Boolean(email) || !isEmail(email) || !Boolean(name) || !Boolean(password) || isLoading || isFormValidated;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let error = '';
    let value = event.target.value;
    if (isJapaneseCharacter(value)) {
      error = t(Translations.ERROR_FULL_WIDTH_DIGIT);
    } else if (value.length < 8) {
      error = t(Translations.ERROR_PASSWORD_MINIMUM_CHARACTERS);
    }
    setPassword(value);
    setErrors({ ...errors, password: error });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onRegister({ email, name, password });
  };

  React.useEffect(() => {
    if (userDataEmail) {
      setEmail(userDataEmail);
    }
  }, [userDataEmail, setEmail]);

  return (
    <>
      <Form size="large" onSubmit={handleSubmit}>
        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_EMAIL)}</StyledLabel>
          <Input
            disabled
            fluid
            error={Boolean(errorMessage)}
            placeholder={t(Translations.PLACEHOLDER_EMAIL)}
            testId="email"
            value={email}
            onChange={handleEmailChange}
          />
        </Form.Field>

        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_NAME)}</StyledLabel>
          <Input
            fluid
            error={Boolean(errorMessage)}
            placeholder={t(Translations.PLACEHOLDER_NAME)}
            testId="name"
            value={name}
            onChange={handleNameChange}
          />
        </Form.Field>

        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_PASSWORD)}</StyledLabel>
          <InputPassword
            fluid
            error={Boolean(errorMessage)}
            placeholder={t(Translations.PLACEHOLDER_PASSWORD)}
            testId="password"
            value={password}
            onChange={handlePasswordChange}
          />
          {Boolean(errors.password) && <Message error content={errors.password} />}
        </Form.Field>
        {Boolean(errorMessage) && <Message error content={errorMessage} />}

        <StyledLegalAgreementWrapper>
          <LegalAgreement />
        </StyledLegalAgreementWrapper>

        <StyledButtonWrapper>
          <ButtonLink testId="cancel" to={getPath(Routes.HOME)} variant={ButtonVariants.INVERTED}>
            {t(Translations.BUTTON_CANCEL)}
          </ButtonLink>
          <Button
            disabled={isSubmitButtonDisabled}
            loading={isLoading}
            testId="register"
            variant={ButtonVariants.PRIMARY}
          >
            {t(Translations.BUTTON_REGISTER)}
          </Button>
        </StyledButtonWrapper>
      </Form>
    </>
  );
};

const StyledLabel = styled.div`
  color: ${(props) => props.theme.colors.neutralGreyForLabels};
  margin-left: ${(props) => props.theme.pxToRem(5)};
  padding-bottom: ${(props) => props.theme.pxToRem(5)};
`;

const StyledLegalAgreementWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(20)};
`;

const StyledButtonWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(50)};
  text-align: right;

  &&& {
    a {
      margin-right: ${(props) => props.theme.pxToRem(10)};
    }
  }
`;
