import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link } from '@/atoms/index';

export const MenuItem: React.FC<State.MenuData & { onClick: (disabled: boolean) => void }> = ({
  disabled,
  label,
  name,
  children,
  path,
  onClick,
}) => {
  const [isExtended, setIsExtended] = React.useState(false);
  const [isExpandable, setIsExpandable] = React.useState(false);

  const handleToggleMenu = () => setIsExtended(!isExtended);

  const handleMainMenuAction = () => {
    if (isExpandable) {
      handleToggleMenu();

      return;
    }
  };

  React.useEffect(() => {
    setIsExpandable(Boolean(children?.length));
  }, [children]);

  return (
    <StyledListItem isExtended={isExtended}>
      {isExpandable && (
        <>
          {disabled && (
            <StyledDisabledLink role="link" onClick={() => onClick(true)}>
              <p>{label}</p>
              <Icon name={isExtended ? 'chevron down' : 'chevron left'} />
            </StyledDisabledLink>
          )}

          {!disabled && (
            <a role="link" onClick={handleMainMenuAction}>
              <p>{label}</p>
              <Icon name={isExtended ? 'chevron down' : 'chevron left'} />
            </a>
          )}
        </>
      )}

      {!isExpandable && (
        <>
          {disabled && (
            <StyledDisabledLink role="link" onClick={() => onClick(true)}>
              <p>{label}</p>
            </StyledDisabledLink>
          )}
          {!disabled && (
            <StyledLink testId={name} to={path as string} onClick={() => onClick(false)}>
              <p>{label}</p>
            </StyledLink>
          )}
        </>
      )}

      {isExpandable &&
        Boolean(children?.length) &&
        children?.map((item) => (
          <ul key={item.name}>
            <StyledListItem>
              {item.disabled && (
                <StyledDisabledLink role="link" onClick={() => onClick(true)}>
                  <p>{item.label}</p>
                </StyledDisabledLink>
              )}

              {!item.disabled && Boolean(item.path) && (
                <StyledLink testId={name} to={item.path as string} onClick={() => onClick(false)}>
                  <p>{item.label}</p>
                </StyledLink>
              )}
            </StyledListItem>
          </ul>
        ))}
    </StyledListItem>
  );
};

const StyledListItem = styled.li<{ isExtended?: boolean }>`
  ul {
    display: ${(props) => (props.isExtended ? `block` : `none`)};
    list-style: none;
    padding: 0;
  }

  a {
    background-color: ${(props) => props.theme.colors.neutralGreyForListItems};
    border: ${(props) => `${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGreyForListItemBorders}`};
    border-radius: ${(props) => props.theme.pxToRem(5)};
    color: ${(props) => props.theme.colors.neutralBlack};
    cursor: pointer;
    display: block;
    margin-bottom: ${(props) => props.theme.pxToRem(10)};
    padding: ${(props) => `${props.theme.pxToRem(10)} ${props.theme.pxToRem(15)}`};
    position: relative;
    transition: width ease-in 0.3s;

    p {
      display: inline;
      margin: 0;
      white-space: normal;
    }

    i {
      color: ${(props) => props.theme.colors.neutralBlackForIcons};
      position: absolute;
      right: ${(props) => props.theme.pxToRem(10)};
    }
  }
`;

const StyledDisabledLink = styled.a`
  opacity .4;
`;

const StyledLink = styled(Link)`
  &&& {
    font-weight: ${(props) => props.theme.fontWeights.regular};
  }
`;
