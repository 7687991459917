import { extractProductLabel, extractProductName } from '@/utils/index';

export const formatProductName = (name: string) => extractProductName(name);

export const formatProductPrices = (prices: State.PriceData[]) => {
  return prices
    ?.map((price) => ({
      ...price,
      amountWithTax: price?.unit_amount ? price?.unit_amount + price?.unit_amount / 10 : 0,
      label: extractProductLabel(price.description),
    }))
    ?.sort((a, b) => {
      // ignore upper and lowercase
      const descriptionA = a.description.toUpperCase();
      const descriptionB = b.description.toUpperCase();

      if (descriptionA < descriptionB) {
        return -1;
      }
      if (descriptionA > descriptionB) {
        return 1;
      }

      return 0;
    });
};
