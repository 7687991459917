import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import { Button, ButtonVariants, InputWithLabel, Message } from '@/atoms/index';
import { Translations } from '@/constants/index';
import { isJapaneseCharacter, isValidPhoneNo } from '@/utils/index';

interface Props {
  userData: State.UserData | undefined;
  errorMessage: string;
  isLoading: boolean;
  isUserSetupCompleted: boolean;
  successMessage: string;
  onUpdateUserData: (params: Omit<State.UserData, 'id' | 'email'>) => void;
}

export const FormUpdateUserDetails: React.FC<Props> = ({
  userData,
  errorMessage,
  isLoading,
  isUserSetupCompleted,
  successMessage,
  onUpdateUserData,
}) => {
  const { t } = useTranslation();

  const [address, setAddress] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [organization, setOrganization] = React.useState('');
  const [phone, setPhone] = React.useState('');

  const [errors, setErrors] = React.useState({
    address: '',
    name: '',
    organization: '',
    phoneNo: '',
  });

  const isFormValid =
    errors.address === '' && errors.name === '' && errors.organization === '' && errors.phoneNo === '';
  const isAddressUpdated = (address && address !== userData?.address) || userData?.address !== address;
  const isNameUpdated = name && name !== userData?.name;
  const isOrganizationUpdated = organization && organization !== userData?.organization;
  const isPhoneUpdated = (phone && phone !== userData?.phone) || userData?.phone !== phone;
  const isAnyFieldsUpdated = isAddressUpdated || isNameUpdated || isOrganizationUpdated || isPhoneUpdated;
  const isSubmitButtonDisabled = !isAnyFieldsUpdated || !isFormValid || isLoading;

  const isValidPhone = isValidPhoneNo(phone);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let error = '';
    let value = event.target.value;
    if (!value) {
      error = `${t(Translations.LABEL_FULL_NAME) + ' ' + t(Translations.ERROR_FIELD_IS_REQUIRED)}`;
    } else if (value.length > 128) {
      error = t(Translations.ERROR_NAME_MAX_CHARACTERS);
    }
    setName(value.trimStart());
    setErrors({ ...errors, name: error });
  };

  const handleOrganizationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let error = '';
    let value = event.target.value;
    if (!value) {
      error = `${t(Translations.LABEL_ORGANIZATION) + ' ' + t(Translations.ERROR_FIELD_IS_REQUIRED)}`;
    } else if (value.length > 128) {
      error = t(Translations.ERROR_ORG_MAX_CHARACTERS);
    }
    setOrganization(value);
    setErrors({ ...errors, organization: error });
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let error = '';
    let value = event.target.value;
    if (isJapaneseCharacter(value)) {
      error = t(Translations.ERROR_FULL_WIDTH_DIGIT);
    } else if (value && !isValidPhone) {
      error = t(Translations.ERROR_INVALID_PHONE_NO);
    } else if (value.length > 11) {
      error = t(Translations.ERROR_PHONE_NO_MAX_DIGITS);
    }
    setPhone(value);
    setErrors({ ...errors, phoneNo: error });
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let error = '';
    let value = event.target.value;
    if (value.length > 128) {
      error = t(Translations.ERROR_ADDRESS_MAX_CHARACTERS);
    }
    setAddress(value);
    setErrors({ ...errors, address: error });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onUpdateUserData({ address, name, organization, phone });
  };

  React.useEffect(() => {
    if (userData) {
      setEmail(userData.email || '');
      setName(userData.name || '');
      setAddress(userData.address || '');
      setOrganization(userData.organization || '');
      setPhone(userData.phone || '');
    }
  }, [userData]);

  return (
    <Form size="small" onSubmit={handleSubmit}>
      <Form.Field>
        <InputWithLabel disabled label={t(Translations.LABEL_EMAIL)} testId="email" value={email} />
      </Form.Field>
      <Form.Field>
        <InputWithLabel
          required
          label={t(Translations.LABEL_FULL_NAME)}
          testId="full-name"
          value={name}
          onChange={handleNameChange}
        />
        {Boolean(errors.name) && <Message error content={errors.name} />}
      </Form.Field>
      <Form.Field>
        <InputWithLabel
          label={t(Translations.LABEL_ORGANIZATION)}
          testId="organization"
          value={organization}
          onChange={handleOrganizationChange}
        />
        {Boolean(errors.organization) && <Message error content={errors.organization} />}
      </Form.Field>
      <Form.Field>
        <InputWithLabel
          label={t(Translations.LABEL_PHONE_NO)}
          testId="phone"
          value={phone}
          onChange={handlePhoneChange}
        />
        {Boolean(errors.phoneNo) && <Message error content={errors.phoneNo} />}
      </Form.Field>
      <Form.Field>
        <InputWithLabel
          label={t(Translations.LABEL_ADDRESS)}
          testId="address"
          value={address}
          onChange={handleAddressChange}
        />
        {Boolean(errors.address) && <Message error content={errors.address} />}
      </Form.Field>
      {Boolean(errorMessage) && <Message error content={errorMessage} />}
      <>
        <Button
          fluid
          primary
          disabled={isSubmitButtonDisabled}
          loading={isLoading}
          testId="save"
          variant={ButtonVariants.PRIMARY}
        >
          {t(Translations.BUTTON_SAVE)}
        </Button>
      </>
    </Form>
  );
};
