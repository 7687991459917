import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const rootSelector = createSelector(
  (state: RootState): State.Contract => state.contract,
  (contract: State.Contract) => contract
);

export const contractListSelector = createSelector(
  rootSelector,
  (contract: State.Contract): State.ContractData[] => contract.list
);

export const errorMessageSelector = createSelector(
  rootSelector,
  (contract: State.Contract): string => contract.errorMessage
);

export const isLoadingSelector = createSelector(
  rootSelector,
  (contract: State.Contract): boolean => contract.isLoading
);

export const successMessageSelector = createSelector(
  rootSelector,
  (contract: State.Contract): string => contract.successMessage
);
