import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthError, AuthErrorCodes } from 'firebase/auth';
import i18next from 'i18next';
import { push } from 'redux-first-history';

import { Customer } from '@/collections/index';
import { Routes, Translations } from '@/constants/index';
import firebase from '@/firebase/index';
import { getPath } from '@/utils/index';

import { setCardDateRetrieved, setCardList } from '../card/actions';
import { setContractList } from '../contract/actions';
import { RootState } from '../store';
import { UserActions } from './constants';
import { userDataSelector } from './selectors';

export const setUserData = createAction<State.UserData | undefined>(UserActions.SET_USER_DATA);

export const setUserDataCustomerId = createAction<string>(UserActions.SET_USER_DATA_CUSTOMER_ID);

export const setUserDataCustomerIntent = createAction<string>(UserActions.SET_USER_DATA_CUSTOMER_INTENT);

export const setUserDataEmail = createAction<string>(UserActions.SET_USER_DATA_EMAIL);

export const setUserErrorMessage = createAction<string>(UserActions.SET_USER_ERROR_MESSAGE);

export const setUserSuccessMessage = createAction<string>(UserActions.SET_USER_SUCCESS_MESSAGE);

export const getUserData = createAsyncThunk(UserActions.GET_USER_DATA, async (params: { userId: string }) => {
  const { userId } = params;

  try {
    const customer = await firebase.firestore().collection('customer').doc(userId).get();
    if (!customer.exists) {
      throw new Error(i18next.t(Translations.ERROR_INVALID_CREDENTIALS));
    }

    return Promise.resolve({ ...customer.data() });
  } catch (error) {
    return Promise.reject();
  }
});

export const updateUserData = createAsyncThunk(
  UserActions.UPDATE_USER_DATA,
  async (params: Omit<State.UserData, 'id' | 'email'>, thunkAPI) => {
    const { address, name, organization, phone } = params;

    try {
      const state = thunkAPI.getState() as RootState;
      const userData = userDataSelector(state);

      // update customer
      await new Customer().updateCustomer(userData?.id || '', { address, name, organization, phone });

      const result = {
        message: i18next.t(Translations.SUCCESS_PROFILE_UPDATE),
        userData: {
          address,
          name,
          organization,
          phone,
        },
      };

      return Promise.resolve(result);
    } catch (error) {
      let message = (error as Error)?.message;

      if ((error as AuthError)?.code) {
        const code = (error as AuthError).code;

        switch (code) {
          case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
            message = `${i18next.t(Translations.ERROR_SOMETHING_WENT_WRONG)}. ${i18next.t(
              Translations.ERROR_PLEASE_RE_LOGIN
            )}`;
            break;

          default:
            message = i18next.t(Translations.ERROR_SOMETHING_WENT_WRONG);
            break;
        }
      }

      return Promise.reject(new Error(message));
    }
  }
);

export const checkEmail = createAsyncThunk(UserActions.CHECK_EMAIL, async (params: { email: string }, thunkAPI) => {
  const { email } = params;

  try {
    thunkAPI.dispatch(setUserDataEmail(email));

    const user = await firebase.auth().fetchSignInMethodsForEmail(email);
    if (user.length) {
      thunkAPI.dispatch(push(getPath(Routes.LOGIN)));
    } else {
      thunkAPI.dispatch(push(getPath(Routes.REGISTER)));
    }

    return Promise.resolve();
  } catch (error) {
    return Promise.reject();
  }
});

export const login = createAsyncThunk(
  UserActions.LOG_IN,
  async (params: { email: string; password: string }, thunkAPI) => {
    const { email, password } = params;

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);

      // clear card date retrieved
      thunkAPI.dispatch(setCardDateRetrieved(undefined));

      return Promise.resolve();
    } catch (error) {
      let message = i18next.t(Translations.ERROR_SOMETHING_WENT_WRONG);

      if ((error as AuthError)?.code) {
        const code = (error as AuthError).code;

        switch (code) {
          case AuthErrorCodes.USER_DELETED:
            message = i18next.t(Translations.ERROR_USER_DOES_NOT_EXIST);
            break;

          case AuthErrorCodes.INVALID_PASSWORD:
            message = i18next.t(Translations.ERROR_INVALID_CREDENTIALS);
            break;

          default:
            message = i18next.t(Translations.ERROR_SOMETHING_WENT_WRONG);
            break;
        }
      }

      return Promise.reject(new Error(message));
    }
  }
);

export const logout = createAsyncThunk(UserActions.LOG_OUT, async (_, thunkAPI) => {
  try {
    await firebase.auth().signOut();

    // clear card list
    thunkAPI.dispatch(setCardList([]));

    // clear contract list
    thunkAPI.dispatch(setContractList([]));

    // clear previous service selection
    localStorage.removeItem('previousServiceSelection');

    return Promise.resolve();
  } catch (error) {
    return Promise.reject();
  }
});

export const register = createAsyncThunk(
  UserActions.REGISTER,
  async (params: { email: string; name: string; password: string }) => {
    const { email, name, password } = params;

    try {
      const res = await firebase.auth().createUserWithEmailAndPassword(email, password);
      if (res.user) {
        const details = { email, name };

        await firebase.firestore().collection('customers').doc(res.user.uid).set(details);
      }

      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  }
);

export const passwordReset = createAsyncThunk(
  UserActions.PASSWORD_RESET,
  async (params: { email: string }, thunkAPI) => {
    const { email } = params;

    try {
      await firebase.auth().sendPasswordResetEmail(email);

      thunkAPI.dispatch(push(getPath(Routes.LOGIN)));

      return Promise.resolve();
    } catch (error) {
      let message = i18next.t(Translations.ERROR_SOMETHING_WENT_WRONG);

      if ((error as AuthError)?.code) {
        const code = (error as AuthError).code;

        switch (code) {
          case AuthErrorCodes.USER_DELETED:
            message = i18next.t(Translations.ERROR_USER_DOES_NOT_EXIST);
            break;

          default:
            message = i18next.t(Translations.ERROR_SOMETHING_WENT_WRONG);
            break;
        }
      }
      return Promise.reject(new Error(message));
    }
  }
);
