import React from 'react';
import { Input as SemanticUIInput, InputProps } from 'semantic-ui-react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';

interface Props extends InputProps {
  testId: string;
}

export const Input: React.FC<Props> = ({ testId, ...props }) => {
  return <StyledInput data-testid={`input-${testId}`} {...props} />;
};

const StyledInput = styled(SemanticUIInput)<ThemedStyledProps<Props, DefaultTheme>>`
  ${({ theme, error }) => `
  &&&& {
    i {
      color: ${theme.colors.primary};
      opacity: 1;
    }

    input {
      border-radius: ${theme.pxToRem(8)};
      height: ${theme.pxToRem(50)};
      color: ${theme.colors.neutralBlack};
      line-height: ${theme.pxToRem(24)};
      padding: ${theme.pxToRem(9)} ${theme.pxToRem(15)};

      // Error Input
      ${
        error &&
        `
        border: solid ${theme.pxToRem(1)} ${theme.colors.neutralRed};
        :focus {
          border: solid ${theme.pxToRem(1)} ${theme.colors.neutralRed};
        }
      `
      };

      // Normal Input
      ${
        !error &&
        `
        border: solid ${theme.pxToRem(1)} ${theme.colors.neutralGreyForInputs};
        :focus {
          border: solid ${theme.pxToRem(2)} ${theme.colors.primary};
        }
      `
      };
    }
  }
  `}

  &&&& {
    &.ui.input > input {
      &:-ms-clear {
        display: none;
      }
      &::-ms-clear {
        display: none;
      }
      &:-ms-reveal {
        display: none;
      }
      &::-ms-reveal {
        display: none;
      }
    }

    &input[type='text']::-ms-clear {
      display: none;
    }
    &input[type='email']::-ms-clear {
      display: none;
    }
    &input[type='password']::-ms-clear {
      display: none;
    }

    &input[type='password']::-ms-reveal {
      display: none;
    }
  }
`;
