import axios, { AxiosResponse } from 'axios';

const host: string = process.env.REACT_APP_FIREBASE_FUNCTION_HOST!;
const url = `${host}/subscription_items`;

export const del = async (params: { token: string; id: string }) =>
  (await axios({
    headers: { Authorization: `Bearer ${params.token}` },
    method: 'DELETE',
    url: `${url}/${params.id}`,
  })) as unknown as AxiosResponse<State.StripeDeletedSubscriptionItem>;
