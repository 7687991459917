import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const rootSelector = createSelector(
  (state: RootState): State.Card => state.card,
  (card: State.Card) => card
);

export const cardListSelector = createSelector(rootSelector, (card: State.Card): State.CardData[] => card.list);

export const errorMessageSelector = createSelector(rootSelector, (card: State.Card): string => card.errorMessage);

export const isLoadingSelector = createSelector(rootSelector, (card: State.Card): boolean => card.isLoading);

export const dateRetrievedSelector = createSelector(
  rootSelector,
  (card: State.Card): string | undefined => card.dateRetrieved
);

export const successMessageSelector = createSelector(rootSelector, (card: State.Card): string => card.successMessage);
