import React from 'react';
import { connect } from 'react-redux';

import { ServiceSelectionSidebar } from '@/molecules/index';
import { contractListSelector } from '@/state/contract/selectors';
import { setSidebarVisibility, setUnPurchasedModalVisibility } from '@/state/layout/actions';
import { setSelectedRegion } from '@/state/menu/actions';
import { menuListWithPathSelector, selectedRegionSelector } from '@/state/menu/selectors';
import { AppDispatch, RootState } from '@/state/store';

const mapActionCreators = (dispatch: AppDispatch) => ({
  closeSidebar(): void {
    dispatch(setSidebarVisibility(false));
  },
  openUnPurchasedModal(): void {
    dispatch(setUnPurchasedModalVisibility(true));
  },
  selectRegion(regionId: string): void {
    dispatch(setSelectedRegion(regionId));
  },
});

const mapStateToProps = (state: RootState) => ({
  contractList: contractListSelector(state),
  menuList: menuListWithPathSelector(state),
  selectedRegion: selectedRegionSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(ServiceSelectionSidebar);
