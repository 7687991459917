import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Translations } from '@/constants/index';
import { BrandTextWithTitle, FormLogin } from '@/molecules/index';
import { FooterGuest } from '@/organisms/index';
import { SingleColumn } from '@/templates/index';

interface Props {
  email?: string;
  errorMessage: string;
  isLoading: boolean;
  login: (params: { email: string; password: string }) => void;
  setUserErrorMessage: (errorMessage: string) => void;
}

export const PageAuthLogin: React.FC<Props> = ({ email, errorMessage, isLoading, login, setUserErrorMessage }) => {
  const { t } = useTranslation();

  // Clear error in form
  React.useEffect(() => {
    setUserErrorMessage('');
  }, [setUserErrorMessage]);

  return (
    <SingleColumn>
      <></>
      <>
        <StyledHeaderWrapper>
          <BrandTextWithTitle as="h1" title={t(Translations.TITLE_LOG_IN)} />
        </StyledHeaderWrapper>

        <FormLogin email={email} errorMessage={errorMessage} isLoading={isLoading} onLogin={login} />
      </>
      <>
        <FooterGuest />
      </>
    </SingleColumn>
  );
};

const StyledHeaderWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(50)};
  margin-bottom: ${(props) => props.theme.pxToRem(70)};
`;
