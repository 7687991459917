import { DocumentData, DocumentSnapshot, Timestamp } from 'firebase/firestore';

import firebase from '@/firebase/index';

export default class Customer {
  private collection = firebase.firestore().collection('customers');

  /**
   *
   * Create CheckoutSession for Subscription mode (stripe) in firestore
   * if successful, then this will redirect
   * the user to stripe checkout portal
   *
   * @param params
   */
  public async createCustomerSubscriptionCheckoutSessions(params: {
    customerId?: string;
    uid: string;
    items: { price: string; quantity: number }[];
    locale: string;
  }): Promise<void> {
    const { customerId, uid, items, locale } = params;

    const customerCheckoutSessionRef = await this.collection
      .doc(uid)
      .collection('checkout_sessions')
      .add({
        ...(customerId && { customerId }),
        billing_address_collection: 'auto',
        cancel_url: `${window.location.origin}/user/contracts`,
        line_items: items.map((item) => ({
          ...item,
          tax_rates: [process.env.REACT_APP_STRIPE_TAX_RATE as string],
        })),
        locale,
        mode: 'subscription',
        payment_method_types: ['card'],
        success_url: `${window.location.origin}/user/settings`,
      });

    // Wait for the CheckoutSession to get attached by the extension
    customerCheckoutSessionRef.onSnapshot((checkoutSessionSnapshop) => {
      const { error, url } = checkoutSessionSnapshop.data() as { error: Error; url: string };

      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);

        throw new Error(error.message);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  }

  /**
   *
   * Return customer data from firestore
   *
   * @param uid
   * @returns State.StripeCustomer
   */
  public async getCustomer(uid: string): Promise<State.UserData> {
    const customerSnapshot = await this.collection.doc(uid).get();
    if (!customerSnapshot.exists) {
      throw new Error('Customer does not exist');
    }

    return customerSnapshot.data() as State.UserData;
  }

  public async getCustomerSubscriptions(uid: string): Promise<State.ContractData[]> {
    // don't include subscription that is in trialing status
    const subscriptionsSnapshot = await this.collection
      .doc(uid)
      .collection('subscriptions')
      .where('status', 'in', ['active'])
      .get();
    const subscriptions = await Promise.all(
      subscriptionsSnapshot.docs.map(async (subscriptionDoc) => {
        const subscription = subscriptionDoc.data() as State.SubscriptionData;

        let product = {} as State.ProductData;

        // get product attached to subscription
        const productRef = (await (subscription?.product as DocumentData)?.get()) as DocumentSnapshot<DocumentData>;
        if (productRef) {
          product = {
            ...productRef.data(),
            id: productRef.id,
          } as State.ProductData;
        }

        return {
          cancel_at_period_end: subscription.cancel_at_period_end,
          created: (subscription.created as unknown as Timestamp).toDate().toString(),
          current_period_end: subscription?.current_period_end
            ? (subscription.current_period_end as unknown as Timestamp).toDate().toString()
            : '',
          current_period_start: subscription?.current_period_start
            ? (subscription.current_period_start as unknown as Timestamp).toDate().toString()
            : '',
          id: subscriptionDoc.id,
          // items: subscriptionItems,
          items: subscription.items,
          metadata: subscription.metadata,
          ...(Boolean(product) && { product }),
        };
      })
    );

    return subscriptions;
  }

  /**
   *
   * Update customer data from firestore
   *
   * @param uid
   * @param params
   */
  public async updateCustomer(uid: string, params: Omit<State.UserData, 'id' | 'email'>): Promise<void> {
    await this.collection.doc(uid).update(params);
  }
}
