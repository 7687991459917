import { createSelector } from '@reduxjs/toolkit';
import { generatePath } from 'react-router-dom';

import { AccessType, Routes } from '@/constants/index';
import { getPath, snakeCase } from '@/utils/index';

import { contractListSelector } from '../contract/selectors';
import { productListSelector } from '../product/selectors';
import { RootState } from '../store';

const rootSelector = createSelector(
  (state: RootState): State.Menu => state.menu,
  (menu: State.Menu) => menu
);

export const menuListSelector = createSelector(rootSelector, (menu: State.Menu): State.MenuData[] => menu.list);

export const selectedRegionSelector = createSelector(rootSelector, (menu: State.Menu): string => menu.selectedRegion);

export const menuListWithPathSelector = createSelector(
  [menuListSelector, selectedRegionSelector, productListSelector, contractListSelector],
  (
    menuList: State.MenuData[],
    selectedRegion: string,
    productList: State.ProductData[],
    contractList: State.ContractData[]
  ): State.MenuData[] => {
    const product = productList.find((product) => product.id === selectedRegion);
    if (!product) {
      return [];
    }

    const name = product.metadata.label || product.name;
    const contract = contractList.find((contract) => contract.product?.id === product.id);

    const hasAccessToBasic = Boolean(contract);
    const hasAccessToFront = Boolean(
      contract?.items?.find((item) => snakeCase(item.price?.nickname || '') === AccessType.FRONT)
    );
    const hasAccessToTide = Boolean(
      contract?.items?.find((item) => snakeCase(item.price?.nickname || '') === AccessType.TIDE)
    );
    const hasAccessToMiddleLayerWaterTemperature = Boolean(
      contract?.items?.find(
        (item) => snakeCase(item.price?.nickname || '') === AccessType.MIDDLE_LAYER_WATER_TEMPERATURE
      )
    );

    const hasAccess = (acessType: AccessType) => {
      return acessType === AccessType.BASIC
        ? hasAccessToBasic
        : acessType === AccessType.FRONT
        ? hasAccessToFront
        : acessType === AccessType.TIDE
        ? hasAccessToTide
        : acessType === AccessType.MIDDLE_LAYER_WATER_TEMPERATURE
        ? hasAccessToMiddleLayerWaterTemperature
        : false;
    };

    const result = menuList.map((menu) => ({
      ...menu,
      ...(menu?.accessType && { disabled: !hasAccess(menu.accessType as AccessType) }),
      path: generatePath(getPath(Routes.USER_VIEW_PRODUCT_DATA), {
        productName: snakeCase(name),
        serviceName: menu.name,
      }),
      ...(menu?.children?.length && {
        children: menu.children.map((childMenu) => ({
          ...childMenu,
          ...(childMenu?.accessType && { disabled: !hasAccess(childMenu.accessType as AccessType) }),
          path: generatePath(getPath(Routes.USER_VIEW_PRODUCT_DATA), {
            productName: snakeCase(name),
            serviceName: childMenu.name,
          }),
        })),
      }),

      // create path for those menu data that doesn't have children
      // ...(!menu?.children?.length && {
      //   ...(menu?.accessType && { disabled: !hasAccess(menu.accessType as AccessType) }),
      //   path: generatePath(getPath(Routes.USER_VIEW_PRODUCT_DATA), {
      //     productName: snakeCase(name),
      //     serviceName: menu.name,
      //   }),
      // }),

      // create path for children
      // ...(menu?.children?.length && {
      //   children: menu.children.map((childMenu) => ({
      //     ...childMenu,
      //     ...(childMenu?.accessType && { disabled: !hasAccess(childMenu.accessType as AccessType) }),
      //     path: generatePath(getPath(Routes.USER_VIEW_PRODUCT_DATA), {
      //       productName: snakeCase(name),
      //       serviceName: childMenu.name,
      //     }),
      //   })),
      // }),
    }));

    return result;
  }
);
