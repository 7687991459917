import React from 'react';
import { InputProps } from 'semantic-ui-react';

import { Input } from '@/atoms/index';

interface Props extends InputProps {
  testId: string;
}

export const InputPassword: React.FC<Props> = ({ testId, ...props }) => {
  const [isShowPassword, setIsShowPassword] = React.useState(false);

  const handleShowPassword = () => setIsShowPassword(!isShowPassword);

  return (
    <Input
      data-testid={`input-${testId}`}
      icon={{ link: true, name: isShowPassword ? 'eye slash' : 'eye', onClick: handleShowPassword }}
      testId={testId}
      type={isShowPassword ? 'text' : 'password'}
      {...props}
    />
  );
};
