import React from 'react';
import { Checkbox as SemanticUICheckbox, CheckboxProps } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props extends CheckboxProps {
  testId: string;
}

export const Checkbox: React.FC<Props> = ({ testId, ...props }) => {
  return <StyledCheckbox data-testid={testId} {...props} />;
};

const StyledCheckbox = styled(SemanticUICheckbox)<{ radio?: boolean }>`
  &.ui.checkbox label {
    color: ${(props) => props.theme.colors.neutralBlack};
  }

  &.ui.checkbox label:empty {
    padding-left: 0;
  }

  &.ui.checkbox input ~ label:before {
    background-color: transparent;
    border: ${(props) => `${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGreyForCheckboxes}`};
  }

  ${(props) =>
    props?.radio
      ? `
  &.ui.checkbox input:checked ~ label:before {
    border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.primary};
  }

  &.ui.radio.checkbox input:focus ~ label:after,
  &.ui.radio.checkbox input:checked ~ label:after {
    color: ${props.theme.colors.neutralWhite};
    background-color: ${props.theme.colors.primary};
    border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.primary};
  }
  `
      : `
  &.ui.checkbox input:checked ~ label:after {
    color: ${props.theme.colors.neutralWhite};
    background-color: ${props.theme.colors.primary};
    border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.primary};
    border-radius: ${props.theme.pxToRem(4)};
  }
  `};

  &.ui.checkbox input:checked ~ label {
    font-weight: ${(props) => props.theme.fontWeights.black};
  }
`;
