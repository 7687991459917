import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button, ButtonLink, ButtonVariants, Message } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { ContractItem, EmptyPlaceHolder } from '@/molecules/index';
import { HeaderWithBack } from '@/organisms/index';
import { FooterAuth } from '@/organisms/index';
import { SingleColumn } from '@/templates/index';
import { getPath } from '@/utils/index';

interface Props {
  cardList: State.CardData[];
  contractList: State.ContractData[];
  productUnPurchasedList: State.ProductData[];
}

export const PageUserContracts: React.FC<Props> = ({ cardList, contractList, productUnPurchasedList }) => {
  const { t } = useTranslation();

  return (
    <SingleColumn>
      <>
        <HeaderWithBack path={getPath(Routes.USER_SETTINGS)} title={t(Translations.TITLE_CONTRACTS)} />
      </>
      <>
        {!contractList.length && <EmptyPlaceHolder />}

        {Boolean(contractList.length) && (
          <>
            <h3>{t(Translations.LABEL_PURCHASE_ITEMS)}</h3>

            <StyledContractWrapper>
              {contractList?.map((contract) => (
                <ContractItem contract={contract} key={contract.id} />
              ))}
            </StyledContractWrapper>
          </>
        )}

        {Boolean(productUnPurchasedList.length) && Boolean(cardList.length) && (
          <StyledButtonLink
            testId="create-contract"
            to={getPath(Routes.USER_ADD_CONTRACT)}
            variant={ButtonVariants.DEFAULT}
          >
            {t(Translations.LABEL_CREATE_CONTACT)}
          </StyledButtonLink>
        )}

        {!cardList.length && <Message error content={t(Translations.DESCRIPTION_NO_REGISTERED_CARD)} />}

        {(!productUnPurchasedList.length || !cardList.length) && (
          <Button disabled fluid testId="create-contract" variant={ButtonVariants.DEFAULT}>
            {t(Translations.LABEL_CREATE_CONTACT)}
          </Button>
        )}
      </>
      <>
        <FooterAuth />
      </>
      /.
    </SingleColumn>
  );
};

const StyledContractWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(20)};
`;

const StyledButtonLink = styled(ButtonLink)`
  &&& {
    display: block;
  }
`;
