import axios, { AxiosResponse } from 'axios';

const host: string = process.env.REACT_APP_FIREBASE_FUNCTION_HOST!;
const url = `${host}/sessions`;

export const createSetup = async (params: { token: string; locale: string; successUrl: string; cancelUrl: string }) =>
  (await axios({
    data: {
      cancelUrl: params.cancelUrl,
      locale: params.locale,
      successUrl: params.successUrl,
    },
    headers: { Authorization: `Bearer ${params.token}` },
    method: 'POST',
    url: `${url}/setup`,
  })) as unknown as AxiosResponse<{ url: string }>;
