import axios, { AxiosResponse } from 'axios';

const host: string = process.env.REACT_APP_FIREBASE_FUNCTION_HOST!;
const url = `${host}/subscriptions`;

export const update = async (params: {
  token: string;
  id: string;
  toRecoverItems: string[];
  toRemoveItems: string[];
  toPurchaseItems: string[];
}) =>
  (await axios({
    data: {
      toPurchaseItems: params.toPurchaseItems,
      toRecoverItems: params.toRecoverItems,
      toRemoveItems: params.toRemoveItems,
    },
    headers: { Authorization: `Bearer ${params.token}` },
    method: 'PATCH',
    url: `${url}/${params.id}`,
  })) as unknown as AxiosResponse<State.ContractData>;
