import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button, ButtonVariants, Message } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { ModalDeleteCard } from '@/molecules/index';
import { HeaderWithBack } from '@/organisms/index';
import { FooterAuth } from '@/organisms/index';
import { SingleColumn } from '@/templates/index';
import { getPath } from '@/utils/index';

interface Props {
  cardList: State.CardData[];
  errorMessage: string;
  isLoading: boolean;
  successMessage: string;
  userData?: State.UserData;
  setCardSuccessMessage: (successMessage: string) => void;
  setCardErrorMessage: (errorMessage: string) => void;
  createCard: (params: {
    name: string;
    stripe: Stripe;
    elementComponent: StripeCardNumberElement;
    clearForm: () => void;
    billing: {
      city: string;
      country: string;
      line1: string;
      line2?: string;
      state?: string;
    };
  }) => void;
  deleteCard: (id: string) => void;
  redirectToCheckout: () => void;
}

export const PageUserCards: React.FC<Props> = ({
  cardList,
  isLoading,
  successMessage,
  deleteCard,
  setCardSuccessMessage,
  setCardErrorMessage,
  redirectToCheckout,
}) => {
  const { t } = useTranslation();

  const [isWarningModalVisible, setIsWarningModalVisible] = React.useState(false);
  const [id, setId] = React.useState('');

  const handleToggleModalVisibility = (id?: string) => {
    if (id) {
      setId(id);
    }

    setIsWarningModalVisible(!isWarningModalVisible);
  };

  const handleDeleteCard = () => {
    deleteCard(id);
  };

  const handleRedirectToCheckout = () => {
    redirectToCheckout();
  };

  // Clear error and success message
  React.useEffect(() => {
    setCardSuccessMessage('');
    setCardErrorMessage('');
  }, [setCardSuccessMessage, setCardErrorMessage]);

  React.useEffect(() => {
    // hide warning modal
    if (successMessage === 'Card successfully deleted' && isWarningModalVisible) {
      handleToggleModalVisibility('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMessage]);

  return (
    <SingleColumn>
      <>
        <HeaderWithBack path={getPath(Routes.USER_SETTINGS)} title={t(Translations.TITLE_CARD)} />
      </>
      <>
        <ModalDeleteCard
          isLoading={isLoading}
          isVisible={isWarningModalVisible}
          onCloseModal={handleToggleModalVisibility}
          onDelete={handleDeleteCard}
        />

        {Boolean(cardList.length) && (
          <StyledTable unstackable>
            <StyledTableHeader>
              <Table.Row>
                <Table.HeaderCell width={2}>{t(Translations.LABEL_NAME)}</Table.HeaderCell>
                <Table.HeaderCell width={2}>{t(Translations.LABEL_CARD_NUMBER)}</Table.HeaderCell>
                <Table.HeaderCell width={1}>{t(Translations.LABEL_CARD_EXPIRATION)}</Table.HeaderCell>
                <Table.HeaderCell width={1}></Table.HeaderCell>
              </Table.Row>
            </StyledTableHeader>

            <Table.Body>
              {cardList.map((card) => (
                <Table.Row key={card.id}>
                  <Table.Cell>{card.billing_details.name}</Table.Cell>
                  <Table.Cell>**** **** **** {card?.card?.last4}</Table.Cell>
                  <Table.Cell>
                    {card?.card?.exp_month} / {card?.card?.exp_year}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      testId="delete-card"
                      variant={ButtonVariants.BOXED}
                      onClick={() => handleToggleModalVisibility(card.id)}
                    >
                      <StyledIcon name="trash alternate" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </StyledTable>
        )}

        {!cardList.length && (
          <>
            <StyledTitleContainer>
              <Message error content={t(Translations.ERROR_NO_CARD)} />
            </StyledTitleContainer>
            <Button
              disabled={isLoading}
              loading={isLoading}
              testId="add-card"
              variant={ButtonVariants.PRIMARY}
              onClick={handleRedirectToCheckout}
            >
              {t(Translations.BUTTON_ADD_CARD)}
            </Button>
          </>
        )}
      </>
      <>
        <FooterAuth />
      </>
    </SingleColumn>
  );
};

const StyledTable = styled(Table)`
  &&& {
    border-radius: 0;
    margin-bottom: ${(props) => props.theme.pxToRem(1)};
    margin-top: ${(props) => props.theme.pxToRem(1)};
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& {
    th {
      background-color: ${(props) => props.theme.colors.neutralWhite};
      color: ${(props) => props.theme.colors.neutralGreyForHeaders};
      font-size: ${(props) => props.theme.fontSizes.s};
    }
  }
`;

const StyledIcon = styled(Icon)`
  &&& {
    padding: 0;
    margin: 0;
  }
`;

const StyledTitleContainer = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(20)};
`;
