import React from 'react';
import styled from 'styled-components';

import { AppBreakpoints, AppMedia } from '@/theme/media';

const { Media } = AppMedia;

export const OnlyDesktop = (props: any) => (
  <StyledOnlyDesktop greaterThanOrEqual={AppBreakpoints.Desktop}>{props.children}</StyledOnlyDesktop>
);

export const OnlyMobile = (props: any) => (
  <StyledOnlyMobile lessThan={AppBreakpoints.Desktop}>{props.children}</StyledOnlyMobile>
);

const StyledOnlyDesktop = styled(Media)`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const StyledOnlyMobile = styled(Media)`
  overflow: visible;
`;
