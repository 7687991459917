import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Sidebar as SemanticUISidebar } from 'semantic-ui-react';
import styled from 'styled-components';

import ContainerUnPurchasedModal from '@/containers/ContainerUnPurchasedModal';
import { Sidebar } from '@/organisms/index';
import { isSidebarVisibleSelector } from '@/state/layout/selectors';

interface Props {
  children: React.ReactNode[];
}

export const PageWithSidebar: React.FC<Props> = ({ children }) => {
  const isSidebarVisible = useSelector(isSidebarVisibleSelector);

  return (
    <StyledGrid columns={1}>
      <ContainerUnPurchasedModal />

      <StyledColumn>
        <StyledSidebarPushable>
          <Sidebar isVisible={isSidebarVisible}>{children[0]}</Sidebar>

          <StyledSidebarPusher dimmed={isSidebarVisible}>{children[1]}</StyledSidebarPusher>
        </StyledSidebarPushable>
      </StyledColumn>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  &&& {
    height: 100%;
    margin: 0;
  }
`;

const StyledColumn = styled(Grid.Column)`
  &&&& {
    margin: 0;
    padding: 0;
  }
`;

const StyledSidebarPushable = styled(SemanticUISidebar.Pushable)`
  height: 100%;
`;

const StyledSidebarPusher = styled(SemanticUISidebar.Pusher)`
  height: 100%;
`;
