import { createSlice } from '@reduxjs/toolkit';

import {
  checkEmail,
  getUserData,
  login,
  logout,
  passwordReset,
  register,
  setUserData,
  setUserDataCustomerId,
  setUserDataCustomerIntent,
  setUserDataEmail,
  setUserErrorMessage,
  setUserSuccessMessage,
  updateUserData,
} from './actions';

const initialState: State.User = {
  errorMessage: '',
  isLoading: false,
  successMessage: '',
};

const userSlice = createSlice({
  extraReducers: {
    // Check Email
    [`${checkEmail.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
    }),
    [`${checkEmail.fulfilled}`]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [`${checkEmail.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Get User Data
    [`${getUserData.pending}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${getUserData.fulfilled}`]: (state, action: { payload: State.UserData }) => ({
      ...state,
      ...(action?.payload && { data: { address: '', phone: '', ...action.payload } }),
      isLoading: false,
    }),
    [`${getUserData.rejected}`]: (state, action: { error: string }) => ({
      ...state,
      ...(action?.error && { error: action.error }),
      isLoading: false,
    }),

    // Login
    [`${login.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
    }),
    [`${login.fulfilled}`]: (state) => ({
      ...state,
    }),
    [`${login.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Logout
    [`${logout.pending}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${logout.fulfilled}`]: (state) => ({
      ...state,
      data: undefined,
      isLoading: false,
    }),
    [`${logout.rejected}`]: (state) => ({
      ...state,
      isLoading: false,
    }),

    // Register
    [`${register.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
    }),
    [`${register.fulfilled}`]: (state) => ({
      ...state,
    }),
    [`${register.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Update User Data
    [`${updateUserData.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
      successMessage: '',
    }),
    [`${updateUserData.fulfilled}`]: (state, action: { payload?: { userData: State.UserData; message: string } }) => {
      const { message, userData } = action?.payload || {};

      return {
        ...state,
        ...(Boolean(message) && { successMessage: message }),
        ...(Boolean(userData) && { data: { ...(state.data || {}), ...userData } }),
        isLoading: false,
      };
    },
    [`${updateUserData.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Set User Data
    [`${setUserData.type}`]: (state, action: { payload: State.UserData }) => ({
      ...state,
      ...(action?.payload && { data: { ...action.payload } }),
      isLoading: false,
    }),
    // Set User Data Customer Id
    // [`${setUserDataCustomerId.type}`]: (state, action: { payload: string }) => ({
    //   ...state,
    //   data: {
    //     ...state.data,
    //     customerId: action.payload,
    //   },
    // }),
    // // Set User Data Customer Intent
    // [`${setUserDataCustomerIntent.type}`]: (state, action: { payload: string }) => ({
    //   ...state,
    //   data: {
    //     ...state.data,
    //     customerIntent: action.payload,
    //   },
    // }),

    // Set User Data Email
    [`${setUserDataEmail.type}`]: (state, action: { payload: string }) => ({
      ...state,
      data: {
        email: action.payload,
      },
    }),

    // Set User Error
    [`${setUserErrorMessage.type}`]: (state, action: { payload: string }) => ({
      ...state,
      errorMessage: action.payload,
      isLoading: false,
    }),

    // Set Success Message
    [`${setUserSuccessMessage.type}`]: (state, action: { payload: string }) => ({
      ...state,
      successMessage: action.payload,
    }),

    // Password Reset
    [`${passwordReset.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
      successMessage: '',
    }),
    [`${passwordReset.fulfilled}`]: (state, action: { payload: { message: string } }) => ({
      ...state,
      isLoading: false,
      successMessage: action.payload.message,
    }),
    [`${passwordReset.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),
  },
  initialState,
  name: 'user',
  reducers: {},
});

export { userSlice };
