import React from 'react';
import { connect } from 'react-redux';

import { ModalUnPurchasedProductOption } from '@/molecules/index';
import { setUnPurchasedModalVisibility } from '@/state/layout/actions';
import { isUnPurchasedModalVisibleSelector } from '@/state/layout/selectors';
import { AppDispatch, RootState } from '@/state/store';

const mapActionCreators = (dispatch: AppDispatch) => ({
  onCloseModal(): void {
    dispatch(setUnPurchasedModalVisibility(false));
  },
});

const mapStateToProps = (state: RootState) => ({
  isVisible: isUnPurchasedModalVisibleSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(ModalUnPurchasedProductOption);
