import React from 'react';
import { connect } from 'react-redux';

import { PageUserPurchases } from '@/pages/PageUserPurchases';
import { getInvoices } from '@/state/invoice/actions';
import { invoiceListSelector, isLoadingSelector } from '@/state/invoice/selectors';
import { AppDispatch, RootState } from '@/state/store';

const mapActionCreators = (dispatch: AppDispatch) => ({
  getInvoices(): void {
    dispatch(getInvoices());
  },
});

const mapStateToProps = (state: RootState) => ({
  invoiceList: invoiceListSelector(state),
  isLoading: isLoadingSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageUserPurchases);
