export enum UserActions {
  CHECK_EMAIL = 'user/CHECK_EMAIL',
  GET_USER_DATA = 'user/GET_USER_DATA',
  SET_USER_DATA = 'user/SET_USER_DATA',
  SET_USER_DATA_CUSTOMER_ID = 'user/SET_USER_DATA_CUSTOMER_ID',
  SET_USER_DATA_CUSTOMER_INTENT = 'user/SET_USER_DATA_CUSTOMER_INTENT',
  SET_USER_DATA_EMAIL = 'user/SET_USER_DATA_EMAIL',
  SET_USER_ERROR_MESSAGE = 'user/SET_USER_ERROR_MESSAGE',
  SET_USER_SUCCESS_MESSAGE = 'user/SET_USER_SUCCESS_MESSAGE',
  UPDATE_USER_DATA = 'user/UPDATE_USER_DATA',
  LOG_IN = 'user/LOG_IN',
  LOG_OUT = 'user/LOG_OUT',
  REGISTER = 'user/REGISTER',
  PASSWORD_RESET = 'user/PASSWORD_RESET',
}
