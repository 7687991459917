import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button, ButtonVariants, Input, LegalAgreement, Message } from '@/atoms/index';
import { Translations } from '@/constants/index';
import { isEmail } from '@/utils/index';

interface Props {
  email?: string;
  errorMessage: string;
  isLoading: boolean;
  onCheckEmail: (params: { email: string }) => void;
}

export const FormCheckEmail: React.FC<Props> = ({ email: userDataEmail, errorMessage, isLoading, onCheckEmail }) => {
  const { t } = useTranslation();

  const [email, setEmail] = React.useState('');

  const isSubmitButtonDisabled = !Boolean(email) || !isEmail(email) || isLoading;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onCheckEmail({ email });
  };

  React.useEffect(() => {
    if (userDataEmail) {
      setEmail(userDataEmail);
    }
  }, [userDataEmail, setEmail]);

  return (
    <>
      <Form size="large" onSubmit={handleSubmit}>
        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_EMAIL)}</StyledLabel>
          <Input
            fluid
            error={Boolean(errorMessage)}
            placeholder={t(Translations.PLACEHOLDER_EMAIL)}
            testId="email"
            value={email}
            onChange={handleEmailChange}
          />
        </Form.Field>

        {Boolean(errorMessage) && <Message error content={errorMessage} />}

        <StyledLegalAgreementWrapper>
          <LegalAgreement />
        </StyledLegalAgreementWrapper>

        <StyledButtonWrapper>
          <Button disabled={isSubmitButtonDisabled} loading={isLoading} testId="next" variant={ButtonVariants.PRIMARY}>
            {t(Translations.BUTTON_NEXT)}
          </Button>
        </StyledButtonWrapper>
      </Form>
    </>
  );
};

const StyledLabel = styled.div`
  color: ${(props) => props.theme.colors.neutralGreyForLabels};
  margin-left: ${(props) => props.theme.pxToRem(5)};
  padding-bottom: ${(props) => props.theme.pxToRem(5)};
`;

const StyledLegalAgreementWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(30)};
`;

const StyledButtonWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(70)};
  text-align: center;
`;
