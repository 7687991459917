import React from 'react';
import { connect } from 'react-redux';

import { PageUserUsages } from '@/pages/PageUserUsages';
import { setSidebarVisibility } from '@/state/layout/actions';
import { setDefaultSelectedRegion } from '@/state/product/actions';
import { AppDispatch, RootState } from '@/state/store';

const mapActionCreators = (dispatch: AppDispatch) => ({
  openSidebar(): void {
    dispatch(setSidebarVisibility(true));
  },
  setDefaultSelectedRegion(region: string): void {
    dispatch(setDefaultSelectedRegion(region));
  },
});

const mapStateToProps = (state: RootState) => ({});

export default connect(mapStateToProps, mapActionCreators)(PageUserUsages);
