import axios, { AxiosResponse } from 'axios';

const host: string = process.env.REACT_APP_FIREBASE_FUNCTION_HOST!;
const url = `${host}/paymentMethods`;

export const list = async (token: string) =>
  (await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: 'GET',
    url,
  })) as unknown as AxiosResponse<State.CardData[]>;

export const deleteDuplicate = async (params: { token: string; id: string }) =>
  (await axios({
    headers: { Authorization: `Bearer ${params.token}` },
    method: 'POST',
    url: `${url}/${params.id}/detach-duplicate`,
  })) as unknown as AxiosResponse<{ isDuplicate: boolean }>;

export const attach = async (params: { token: string; id: string }) =>
  (await axios({
    headers: { Authorization: `Bearer ${params.token}` },
    method: 'POST',
    url: `${url}/${params.id}/attach`,
  })) as unknown as AxiosResponse<{ success: boolean }>;

export const deleteCard = async (params: { token: string; id: string }) =>
  (await axios({
    headers: { Authorization: `Bearer ${params.token}` },
    method: 'DELETE',
    url: `${url}/${params.id}`,
  })) as unknown as AxiosResponse<{ success: boolean }>;
