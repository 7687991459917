import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import React from 'react';
import { connect } from 'react-redux';

import { PageUserCards } from '@/pages/PageUserCards';
import {
  createCard,
  deleteCard,
  redirectToCheckout,
  setCardErrorMessage,
  setCardSuccessMessage,
} from '@/state/card/actions';
import {
  cardListSelector,
  errorMessageSelector,
  isLoadingSelector,
  successMessageSelector,
} from '@/state/card/selectors';
import { AppDispatch, RootState } from '@/state/store';
import { userDataSelector } from '@/state/user/selectors';

const mapActionCreators = (dispatch: AppDispatch) => ({
  createCard(params: {
    name: string;
    stripe: Stripe;
    elementComponent: StripeCardNumberElement;
    clearForm: () => void;
    billing: {
      city: string;
      country: string;
      line1: string;
      line2?: string;
      state?: string;
    };
  }): void {
    dispatch(createCard(params));
  },
  deleteCard(id: string): void {
    dispatch(deleteCard({ id }));
  },
  redirectToCheckout(): void {
    dispatch(redirectToCheckout());
  },
  setCardErrorMessage(message: string): void {
    dispatch(setCardErrorMessage(message));
  },
  setCardSuccessMessage(message: string): void {
    dispatch(setCardSuccessMessage(message));
  },
});

const mapStateToProps = (state: RootState) => ({
  cardList: cardListSelector(state),
  errorMessage: errorMessageSelector(state),
  isLoading: isLoadingSelector(state),
  successMessage: successMessageSelector(state),
  userData: userDataSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageUserCards);
