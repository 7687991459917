import { createSlice } from '@reduxjs/toolkit';

import {
  editContract,
  getContracts,
  redirectToCheckout,
  setContractErrorMessage,
  setContractList,
  setContractSuccessMessage,
} from './actions';

const initialState: State.Contract = {
  errorMessage: '',
  isLoading: false,
  list: [],
  successMessage: '',
};

const contractSlice = createSlice({
  extraReducers: {
    // Edit Contract
    [`${editContract.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
      successMessage: '',
    }),
    [`${editContract.fulfilled}`]: (
      state,
      action: { payload: { message: string; data: State.ContractData & { cancel_at_period_end: boolean } } }
    ) => ({
      ...state,
      isLoading: false,
      ...(action?.payload?.message && { successMessage: action?.payload?.message }),
      ...(action?.payload?.data && {
        list: [
          ...state.list.map((contractItem) => {
            if (contractItem.id === action.payload.data.id) {
              const updatedContractItems = action.payload.data?.items;
              let contractItems = contractItem.items || [];

              if (contractItems.length && updatedContractItems?.length) {
                contractItems = contractItems.map((contractItem) => {
                  const updatedContractItem = updatedContractItems?.find(
                    (updatedItem) => contractItem.id === updatedItem.id
                  );

                  return updatedContractItem || contractItem;
                });

                const contractItemsIds = contractItems.map((contractItem) => contractItem.id);
                const toAddNewContractItems = updatedContractItems.filter(
                  (updatedContractItem) => !contractItemsIds.includes(updatedContractItem.id)
                );

                contractItems = [...contractItems, ...toAddNewContractItems];
              }

              return {
                ...contractItem,
                ...(action.payload.data?.items?.length && { items: contractItems }),
                ...(action.payload.data?.metadata && { metadata: action.payload.data.metadata }),
                ...(action.payload.data?.cancel_at_period_end && {
                  cancel_at_period_end: action.payload.data.cancel_at_period_end,
                }),
              };
            }

            return contractItem;
          }),
        ],
      }),
    }),
    [`${editContract.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Get Contract List
    [`${getContracts.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
    }),
    [`${getContracts.fulfilled}`]: (state, action: { payload: State.ContractData[] }) => ({
      ...state,
      isLoading: false,
      list: action.payload,
    }),
    [`${getContracts.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Redirect to Checkout
    [`${redirectToCheckout.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
    }),
    [`${redirectToCheckout.fulfilled}`]: (state) => ({
      ...state,
    }),
    [`${redirectToCheckout.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Set Contract List
    [`${setContractList.type}`]: (state, action: { payload: State.ContractData[] }) => ({
      ...state,
      list: action.payload,
    }),

    // Set Contract Error Message
    [`${setContractErrorMessage.type}`]: (state, action: { payload: string }) => ({
      ...state,
      errorMessage: action.payload,
    }),

    // Set Contract Success Message
    [`${setContractSuccessMessage.type}`]: (state, action: { payload: string }) => ({
      ...state,
      successMessage: action.payload,
    }),
  },
  initialState,
  name: 'contract',
  reducers: {},
});

export { contractSlice };
