import React from 'react';
import { connect } from 'react-redux';

import { PageAuthLogin } from '@/pages/PageAuthLogin';
import { AppDispatch, RootState } from '@/state/store';
import { login, setUserErrorMessage } from '@/state/user/actions';
import { errorMessageSelector, isLoadingSelector, userDataSelector } from '@/state/user/selectors';

const mapActionCreators = (dispatch: AppDispatch) => ({
  login(params: { email: string; password: string }): void {
    dispatch(login(params));
  },
  setUserErrorMessage(error: string): void {
    dispatch(setUserErrorMessage(error));
  },
});

const mapStateToProps = (state: RootState) => ({
  email: userDataSelector(state)?.email,
  errorMessage: errorMessageSelector(state),
  isLoading: isLoadingSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageAuthLogin);
