import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { matchPath } from 'react-router-dom';

import { Customer, Product } from '@/collections/index';
import { DataType, Routes, Translations } from '@/constants/index';
import firebase from '@/firebase/index';
import NoImage from '@/resources/noimage.png';
import { deepFind, getPath, snakeCase } from '@/utils/index';

import { menuListSelector } from '../menu/selectors';
import { RootState } from '../store';
import { userDataSelector } from '../user/selectors';
import { ProductActions } from './constants';
import { formatProductName, formatProductPrices } from './helpers';

export const setDefaultSelectedRegion = createAction<string>(ProductActions.SET_DEFAULT_SELECTED_REGION);

export const setProductData = createAction<State.ProductData | undefined>(ProductActions.SET_PRODUCT_DATA);

export const getProduct = createAsyncThunk(ProductActions.GET_PRODUCT_DATA, async (uid: string) => {
  try {
    // Get product with prices
    const product = await new Product().getProductWithPrices(uid);

    const result = {
      ...product,
      displayName: formatProductName(product?.metadata?.label || ''),
      prices: formatProductPrices(product.prices || []),
    };

    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject();
  }
});

export const getProducts = createAsyncThunk(ProductActions.GET_PRODUCT_LIST, async (_) => {
  try {
    // Get products
    const products = await new Product().getProducts();

    const result = products?.map((product) => ({
      ...product,
      displayName: formatProductName(product?.metadata?.label || ''),
    }));

    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject();
  }
});

export const getProductDataDisplay = createAsyncThunk(ProductActions.GET_PRODUCT_DATA_DISPLAY, async (_, thunkAPI) => {
  try {
    const urlParams = matchPath(getPath(Routes.USER_VIEW_PRODUCT_DATA), location.pathname);
    if (!urlParams) {
      throw new Error('Invalid path');
    }

    const state = thunkAPI.getState() as RootState;
    const user = userDataSelector(state);
    const services = menuListSelector(state);

    // validation: get contract directly from firestore
    const contracts = await new Customer().getCustomerSubscriptions(user?.id || '');
    if (!contracts.length) {
      throw new Error('No Access');
    }

    // validation: find contract with the product === selectedRegion in subscription
    const contract = contracts.find(
      (contract) => snakeCase(contract.product?.metadata.label || '') === urlParams.params.productName
    );
    if (!contract) {
      throw new Error('No Access');
    }

    // validation: find service in services
    let service = deepFind((service: any) => service.name === urlParams.params.serviceName, services);
    if (!service) {
      throw new Error('No Access');
    }

    // validation: check if accessType exist in contract items
    const item = contract.items?.find((item) => snakeCase(item.price?.nickname || '') === service.accessType);
    if (!item) {
      throw new Error('No Access');
    }

    const fileName = `${urlParams?.params?.productName}/${service.dataType}${
      ![DataType.GAN, DataType.HIMAWARI].includes(service.dataType as DataType) ? `/${service.depth}` : ''
    }/${service.date}.png`;

    let file = NoImage;
    try {
      file = (await firebase.storage().ref().child(fileName).getDownloadURL()) as string;
    } catch (error) {}

    return Promise.resolve(file);
  } catch (error) {
    let message = i18next.t(Translations.ERROR_SOMETHING_WENT_WRONG);

    if ((error as Error)?.message === 'No Access') {
      message = i18next.t(Translations.ERROR_NO_ACCESS);
    }

    return Promise.reject(new Error(message));
  }
});
