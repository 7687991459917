import React from 'react';
import { Button as SemanticUIButton, StrictButtonProps } from 'semantic-ui-react';
import styled, { DefaultTheme, ThemedStyledProps } from 'styled-components';

export enum ButtonVariants {
  BOXED = 'boxed',
  DANGER = 'danger',
  DEFAULT = 'default',
  INVERTED = 'inverted',
  INVERTED_DANGER = 'inverted_danger',
  PRIMARY = 'primary',
}
export interface Props extends StrictButtonProps {
  testId: string;
  variant: ButtonVariants;
  href?: string;
  to?: string;
}

export const Button: React.FC<Props> = ({ children, testId, ...props }) => (
  <StyledButton data-testid={`button-${testId}`} size="large" {...props}>
    {children && <span>{children}</span>}
  </StyledButton>
);

const StyledButton = styled(SemanticUIButton)<ThemedStyledProps<Props, DefaultTheme>>`
  ${({ theme, variant }) => `
  &&& {
    font-weight: ${theme.fontWeights.regular};
    transition: all 0.3s;

    &:disabled {
      color: #F7F7F4;
      background-color: ${theme.colors.neutralGreyForButtons};
      border: none;
      opacity: 1 !important;
    }

    // Danger Variant
    ${
      variant === ButtonVariants.DANGER
        ? `
      background-color: ${theme.colors.neutralRed};
      border-radius: ${theme.pxToRem(25)};
      color: ${theme.colors.neutralWhite};

      &:hover {
        box-shadow: 0 ${theme.pxToRem(4)} ${theme.pxToRem(8)} 0 rgba(245, 97, 97, 0.3), 0 ${theme.pxToRem(
            6
          )} ${theme.pxToRem(20)} 0 rgba(245, 97, 97, 0.19);
      };
    `
        : ``
    };

    // Default Variant
    ${
      variant === ButtonVariants.DEFAULT
        ? `
      border: ${theme.pxToRem(1)} solid ${theme.colors.neutralGreyForButtons};
      background-color: ${theme.colors.neutralWhite};
      border-radius: ${theme.pxToRem(4)};
      color: ${theme.colors.neutralBlack};
      padding: ${theme.pxToRem(20)};

      &:hover {
        background-color: ${theme.colors.neutralGreyForButtons};
      };
    `
        : ``
    };

    // Boxed Variant
    ${
      variant === ButtonVariants.BOXED
        ? `
      background-color: ${theme.colors.primary};
      border-radius: ${theme.pxToRem(4)};
      color: ${theme.colors.neutralWhite};
      padding: ${theme.pxToRem(6)};

      &:hover {
        box-shadow: 0 ${theme.pxToRem(4)} ${theme.pxToRem(8)} 0 rgba(38, 102, 207, 0.3), 0 ${theme.pxToRem(
            6
          )} ${theme.pxToRem(20)} 0 rgba(38, 102, 207, 0.19);
      };
    `
        : ``
    };

    // Inverted Variant
    ${
      variant === ButtonVariants.INVERTED
        ? `
      border: ${theme.pxToRem(1)} solid ${theme.colors.primary};
      background-color: transparent;
      border-radius: ${theme.pxToRem(25)};
      color: ${theme.colors.primary};

      &:hover {
        color: ${theme.colors.neutralWhite};
        background-color: ${theme.colors.primary};
      };
    `
        : ``
    };

    // Inverted Danger Variant
    ${
      variant === ButtonVariants.INVERTED_DANGER
        ? `
      border: ${theme.pxToRem(1)} solid ${theme.colors.neutralRed};
      background-color: transparent;
      border-radius: ${theme.pxToRem(25)};
      color: ${theme.colors.neutralRed};

      &:hover {
        color: ${theme.colors.neutralWhite};
        background-color: ${theme.colors.neutralRed};
      };
    `
        : ``
    };

    // Primary Variant
    ${
      variant === ButtonVariants.PRIMARY
        ? `
      background-color: ${theme.colors.primary};
      border-radius: ${theme.pxToRem(25)};
      color: ${theme.colors.neutralWhite};

      &:hover {
        box-shadow: 0 ${theme.pxToRem(4)} ${theme.pxToRem(8)} 0 rgba(38, 102, 207, 0.3), 0 ${theme.pxToRem(
            6
          )} ${theme.pxToRem(20)} 0 rgba(38, 102, 207, 0.19);
      };
    `
        : ``
    };
  }
  `}
`;
