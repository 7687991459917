import React from 'react';
import { Grid, Sidebar as SemanticUISidebar } from 'semantic-ui-react';
import styled from 'styled-components';

import { ButtonLink, ButtonVariants, Title } from '@/atoms/index';
import { Routes } from '@/constants/index';
import { CheckboxRegions } from '@/molecules/index';
import { CloseIcon } from '@/resources/icons';
import { getPath } from '@/utils/index';

interface Props {
  contractList: State.ContractData[];
  defaultSelectedRegion: string;
  isVisible: boolean;
  productList: State.ProductData[];
  onCloseSidebar: () => void;
  onSetDefaultRegion: (region: string) => void;
}

export const SidebarOld: React.FC<Props> = ({
  contractList,
  defaultSelectedRegion,
  isVisible,
  productList,
  onCloseSidebar,
  onSetDefaultRegion,
}) => {
  const [selectedRegion, setSelectedRegion] = React.useState('');

  const handleSelectRegion = (regionId: string) => {
    onSetDefaultRegion(regionId);
    setSelectedRegion(regionId);
  };

  React.useEffect(() => {
    if (defaultSelectedRegion) {
      setSelectedRegion(defaultSelectedRegion);
    } else if (productList.length) {
      onSetDefaultRegion(productList[0]?.id);
      setSelectedRegion(productList[0]?.id);
    }
  }, [defaultSelectedRegion, onSetDefaultRegion, productList]);

  return (
    <SemanticUISidebar
      inverted
      vertical
      animation="overlay"
      as={StyledSidebar}
      direction="right"
      visible={isVisible}
      width="wide"
    >
      <StyledSidebarWrapper>
        <StyledContentWrapper>
          <StyledGridHeader>
            <Grid.Column width={12}>
              <Title as="h3">Sea area selection</Title>
            </Grid.Column>

            <Grid.Column textAlign="right" width={4}>
              <StyledImage alt="cog" src={CloseIcon} onClick={onCloseSidebar} />
            </Grid.Column>
          </StyledGridHeader>

          <CheckboxRegions regions={productList} selectedRegion={selectedRegion} onSelectRegion={handleSelectRegion} />

          <StyledMenuWrapper>
            <StyledGridHeader>
              <Grid.Column>
                <Title as="h3">Service selection</Title>
              </Grid.Column>
            </StyledGridHeader>

            {/* <SidebarMenu menu={menu} /> */}
          </StyledMenuWrapper>
        </StyledContentWrapper>
        <StyledFooterWrapper>
          <StyledButtonLink testId="top-page" to={getPath(Routes.HOME)} variant={ButtonVariants.DEFAULT}>
            Go to Top Page
          </StyledButtonLink>
        </StyledFooterWrapper>
      </StyledSidebarWrapper>
    </SemanticUISidebar>
  );
};

const StyledSidebar = styled.div`
  background-color: ${(props) => props.theme.colors.neutralBrown};
  padding: ${(props) => props.theme.pxToRem(20)};
`;

const StyledSidebarWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledContentWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(30)};
`;

const StyledFooterWrapper = styled.div``;

const StyledGridHeader = styled(Grid)`
  &&& {
    margin-bottom: 0;
  }
`;

const StyledImage = styled.img`
  cursor: pointer;
`;

const StyledMenuWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(30)};
`;

const StyledButtonLink = styled(ButtonLink)`
  &&& {
    display: block;
  }
`;
