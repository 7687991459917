import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button, ButtonLink,  ButtonVariants, Input, Message } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { getPath, isEmail } from '@/utils/index';

interface Props {
  errorMessage: string;
  isLoading: boolean;
  successMessage: string;
  onPasswordReset: (params: { email: string }) => void;
}

export const FormPasswordReset: React.FC<Props> = ({ errorMessage, isLoading, onPasswordReset, successMessage }) => {
  const { t } = useTranslation();

  const [email, setEmail] = React.useState('');

  const isSubmitButtonDisabled = !Boolean(email) || !isEmail(email) || isLoading;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onPasswordReset({ email });
  };

  React.useEffect(() => {
    if (successMessage) {
      setEmail('');
    }
  }, [successMessage]);

  return (
    <>
      {Boolean(errorMessage) && <Message error content={errorMessage} />}
      {Boolean(successMessage) && <Message success content={successMessage} />}
      <Form size="large" onSubmit={handleSubmit}>
        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_EMAIL)}</StyledLabel>
          <Input
            fluid
            placeholder={t(Translations.PLACEHOLDER_EMAIL)}
            testId="email"
            value={email}
            onChange={handleEmailChange}
          />
        </Form.Field>

        <StyledButtonWrapper>
        <ButtonLink testId="cancel" to={getPath(Routes.HOME)} variant={ButtonVariants.INVERTED}>
            Cancel
          </ButtonLink>
          <Button
            disabled={isSubmitButtonDisabled}
            loading={isLoading}
            testId="next"
            type="submit"
            variant={ButtonVariants.PRIMARY}
          >
            {t(Translations.BUTTON_NEXT)}
          </Button>
        </StyledButtonWrapper>
      </Form>
    </>
  );
};

const StyledLabel = styled.div`
  color: ${(props) => props.theme.colors.neutralGreyForLabels};
  margin-left: ${(props) => props.theme.pxToRem(5)};
  padding-bottom: ${(props) => props.theme.pxToRem(5)};
`;


const StyledButtonWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(70)};
  text-align: right;

  &&& {
    a {
      margin-right: ${(props) => props.theme.pxToRem(10)};
    }
  }
`;
