import { CardNumberElement as StripeCardNumberElement } from '@stripe/react-stripe-js';
import { StripeCardNumberElementChangeEvent, StripeCardNumberElementOptions } from '@stripe/stripe-js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Translations } from '@/constants/index';

interface Props {
  error: string;
  handleInputChange: (name: string, label: string) => (elementData: StripeCardNumberElementChangeEvent) => void;
}

const CARD_NUMBER_ELEMENT_OPTIONS: StripeCardNumberElementOptions = {
  iconStyle: 'default',
  showIcon: true,
  style: {
    base: {
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: 'lightgrey',
      },
      color: 'black',
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      fontWeight: 400,
      iconColor: 'black',
    },
    invalid: {
      color: 'red',
      iconColor: 'red',
    },
  },
};

export const CardNumber: React.FC<Props> = ({ error, handleInputChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledLabel>{t(Translations.LABEL_CARD_NUMBER)}</StyledLabel>
      <StripeCardNumberElement
        options={CARD_NUMBER_ELEMENT_OPTIONS}
        onChange={handleInputChange('cardNumber', 'Card Number')}
      />
      <StyledErrorMessage>{error}</StyledErrorMessage>
    </>
  );
};

const StyledLabel = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: ${(props) => props.theme.pxToRem(10)};
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.colors.neutralRed};
  margin-top: ${(props) => props.theme.pxToRem(5)};
  font-size: ${(props) => props.theme.pxToRem(16)};
`;
