import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const rootSelector = createSelector(
  (state: RootState): State.App => state.app,
  (app: State.App) => app
);

export const isInitSelector = createSelector(rootSelector, (app: State.App): boolean => app.isInit);

export const isLoadingSelector = createSelector(rootSelector, (app: State.App): boolean => app.isLoading);
