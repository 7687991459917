import './style.css';

import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
  StripeElements,
} from '@stripe/stripe-js';
import countryList from 'country-list';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownProps, Form, Message, Segment, Select } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button, ButtonVariants, Input } from '@/atoms/index';
import { Translations } from '@/constants/index';

import { CardCvc } from './CardCvc';
import { CardExpirationDate } from './CardExpirationDate';
import { CardNumber } from './CardNumber';

interface Props {
  errorMessage: string;
  isLoading: boolean;
  elements: StripeElements;
  stripe: Stripe;
  successMessage: string;
  userData?: State.UserData;
  onCreateCard: (params: {
    name: string;
    stripe: Stripe;
    elementComponent: StripeCardNumberElement;
    clearForm: () => void;
    billing: {
      city: string;
      country: string;
      line1: string;
      line2?: string;
      state?: string;
    };
  }) => void;
}

export const FormCardRegistration: React.FC<Props> = ({
  errorMessage,
  isLoading,
  stripe,
  successMessage,
  userData,
  elements,
  onCreateCard,
}) => {
  const { t } = useTranslation();

  const [name, setName] = React.useState('');
  const [lineOne, setLineOne] = React.useState('');
  const [lineTwo, setLineTwo] = React.useState('');
  const [city, setCity] = React.useState('');
  const [state, setState] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [elementsData, setElementsData] = React.useState<{
    cardCvc: {
      error: string;
      isCompleted: boolean;
    };
    cardExpiryDate: {
      error: string;
      isCompleted: boolean;
    };
    cardNumber: {
      error: string;
      isCompleted: boolean;
    };
  }>({
    cardCvc: {
      error: '',
      isCompleted: false,
    },
    cardExpiryDate: {
      error: '',
      isCompleted: false,
    },
    cardNumber: {
      error: '',
      isCompleted: false,
    },
  });

  const options = countryList
    .getData()
    .map((country, index) => ({ key: index, text: country.name, value: country.code }));

  const isCardCvcInvalid = !elementsData.cardCvc.isCompleted || Boolean(elementsData.cardCvc.error);
  const isCardExpiryDateInvalid =
    !elementsData.cardExpiryDate.isCompleted || Boolean(elementsData.cardExpiryDate.error);
  const isCardCardNumberDateInvalid = !elementsData.cardNumber.isCompleted || Boolean(elementsData.cardNumber.error);
  const isSubmitButtonDisabled =
    isCardCvcInvalid ||
    isCardExpiryDateInvalid ||
    isCardCardNumberDateInvalid ||
    (i18next.language === 'jp' ? false : !lineOne || !city || !country);

  // const isSubmitButtonDisabled = isCardCvcInvalid || isCardExpiryDateInvalid || isCardCardNumberDateInvalid;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleLineOneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLineOne(event.target.value);
  };

  const handleLineTwoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLineTwo(event.target.value);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

  const handleCountryChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data?.value) {
      setCountry(data.value as string);
    }
  };

  const handleCardElementInputChange =
    (name: string, label: string) =>
    (
      elementData:
        | StripeCardNumberElementChangeEvent
        | StripeCardCvcElementChangeEvent
        | StripeCardExpiryElementChangeEvent
    ) => {
      let paymentInputObject = { [name]: { error: '', isCompleted: false } };

      // check if element data is completed
      if (!elementData.complete) {
        paymentInputObject[name] = { ...paymentInputObject[name], isCompleted: false };
      } else {
        paymentInputObject[name] = { ...paymentInputObject[name], isCompleted: true };
      }

      // check if element data has error
      if (elementData.error) {
        paymentInputObject[name] = { ...paymentInputObject[name], error: `Your ${label} is invalid.` };
      } else {
        paymentInputObject[name] = { ...paymentInputObject[name], error: '' };
      }

      setElementsData({ ...elementsData, ...paymentInputObject });
    };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const elementComponent = elements.getElement(CardNumberElement);

    onCreateCard({
      billing: { city, country, line1: lineOne, line2: lineTwo, state },
      clearForm: handleClearForm,
      elementComponent: elementComponent!,
      name,
      stripe,
    });
  };

  const handleClearForm = () => {
    elements.getElement(CardNumberElement)?.clear();
    elements.getElement(CardCvcElement)?.clear();
    elements.getElement(CardExpiryElement)?.clear();
    setLineOne('');
    setLineTwo('');
    setCity('');
    setState('');
    setCountry('');
  };

  React.useEffect(() => {
    if (userData?.name) {
      setName(userData?.name);
    }
  }, [userData]);

  return (
    <StyledSegment stacked>
      <Form size="large" onSubmit={handleSubmit}>
        <Form.Field>
          <label>{t(Translations.LABEL_CARD_DETAILS)}</label>
        </Form.Field>

        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_NAME)}</StyledLabel>
          <Input fluid placeholder={'Name'} testId="name" value={name} onChange={handleNameChange} />
        </Form.Field>

        {/* <CardSection /> */}
        <Form.Field>
          <CardNumber error={elementsData.cardNumber.error} handleInputChange={handleCardElementInputChange} />
        </Form.Field>
        <Form.Field>
          <CardCvc error={elementsData.cardCvc.error} handleInputChange={handleCardElementInputChange} />
        </Form.Field>
        <Form.Field>
          <CardExpirationDate
            error={elementsData.cardExpiryDate.error}
            handleInputChange={handleCardElementInputChange}
          />
        </Form.Field>

        <Form.Field>
          <label>{t(Translations.LABEL_CARD_BILLING_ADDRESS)}</label>
        </Form.Field>

        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_CARD_BILLING_LINE_ONE)}</StyledLabel>
          <Input
            fluid
            placeholder={t(Translations.LABEL_CARD_BILLING_LINE_ONE)}
            testId="lineOne"
            value={lineOne}
            onChange={handleLineOneChange}
          />
        </Form.Field>

        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_CARD_BILLING_LINE_TWO)}</StyledLabel>
          <Input
            fluid
            placeholder={t(Translations.LABEL_CARD_BILLING_LINE_TWO)}
            testId="lineTwo"
            value={lineTwo}
            onChange={handleLineTwoChange}
          />
        </Form.Field>

        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_CARD_BILLING_COUNTRY)}</StyledLabel>
          <Input
            fluid
            placeholder={t(Translations.LABEL_CARD_BILLING_COUNTRY)}
            testId="city"
            value={city}
            onChange={handleCityChange}
          />
        </Form.Field>

        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_CARD_BILLING_ADDRESS)}</StyledLabel>
          <Input fluid placeholder={'State'} testId="state" value={state} onChange={handleStateChange} />
        </Form.Field>

        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_CARD_BILLING_ADDRESS)}</StyledLabel>
          <Select
            fluid
            search
            disabled={isLoading || i18next.language === 'jp'}
            options={options}
            value={i18next.language === 'jp' ? 'JP' : country}
            onChange={handleCountryChange}
          />
        </Form.Field>

        {Boolean(errorMessage) && <Message color="red">{errorMessage}</Message>}
        {Boolean(successMessage) && <Message color="green">{successMessage}</Message>}

        <StyledButtonContainer>
          <Button
            disabled={isLoading || isSubmitButtonDisabled}
            loading={isLoading}
            testId="save-card"
            type="submit"
            variant={ButtonVariants.PRIMARY}
          >
            Save Card
          </Button>
        </StyledButtonContainer>
      </Form>
    </StyledSegment>
  );
};

const StyledSegment = styled(Segment)`
  &&& {
    :after {
      width: 0;
    }
  }
`;

const StyledLabel = styled.label`
  &&&& {
    font-weight: ${(props) => props.theme.fontWeights.regular};
  }
`;

const StyledButtonContainer = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(20)};
  text-align: right;

  &&&& {
    button,
    a {
      margin-right: 0;

      &:first-child {
        margin-right: ${(props) => props.theme.pxToRem(15)};
      }
    }
  }
`;
