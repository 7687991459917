import React from 'react';
import { connect } from 'react-redux';

import { PageUserEditContract } from '@/pages/PageUserEditContract';
import { editContract, setContractErrorMessage, setContractSuccessMessage } from '@/state/contract/actions';
import {
  contractListSelector,
  errorMessageSelector,
  isLoadingSelector,
  successMessageSelector,
} from '@/state/contract/selectors';
import { getProduct, setProductData } from '@/state/product/actions';
import { productDataSelector } from '@/state/product/selectors';
import { AppDispatch, RootState } from '@/state/store';

const mapActionCreators = (dispatch: AppDispatch) => ({
  clearProduct(): void {
    dispatch(setProductData(undefined));
  },
  editContract(params: {
    contractId: string;
    toRecoverItems?: State.StripeSubscriptionItem[];
    toRemoveItems?: State.StripeSubscriptionItem[];
    toPurchaseItems?: State.PriceData[];
  }): void {
    dispatch(editContract(params));
  },
  getProduct(uid: string): void {
    dispatch(getProduct(uid));
  },
  setContractErrorMessage(errorMessage: string): void {
    dispatch(setContractErrorMessage(errorMessage));
  },
  setContractSuccessMessage(successMessage: string): void {
    dispatch(setContractSuccessMessage(successMessage));
  },
});

const mapStateToProps = (state: RootState) => ({
  contractList: contractListSelector(state),
  errorMessage: errorMessageSelector(state),
  isContractLoading: isLoadingSelector(state),
  productData: productDataSelector(state),
  successMessage: successMessageSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageUserEditContract);
