import * as dateFns from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { EmptyPlaceHolder } from '@/molecules/index';
import { HeaderWithBack } from '@/organisms/index';
import { FooterAuth } from '@/organisms/index';
import { SingleColumn } from '@/templates/index';
import { getPath } from '@/utils/index';

interface Props {
  invoiceList: State.InvoiceData[];
  isLoading: boolean;
  getInvoices: () => void;
}

export const PageUserPurchases: React.FC<Props> = ({ invoiceList, isLoading, getInvoices }) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return (
    <SingleColumn>
      <>
        <HeaderWithBack path={getPath(Routes.USER_SETTINGS)} title={t(Translations.TITLE_PURCHASE_HISTORY)} />
      </>
      <>
        {isLoading && <Loader active content="Loading" />}
        {!isLoading && !invoiceList.length && <EmptyPlaceHolder />}
        {!isLoading && Boolean(invoiceList.length) && (
          <StyledTable unstackable>
            <StyledTableHeader>
              <Table.Row>
                <Table.HeaderCell width={2}>{t(Translations.LABEL_DATE)}</Table.HeaderCell>
                <Table.HeaderCell width={1}>{t(Translations.LABEL_TOTAL)}</Table.HeaderCell>
              </Table.Row>
            </StyledTableHeader>

            <Table.Body>
              {invoiceList.map((invoice) => (
                <Table.Row key={invoice.id}>
                  <Table.Cell>
                    <Link isExternal testId="invoice-link" to={invoice.invoice_pdf as string}>
                      {dateFns.format(new Date(invoice.created * 1000), 'yyyy-MM-dd')}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {invoice.total} {invoice.currency.toUpperCase()}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </StyledTable>
        )}
      </>
      <>
        <FooterAuth />
      </>
      /.
    </SingleColumn>
  );
};

const StyledTable = styled(Table)`
  &&& {
    border-radius: 0;
    margin-bottom: ${(props) => props.theme.pxToRem(1)};
    margin-top: ${(props) => props.theme.pxToRem(1)};
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& {
    th {
      background-color: ${(props) => props.theme.colors.neutralWhite};
      color: ${(props) => props.theme.colors.neutralGreyForHeaders};
      font-size: ${(props) => props.theme.fontSizes.s};
    }
  }
`;
