import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonVariants, Modal, ModalVariants } from '@/atoms/index';
import { Translations } from '@/constants/index';
import { WarningIcon } from '@/resources/icons';

interface Props {
  isLoading: boolean;
  isVisible: boolean;
  onCloseModal: () => void;
  onDelete: () => void;
}

export const ModalDeleteCard: React.FC<Props> = ({ isLoading, isVisible, onCloseModal, onDelete }) => {
  const { t } = useTranslation();

  return (
    <Modal
      cancelNode={
        <Button
          disabled={isLoading}
          testId="cancel-modal"
          variant={ButtonVariants.INVERTED_DANGER}
          onClick={onCloseModal}
        >
          {t(Translations.BUTTON_CANCEL)}
        </Button>
      }
      confirmNode={
        <Button
          disabled={isLoading}
          loading={isLoading}
          testId="confirm-modal"
          variant={ButtonVariants.DANGER}
          onClick={onDelete}
        >
          {t(Translations.BUTTON_OK)}
        </Button>
      }
      description={t(Translations.DESCRIPTION_DELETE_CARD)}
      icon={WarningIcon}
      open={isVisible}
      testId="delete-modal-card"
      title={t(Translations.LABEL_DELETE_CARD)}
      variant={ModalVariants.DANGER}
    />
  );
};
