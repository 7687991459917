import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { ButtonLink, ButtonVariants, Title } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { CheckboxRegions, ServiceSelectionMenu } from '@/molecules/index';
import { CloseIcon } from '@/resources/icons';
import { getPath } from '@/utils/index';

interface Props {
  contractList: State.ContractData[];
  menuList: State.MenuData[];
  selectedRegion: string;
  closeSidebar: () => void;
  openUnPurchasedModal: () => void;
  selectRegion: (regionId: string) => void;
}

export const ServiceSelectionSidebar: React.FC<Props> = ({
  menuList,
  contractList,
  selectedRegion,
  closeSidebar,
  openUnPurchasedModal,
  selectRegion,
}) => {
  const { t } = useTranslation();

  const productList = contractList.map((contract) => contract.product);

  const handleSelectRegion = React.useCallback(
    (regionId: string) => {
      selectRegion(regionId);
    },
    [selectRegion]
  );

  const handleServiceOnClick = (disabled: boolean) => {
    if (!disabled) {
      closeSidebar();
    } else {
      openUnPurchasedModal();
    }
  };

  React.useEffect(() => {
    if (!selectedRegion && contractList.length && contractList[0].product?.id) {
      handleSelectRegion(contractList[0].product.id);
    }
  }, [handleSelectRegion, contractList, selectedRegion]);

  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <StyledGridHeader>
          <Grid.Column width={12}>
            <Title as="h3">{t(Translations.LABEL_SEA_AREA_SELECTION)}</Title>
          </Grid.Column>

          <Grid.Column textAlign="right" width={4}>
            <StyledImage alt="cog" src={CloseIcon} onClick={closeSidebar} />
          </Grid.Column>
        </StyledGridHeader>

        {/* Region check boxes */}
        {Boolean(productList.length) && (
          <CheckboxRegions regions={productList!} selectedRegion={selectedRegion} onSelectRegion={handleSelectRegion} />
        )}

        <StyledMenuWrapper>
          <StyledGridHeader>
            <Grid.Column>
              <Title as="h3">{t(Translations.LABEL_SERVICE_SELECTION)}</Title>
            </Grid.Column>
          </StyledGridHeader>

          {/* Service selection menu */}
          <ServiceSelectionMenu menu={menuList} onClick={handleServiceOnClick} />
        </StyledMenuWrapper>
      </StyledContentWrapper>
      <StyledFooterWrapper>
        <StyledButtonLink testId="top-page" to={getPath(Routes.HOME)} variant={ButtonVariants.DEFAULT}>
          {t(Translations.LABEL_TOP_PAGE)}
        </StyledButtonLink>
      </StyledFooterWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
`;

const StyledContentWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(30)};
`;

const StyledFooterWrapper = styled.div``;

const StyledGridHeader = styled(Grid)`
  &&& {
    margin-bottom: 0;
  }
`;

const StyledImage = styled.img`
  cursor: pointer;
`;

const StyledMenuWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(30)};
`;

const StyledButtonLink = styled(ButtonLink)`
  &&& {
    display: block;
  }
`;
