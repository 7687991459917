interface IRoute {
  path: string;
  title?: string;
}

export enum Routes {
  // Auth routes
  CHECK_EMAIL = 'CHECK_EMAIL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  PASSWORD_RESET = 'PASSWORD_RESET',

  CHECKOUT_RESULT = 'CHECKOUT_RESULT',

  //
  HOME = 'HOME',

  // Card routes
  USER_ADD_CARD = 'ADD_CARD',
  USER_CARDS = 'CARDS',

  // Contract routes
  USER_ADD_CONTRACT = 'ADD_CONTRACT',
  USER_CONTRACTS = 'CONTRACTS',
  USER_EDIT_CONTRACT = 'EDIT_CONTRACT',

  // Products Routes
  USER_VIEW_PRODUCT_DATA = 'USER_VIEW_PRODUCT_DATA',

  // Purchases Routes
  USER_PURCHASES = 'PURCHASES',

  // Settings Routes
  USER_SETTINGS = 'SETTINGS',

  // Usages Routes
  USER_USAGES = 'USAGES',
}

export const fullRoutes: { [key: string]: IRoute } = {
  [Routes.HOME]: {
    path: '/',
  },

  [Routes.CHECKOUT_RESULT]: {
    path: '/checkout/result',
    title: 'Checkout Result',
  },

  // Auth routes
  [Routes.CHECK_EMAIL]: {
    path: '/check-email',
    title: 'Check Email',
  },
  [Routes.LOGIN]: {
    path: '/login',
    title: 'Login',
  },
  [Routes.REGISTER]: {
    path: '/register',
    title: 'Register',
  },
  [Routes.PASSWORD_RESET]: {
    path: '/password-reset',
    title: 'Password Reset',
  },

  // Cards routes
  [Routes.USER_ADD_CARD]: {
    path: '/user/cards/add',
    title: 'Add Card',
  },
  [Routes.USER_CARDS]: {
    path: '/user/cards',
    title: 'Contracts',
  },

  // Contracts routes
  [Routes.USER_ADD_CONTRACT]: {
    path: '/user/contracts/add',
    title: 'Add Contract',
  },
  [Routes.USER_CONTRACTS]: {
    path: '/user/contracts',
    title: 'Contracts',
  },
  [Routes.USER_EDIT_CONTRACT]: {
    path: '/user/contracts/:id',
    title: 'Edit Contract',
  },

  // Products routes
  [Routes.USER_VIEW_PRODUCT_DATA]: {
    path: '/user/products/:productName/services/:serviceName',
    title: 'View Product Data',
  },

  // Purchases routes
  [Routes.USER_PURCHASES]: {
    path: '/user/purchases',
    title: 'Purchases',
  },

  // Settings routes
  [Routes.USER_SETTINGS]: {
    path: '/user/settings',
    title: 'Settings',
  },

  // Usages routes
  [Routes.USER_USAGES]: {
    path: '/user/usages',
    title: 'Usage',
  },
};
