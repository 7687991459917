export enum CardActions {
  CREATE_CARD = 'card/CREATE_CARD',
  DELETE_CARD = 'card/DELETE_CARD',
  GET_CARD_LIST = 'card/GET_CARD_LIST',
  SET_CARD_DATE_RETRIEVED = 'card/SET_CARD_DATE_RETRIEVED',
  SET_CARD_LIST = 'card/SET_CARD_LIST',
  SET_CARD_ERROR_MESSAGE = 'card/SET_CARD_ERROR_MESSAGE',
  SET_CARD_SUCCESS_MESSAGE = 'card/SET_CARD_SUCCESS_MESSAGE',
  REDIRECT_TO_CHECKOUT = 'card/REDIRECT_TO_SETUP_CHECKOUT',
}
