import React from 'react';
import { Header, HeaderProps } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props extends HeaderProps {}

export const Title: React.FC<Props> = ({ children, ...props }) => (
  <StyledCompanyText {...props}>{children}</StyledCompanyText>
);

const StyledCompanyText = styled(Header)`
  &&& {
    color: ${(props) => props.theme.colors.neutralBlack};
    padding: 0;
    margin: 0;
  }
`;
