import React from 'react';
import styled from 'styled-components';

import { Checkbox } from '@/atoms/index';

interface Props {
  regions?: (State.ProductData | undefined)[];
  selectedRegion: string;
  onSelectRegion: (regionId: string) => void;
}

export const CheckboxRegions: React.FC<Props> = ({ regions, selectedRegion, onSelectRegion }) => {
  return (
    <>
      {regions?.map((region) => (
        <StyledWrapper key={region?.id} onClick={() => onSelectRegion(region?.id as string)}>
          <Checkbox
            radio
            checked={selectedRegion === region?.id}
            label={region?.displayName || region?.metadata?.label || region?.name}
            testId={region?.name as string}
          />
        </StyledWrapper>
      ))}
    </>
  );
};

const StyledWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutralGreyForListItems};
  border: ${(props) => `${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGreyForListItemBorders}`};
  border-radius: ${(props) => props.theme.pxToRem(5)};
  cursor: pointer;
  display: block;
  padding: ${(props) => `${props.theme.pxToRem(10)} ${props.theme.pxToRem(25)}`};
  margin-bottom: ${(props) => props.theme.pxToRem(10)};

  &&& {
    label {
      font-size: ${(props) => props.theme.fontSizes.m};
    }
  }
`;
