import React from 'react';
import { Container as SemanticUIContainer, ContainerProps } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props extends ContainerProps {}

export const Container: React.FC<Props> = ({ children, ...props }) => (
  <StyledContainer {...props}>{children}</StyledContainer>
);

const StyledContainer = styled(SemanticUIContainer)`
  &&& {
    @media only screen and (max-width: 767px) {
      margin-left: ${(props) => props.theme.pxToRem(20)} !important;
      margin-right: ${(props) => props.theme.pxToRem(20)} !important;
    }
  }
`;
