import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownProps, Select } from 'semantic-ui-react';
import styled from 'styled-components';

import { Message } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { FormCreateContract, ModalPurchaseConfirmation } from '@/molecules/index';
import { HeaderWithBack } from '@/organisms/index';
import { FooterAuth } from '@/organisms/index';
import { SingleColumn } from '@/templates/index';
import { getPath } from '@/utils/index';

interface Props {
  cardList: State.CardData[];
  isContractLoading: boolean;
  isLoading: boolean;
  productData: State.ProductData | undefined;
  unPurchasedProductList: State.ProductData[];
  clearProduct: () => void;
  getProduct: (uid: string) => void;
  redirectToCheckout: (params: { productId: string; prices: State.PriceData[] }) => void;
}

export const PageUserAddContract: React.FC<Props> = ({
  cardList,
  isContractLoading,
  isLoading,
  productData,
  unPurchasedProductList,
  clearProduct,
  getProduct,
  redirectToCheckout,
}) => {
  const { t } = useTranslation();

  const [isPurchaseModalVisible, setIsPurchaseModalVisible] = React.useState(false);
  const [selectedPrices, setSelectedPrices] = React.useState<State.PriceData[]>([]);

  const options = unPurchasedProductList.map((product) => ({
    key: product.id,
    text: product?.displayName || product?.metadata?.label || product.name,
    value: product.id,
  }));

  const handleSelectOnChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (data.value) {
      getProduct(data.value as string);
    }
  };

  const handleToggleModalVisibility = () => setIsPurchaseModalVisible(!isPurchaseModalVisible);

  const handleRedirectToCheckout = () => {
    redirectToCheckout({ prices: selectedPrices, productId: productData?.id || '' });
  };

  React.useEffect(() => {
    clearProduct();
  }, [clearProduct]);

  return (
    <SingleColumn>
      <>
        <HeaderWithBack path={getPath(Routes.USER_CONTRACTS)} title={t(Translations.TITLE_ADD_CONTRACT)} />
      </>
      <>
        {!cardList.length && <Message error content={t(Translations.DESCRIPTION_NO_REGISTERED_CARD)} />}

        {Boolean(cardList.length) && (
          <>
            <Select
              fluid
              disabled={isLoading}
              loading={isLoading}
              options={options}
              placeholder={t(Translations.PLACEHOLDER_SELECT_PRODUCT)}
              onChange={handleSelectOnChange}
            />

            {productData && (
              <StyledFormWrapper>
                <FormCreateContract
                  product={productData}
                  selectedPrices={selectedPrices}
                  setSelectedPrices={setSelectedPrices}
                  onPurchase={handleToggleModalVisibility}
                />
              </StyledFormWrapper>
            )}

            <ModalPurchaseConfirmation
              isLoading={isContractLoading}
              isVisible={isPurchaseModalVisible}
              product={productData}
              selectedPrices={selectedPrices}
              onCloseModal={handleToggleModalVisibility}
              onConfirmPurchase={handleRedirectToCheckout}
            />
          </>
        )}
      </>
      <>
        <FooterAuth />
      </>
      /.
    </SingleColumn>
  );
};

const StyledFormWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(30)};
`;
