import React from 'react';
import { useTranslation } from 'react-i18next';
import stringReplace from 'react-string-replace';
import styled from 'styled-components';

import { ExternalLinks, Translations } from '@/constants/index';

import { Link } from '../Link/Link';

export const LegalAgreement: React.FC = () => {
  const { t } = useTranslation();

  const description = stringReplace(t(Translations.DESCRIPTION_LEGAL_AGREEMENTS), '_', (match, index) => {
    if (index === 1) {
      return (
        <Link key="terms-of-service" target="_blank" testId="terms-of-service" to={ExternalLinks.TERMS_OF_SERVICE}>
          {t(Translations.LINK_TERMS_OF_SERVICE)}
        </Link>
      );
    }

    return (
      <Link key="privacy-policy" target="_blank" testId="privacy-policy" to={ExternalLinks.PRIVACY_POLICY}>
        {t(Translations.LINK_PRIVACY_POLICY)}
      </Link>
    );
  });

  return <StyledDescription>{description}</StyledDescription>;
};

const StyledDescription = styled.p`
  font-size: ${(props) => props.theme.fontSizes.s};
`;
