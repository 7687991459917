import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { appSlice } from './app';
import { cardSlice } from './card';
import { contractSlice } from './contract';
import { invoiceSlice } from './invoice';
import { layoutSlice } from './layout';
import { menuSlice } from './menu';
import { productSlice } from './product';
import { userSlice } from './user';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['card', 'user'],
};

const cardPersistConfig = {
  key: 'card',
  storage,
  whitelist: ['dateRetrieved'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['data'],
};

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const rootReducer = combineReducers({
  app: appSlice.reducer,
  card: persistReducer(cardPersistConfig, cardSlice.reducer),
  contract: contractSlice.reducer,
  invoice: invoiceSlice.reducer,
  layout: layoutSlice.reducer,
  menu: menuSlice.reducer,
  product: productSlice.reducer,
  router: routerReducer,
  user: persistReducer(userPersistConfig, userSlice.reducer),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(routerMiddleware),
  reducer: persistedReducer,
});

const persistor = persistStore(store);

const history = createReduxHistory(store);

type AppDispatch = typeof store.dispatch;

type RootState = ReturnType<typeof store.getState>;

export { history, persistor, store };

export type { AppDispatch, RootState };
