import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button, ButtonVariants, Modal, ModalVariants } from '@/atoms/index';
import { Translations } from '@/constants/index';
import { BasketIcon } from '@/resources/icons';

interface Props {
  isLoading: boolean;
  isVisible: boolean;
  product?: State.ProductData;
  selectedPrices: State.PriceData[];
  onCloseModal: () => void;
  onConfirmPurchase: () => void;
}

export const ModalPurchaseConfirmation: React.FC<Props> = ({
  isLoading,
  isVisible,
  product,
  selectedPrices,
  onCloseModal,
  onConfirmPurchase,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isAction
      cancelNode={
        <Button disabled={isLoading} testId="cancel-modal" variant={ButtonVariants.INVERTED} onClick={onCloseModal}>
          {t(Translations.BUTTON_BACK)}
        </Button>
      }
      confirmNode={
        <Button
          disabled={isLoading}
          loading={isLoading}
          testId="confirm-modal"
          variant={ButtonVariants.PRIMARY}
          onClick={onConfirmPurchase}
        >
          {t(Translations.BUTTON_OK)}
        </Button>
      }
      descriptionNode={
        <StyledDescriptionWrapper>
          <p>{product?.displayName || product?.metadata?.label || product?.name}</p>

          <Table unstackable basic="very">
            <Table.Body>
              {selectedPrices.map((price) => (
                <Table.Row key={price.description}>
                  <Table.Cell>{price?.label || price.description}</Table.Cell>
                  <Table.Cell textAlign="right">{price?.amountWithTax}</Table.Cell>
                </Table.Row>
              ))}
              <Table.Row>
                <Table.Cell>
                  <b>{t(Translations.LABEL_TOTAL)}</b>
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {' '}
                  {selectedPrices.reduce((prev, current) => prev + (current?.amountWithTax || 0), 0)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </StyledDescriptionWrapper>
      }
      icon={BasketIcon}
      open={isVisible}
      testId="purchase-confirmation"
      title={t(Translations.LABEL_PURCHASE_CONFIRMATION_DETAILS)}
      variant={ModalVariants.PRIMARY}
    />
  );
};

const StyledDescriptionWrapper = styled.div`
  p {
    color: ${(props) => props.theme.colors.neutralBlack};
    font-size: ${(props) => props.theme.fontSizes.m};
    font-weight: ${(props) => props.theme.fontWeights.black};
  }
`;
