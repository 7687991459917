import React from 'react';
import { HeaderProps } from 'semantic-ui-react';

import { BrandText, Title } from '@/atoms/index';

interface Props extends HeaderProps {
  title: string;
}

export const BrandTextWithTitle: React.FC<Props> = ({ title, ...props }) => (
  <>
    <BrandText />
    <Title {...props}>{title}</Title>
  </>
);
