import React from 'react';
import { connect } from 'react-redux';

import { PageAuthRegister } from '@/pages/PageAuthRegister';
import { AppDispatch, RootState } from '@/state/store';
import { register, setUserErrorMessage } from '@/state/user/actions';
import { errorMessageSelector, isLoadingSelector, userDataSelector } from '@/state/user/selectors';

const mapActionCreators = (dispatch: AppDispatch) => ({
  register(params: { email: string; name: string; password: string }): void {
    dispatch(register(params));
  },
  setUserErrorMessage(error: string): void {
    dispatch(setUserErrorMessage(error));
  },
});

const mapStateToProps = (state: RootState) => ({
  email: userDataSelector(state)?.email,
  errorMessage: errorMessageSelector(state),
  isLoading: isLoadingSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageAuthRegister);
