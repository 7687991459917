import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { isExternalLink } from '@/utils/index';

interface Props {
  testId: string;
  to: string;
  isExternal?: boolean;
  target?: string;
  onClick?: () => void;
}

export const Link: React.FC<Props> = ({ children, testId, to, isExternal, onClick, ...props }) => {
  const isEx = typeof isExternal === 'undefined' ? isExternalLink(to) : isExternal;

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return isEx ? (
    <StyledAnchor data-testid={`external-link-${testId}`} href={to} target="_blank" onClick={onClick} {...props}>
      {children}
    </StyledAnchor>
  ) : (
    <StyledLink {...props} data-testid={`link-${testId}`} to={to} onClick={handleOnClick}>
      {children}
    </StyledLink>
  );
};

const StyledAnchor = styled.a`
  color: ${(props) => props.theme.colors.primary};
  font-weight: ${(props) => props.theme.fontWeights.black};
`;

const StyledLink = styled(RouterLink)`
  color: ${(props) => props.theme.colors.primary};
  font-weight: ${(props) => props.theme.fontWeights.black};
`;
