import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { Customer } from '@/collections/index';
import { Translations } from '@/constants/index';
import firebase from '@/firebase/index';
import { Subscription as SubscriptionService } from '@/services/index';

import { formatProductName } from '../product/helpers';
import { RootState } from '../store';
import { userDataSelector } from '../user/selectors';
import { ContractActions } from './constants';
import { formatSubscriptionItems } from './helpers';

export const setContractList = createAction<State.ContractData[]>(ContractActions.SET_CONTRACT_LIST);

export const setContractErrorMessage = createAction<string>(ContractActions.SET_CONTRACT_ERROR_MESSAGE);

export const setContractSuccessMessage = createAction<string>(ContractActions.SET_CONTRACT_SUCCESS_MESSAGE);

export const editContract = createAsyncThunk(
  ContractActions.EDIT_CONTRACT,
  async (params: {
    contractId: string;
    toRecoverItems?: State.StripeSubscriptionItem[];
    toRemoveItems?: State.StripeSubscriptionItem[];
    toPurchaseItems?: State.PriceData[];
  }) => {
    try {
      const token = await firebase.auth().currentUser?.getIdToken();
      if (!token) {
        throw new Error('Failed to retrieve current user');
      }

      const toRecoverItems = params?.toRecoverItems?.map((item) => item.id) || [];
      const toRemoveItems = params?.toRemoveItems?.map((item) => item.id) || [];
      const toPurchaseItems = params?.toPurchaseItems?.map((item) => item.id) || [];
      let data = {} as State.ContractData;

      try {
        const updateSubscriptionRes = await SubscriptionService.update({
          id: params.contractId,
          toPurchaseItems,
          toRecoverItems,
          toRemoveItems,
          token,
        });
        if (updateSubscriptionRes.status !== 200) {
          throw new Error('Failed to update contract');
        }

        data = updateSubscriptionRes.data;
      } catch (error) {
        throw new Error('Failed to update contract');
      }

      if (!data) {
        throw new Error('Failed to update contract');
      }

      const subscriptionItems = formatSubscriptionItems(data?.items || []);
      const result = {
        ...data,
        items: subscriptionItems,
      };

      return Promise.resolve({ data: result, message: i18next.t(Translations.SUCCESS_CONTRACT_UPDATED) });
    } catch (error) {
      return Promise.reject(i18next.t(Translations.ERROR_CONTRACT_FAILED_TO_UPDATE));
    }
  }
);

export const redirectToCheckout = createAsyncThunk(
  ContractActions.REDIRECT_TO_CHECKOUT,
  async (
    params: {
      productId: string;
      prices: State.PriceData[];
    },
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const userData = userDataSelector(state);

      await new Customer().createCustomerSubscriptionCheckoutSessions({
        customerId: userData?.stripeId,
        items: params.prices.map((price) => ({
          price: price.id,
          quantity: 1,
        })),
        locale: i18next.language === 'jp' ? 'ja' : 'auto',
        uid: userData?.id || '',
      });

      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  }
);

export const getContracts = createAsyncThunk(ContractActions.GET_CONTRACT_LIST, async (userId: string, thunkAPI) => {
  try {
    // Get customer contracts
    const contracts = await new Customer().getCustomerSubscriptions(userId);

    const result = contracts.map((subscription) => {
      const subscriptionItems = formatSubscriptionItems(subscription?.items || []);

      return {
        ...subscription,
        items: subscriptionItems,
        product: {
          ...subscription.product,
          displayName: formatProductName(subscription?.product?.metadata?.label || ''),
        },
      };
    });

    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject();
  }
});
