import React from 'react';
import { Radio as SemanticUIRadio, RadioProps } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props extends RadioProps {
  testId: string;
}

export const Radio: React.FC<Props> = ({ testId, ...props }) => {
  return <StyledRadio data-testid={testId} {...props} />;
};

const StyledRadio = styled(SemanticUIRadio)<{ radio?: boolean }>`
  &.ui.checkbox label {
    color: ${(props) => props.theme.colors.neutralBlack};
  }

  &.ui.checkbox label:empty {
    padding-left: 0;
  }

  &.ui.checkbox input:checked ~ label:before {
    border: ${(props) => `${props.theme.pxToRem(1)} solid ${props.theme.colors.primary}`};
  }

  &.ui.radio.checkbox input:focus ~ label:after,
  &.ui.radio.checkbox input:checked ~ label:after {
    color: ${(props) => props.theme.colors.neutralWhite};
    background-color: ${(props) => props.theme.colors.primary};
    border: ${(props) => `${props.theme.pxToRem(1)} solid ${props.theme.colors.primary}`};
  }

  &.ui.checkbox input:checked ~ label {
    font-weight: ${(props) => props.theme.fontWeights.black};
  }
`;
