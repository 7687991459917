import React from 'react';
import styled from 'styled-components';

export const BrandText: React.FC = () => <StyledCompanyText>fnavistandard</StyledCompanyText>;

const StyledCompanyText = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.fontWeights.black};
  font-size: ${(props) => props.theme.pxToRem(18)};
  color: ${(props) => props.theme.colors.primary};
  padding: 0;
  margin: 0;
`;
