import { Translations } from '@/constants/index';

export const jp = {
  translation: {
    [Translations.BUTTON_ADD_CARD]: 'カード追加',
    [Translations.BUTTON_BACK]: '戻る',
    [Translations.BUTTON_CANCEL]: 'キャンセル',
    [Translations.BUTTON_CONTRACTS]: '設定・購入',
    [Translations.BUTTON_LOG_IN]: 'ログイン',
    [Translations.BUTTON_NEXT]: '次ページ',
    [Translations.BUTTON_REGISTER]: '登録',
    [Translations.BUTTON_RETURN]: '戻る',
    [Translations.BUTTON_OK]: 'はい',
    [Translations.BUTTON_OKAY]: 'OK',
    [Translations.BUTTON_PURCHASE]: '購入',
    [Translations.BUTTON_SAVE]: '保存',
    [Translations.BUTTON_UPDATE]: '更新',

    [Translations.DESCRIPTION_DELETE_CARD]: '本当にカードを削除していいですか？',
    [Translations.DESCRIPTION_LEGAL_AGREEMENTS]: 'ご利用いただく場合には、弊社の_と_に同意いただいたものとして取り扱わさせていただきます。',
    [Translations.DESCRIPTION_NO_CONTRACTS]: '今すぐ追加して、利用可能な契約を見るにはこちら',
    [Translations.DESCRIPTION_NO_REGISTERED_CARD]: '設定画面で「カード登録」をお願いします。',
    [Translations.DESCRIPTION_UNPURCHASED_PRODUCT]: 'この機能はまだ購入されていません。Top画面の設定から購入が必要です',

    [Translations.ERROR_ADDRESS_MAX_CHARACTERS]: 'アドレスは最大128文字までです。',
    [Translations.ERROR_CARD_INVALID]:
      '申し訳ございませんが、お客様のカードに問題が発生しました。カード発行会社にお問い合わせください。',
    [Translations.ERROR_CARD_IS_ALREADY_REGISTERED]: 'カードはすでに登録されています。他のカードをお使いください。',
    [Translations.ERROR_CONTRACT_FAILED_TO_UPDATE]: '契約の更新に失敗しました',
    [Translations.ERROR_EMAIL_IS_ALREADY_TAKEN]: 'メールアドレスはすでに使用されています',
    [Translations.ERROR_INVALID_CREDENTIALS]: '無効な認証情報です',
    [Translations.ERROR_INVALID_PHONE_NO]: '電話番号は正しく入力してください。',
    [Translations.ERROR_FIELD_IS_REQUIRED]: 'が必要です。',
    [Translations.ERROR_FULL_WIDTH_DIGIT]: '全角の数字は不可です',
    [Translations.ERROR_NAME_MAX_CHARACTERS]: 'フルネームは128文字以内でお願いします。',
    [Translations.ERROR_NO_ACCESS]: 'アクセス禁止です',
    [Translations.ERROR_NO_CARD]: 'クレジットカード登録をお願いします',
    [Translations.ERROR_ORG_MAX_CHARACTERS]: '組織は、最大128文字までです。',
    [Translations.ERROR_PASSWORD_MINIMUM_CHARACTERS]: 'パスワードは8文字以上としてください。',
    [Translations.ERROR_PHONE_NO_MAX_DIGITS]: '電話番号は11桁以内でお願いします。',
    [Translations.ERROR_PLEASE_RE_LOGIN]: '再ログインしてください',
    [Translations.ERROR_SOMETHING_WENT_WRONG]: '何かが間違っています',
    [Translations.ERROR_USER_DOES_NOT_EXIST]: 'ユーザーが存在しません',
    [Translations.ERROR_USER_IS_ALREADY_EXISTING]: 'ユーザーが既に存在します',

    [Translations.LABEL_ADDITIONAL]: 'オプション',
    [Translations.LABEL_ADDRESS]: '住所',
    [Translations.LABEL_AUTO_UPDATE]: '自動更新',
    [Translations.LABEL_BASIC]: '基本パック',
    [Translations.LABEL_BILLING]: '請求書作成',
    [Translations.LABEL_BUILDING]: '建物',
    [Translations.LABEL_CARD_BILLING_ADDRESS]: '請求先住所',
    [Translations.LABEL_CARD_BILLING_CITY]: '都市',
    [Translations.LABEL_CARD_BILLING_COUNTRY]: '国名',
    [Translations.LABEL_CARD_BILLING_LINE_ONE]: '住所1',
    [Translations.LABEL_CARD_BILLING_LINE_TWO]: '住所2',
    [Translations.LABEL_CARD_BILLING_STATE]: '状態',
    [Translations.LABEL_CARD_CVC]: 'カードCvc',
    [Translations.LABEL_CARD_DETAILS]: 'カード詳細',
    [Translations.LABEL_CARD_EXPIRATION]: 'カード有効期限',
    [Translations.LABEL_CARD_NOT_REGISTERED]: 'カード未登録',
    [Translations.LABEL_CARD_NUMBER]: 'カード番号',
    [Translations.LABEL_CREATE_CONTACT]: '海域追加',
    [Translations.LABEL_DATE]: '日付',
    [Translations.LABEL_DELETE_CARD]: 'カードの削除',
    [Translations.LABEL_EDIT_BILLING]: 'ビリング編集',
    [Translations.LABEL_EMAIL]: 'メールアドレス',
    [Translations.LABEL_EMAIL_ADDRESS]: 'メールアドレス',
    [Translations.LABEL_ITEM]: '項目',
    [Translations.LABEL_FULL_NAME]: '氏名',
    [Translations.LABEL_MUNICIPALITIES]: '自治体',
    [Translations.LABEL_NAME]: '氏名',
    [Translations.LABEL_NEW_ACCOUNT]: '新規登録・設定',
    [Translations.LABEL_NEXT_UPDATE]: '次回更新日',
    [Translations.LABEL_LOGOUT]: 'ログアウト',
    [Translations.LABEL_ORGANIZATION]: '組織',
    [Translations.LABEL_PHONE_NO]: '電話番号',
    [Translations.LABEL_PASSWORD]: 'パスワード',
    [Translations.LABEL_PRICE]: '価格',
    [Translations.LABEL_POST_CODE]: '郵便番号',
    [Translations.LABEL_PREFECTURES]: '都道府県名',
    [Translations.LABEL_PURCHASE_ITEMS]: '購入海域',
    [Translations.LABEL_PURCHASE_CONFIRMATION_DETAILS]: '購入内容の確認',
    [Translations.LABEL_REGION]: '海域',
    [Translations.LABEL_REGION_NAME]: '海域名',
    [Translations.LABEL_SEA_AREA_SELECTION]: '海域選択',
    [Translations.LABEL_SERVICE]: 'サービス',
    [Translations.LABEL_SERVICE_SELECTION]: 'サービス選択',
    [Translations.LABEL_SETTINGS]: '設定',
    [Translations.LABEL_TELEPHONE_NO]: '電話番号',
    [Translations.LABEL_TOP_PAGE]: 'Topページへ移動',
    [Translations.LABEL_TOTAL]: '金額',
    [Translations.LABEL_USAGE]: '使い方',
    [Translations.LABEL_UNPURCHASED_PRODUCT]: '未購入製品オプション',
    [Translations.LABEL_YEN_MONTH]: '円',

    [Translations.MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST]: '現在の雲除去',
    [Translations.MENU_LABEL_CURRENT_FRONT]: '現在の潮目',
    [Translations.MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP]: '現在の中層水温',
    [Translations.MENU_LABEL_CURRENT_SATELLITE_SST]: '現在のひまわり',
    [Translations.MENU_LABEL_CURRENT_TIDE]: '現在の潮流',
    [Translations.MENU_LABEL_TOMORROWS_FRONT]: '明日の潮目',
    [Translations.MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP]: '明日の中層水温',
    [Translations.MENU_LABEL_TOMORROWS_TIDE]: '明日の潮流',
    [Translations.MENU_LABEL_TOM_SEA_SURFACE_TEMP]: '明日の表面水温',

    [Translations.MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST_DESC]:
      'ひまわりのデータの雲で見えない部分をAI技術で補完した表面海水温',
    [Translations.MENU_LABEL_CURRENT_FRONT_DESC]: 'スーパーコンピューターで計算したデータから推定した現在の潮目',
    [Translations.MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP_DESC]:
      'スーパーコンピューターで計算した現在の中層水温（20m）',
    [Translations.MENU_LABEL_CURRENT_SATELLITE_SST_DESC]: '気象衛星ひまわりの表面海水温',
    [Translations.MENU_LABEL_CURRENT_TIDE_DESC]: 'スーパーコンピューターで計算した現在の潮流（0m及び20m）',
    [Translations.MENU_LABEL_TOMORROWS_FRONT_DESC]: 'スーパーコンピューターで計算したデータから推定した明日の潮目',
    [Translations.MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP_DESC]:
      'スーパーコンピューターで計算した明日の中層水温（20m）',
    [Translations.MENU_LABEL_TOMORROWS_TIDE_DESC]: 'スーパーコンピューターで計算した明日の潮流（0m及び20m）',
    [Translations.MENU_LABEL_TOM_SEA_SURFACE_TEMP_DESC]: 'スーパーコンピューターで計算した明日の表面海水温',

    [Translations.LINK_CONTACT]: 'サポート',
    [Translations.LINK_FORGOT_PASSWORD]: 'パスワードをお忘れの方',
    [Translations.LINK_PRIVACY_POLICY]: 'プライバシーポリシー',
    [Translations.LINK_TERMS_OF_SERVICE]: '利用規約',

    [Translations.PLACEHOLDER_EMAIL]: 'メールアドレスを入力してください',
    [Translations.PLACEHOLDER_NAME]: '名前を入力してください',
    [Translations.PLACEHOLDER_PASSWORD]: 'パスワードの入力',
    [Translations.PLACEHOLDER_SELECT_PRODUCT]: '商品選択',

    [Translations.SUCCESS_CARD_REGISTRATION]: 'カードが正常に登録された',
    [Translations.SUCCESS_CONTRACT_UPDATED]: '契約の更新に成功しました',
    [Translations.SUCCESS_PASSWORD_RESET_LINK]: 'パスワードリセットが送信されました',

    [Translations.PRODUCT_ITEM_BASIC]: 'ベーシック',
    [Translations.PRODUCT_ITEM_FRONT]: '潮目',
    [Translations.PRODUCT_ITEM_MIDDLE_LAYER_WATER_TEMPERATURE]: '中層水温',
    [Translations.PRODUCT_ITEM_TIDE]: '潮流',

    [Translations.PRODUCT_NAME_KYOTO]: '京都',
    [Translations.PRODUCT_NAME_SAGA_GENKAI]: '佐賀(玄海)',
    [Translations.PRODUCT_NAME_KAGAWA]: '香川',
    [Translations.PRODUCT_NAME_CHIBA_SATOBO]: '千葉(外房)',
    [Translations.PRODUCT_NAME_CHIBA_UCHIBO]: '千葉(内房)',
    [Translations.PRODUCT_NAME_CHIBA_KUJUKURI]: '千葉(九十九里)',
    [Translations.PRODUCT_NAME_ARIAKE]: '有明',
    [Translations.PRODUCT_NAME_YATSUSHIRO]: '八代',
    [Translations.PRODUCT_NAME_KAGOSHIMA_WEST]: '鹿児島西部',
    [Translations.PRODUCT_NAME_SAGAMIWAN]: '相模湾',
    [Translations.PRODUCT_NAME_OSHIKA]: '牡鹿',
    [Translations.PRODUCT_NAME_ENSYUNADA]: '遠州灘',
    [Translations.PRODUCT_NAME_SURUGAWAN]: '駿河湾',
    [Translations.PRODUCT_NAME_NAGASAKI]: '長崎',
    [Translations.PRODUCT_NAME_YAMAGUCHI]: '山口',
    [Translations.PRODUCT_NAME_KOCHI]: '高知',
    [Translations.PRODUCT_NAME_WAKAYAMA]: '和歌山',
    [Translations.PRODUCT_NAME_HACHIJO]: '八丈',
    [Translations.PRODUCT_NAME_TSUSHIMA]: '対馬',
    [Translations.PRODUCT_NAME_CHIBA_KATSUURA]: '千葉(勝浦)',
    [Translations.PRODUCT_NAME_IBARAKI]: '茨城',
    [Translations.PRODUCT_NAME_KOCHI_WEST]: '高知西部',
    [Translations.PRODUCT_NAME_IZU_EAST]: '東伊豆',
    [Translations.PRODUCT_NAME_IZU_SOUTH]: '南伊豆',
    [Translations.PRODUCT_NAME_MIYAKEJIMA]: '三宅島',
    [Translations.PRODUCT_NAME_TOKYOWAN]: '東京湾',
    [Translations.PRODUCT_NAME_OSAKA]: '大阪',
    [Translations.PRODUCT_NAME_AICHI]: '愛知(伊勢湾)',
    [Translations.PRODUCT_NAME_MIE]: '三重',
    [Translations.PRODUCT_NAME_TOKYOCHIBA]: '東京千葉',

    [Translations.TITLE_ADD_CONTRACT]: '提供海域',
    [Translations.TITLE_CARD]: 'クレジットカード',
    [Translations.TITLE_CHECK_EMAIL]: 'メールアドレス設定',
    [Translations.TITLE_CONTRACTS]: '契約情報',
    [Translations.TITLE_CREDIT_CARD_REGISTRATION]: 'カード登録・変更',
    [Translations.TITLE_EDIT_CONTRACT]: '契約情報更新',
    [Translations.TITLE_LOG_IN]: 'ログイン',
    [Translations.TITLE_PASSWORD_RESET]: 'パスワードリセット',
    [Translations.TITLE_PURCHASE_HISTORY]: '購入履歴',
    [Translations.TITLE_REGISTER]: 'ユーザー情報登録',
    [Translations.TITLE_SETTINGS]: '設定',
    [Translations.TITLE_USAGE]: '使い方',
  },
};
