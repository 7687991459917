import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button, ButtonVariants } from '@/atoms/index';
import { Translations } from '@/constants/index';

import { CheckboxProductPrices } from '../CheckboxProductPrices/CheckboxProductPrices';

interface Props {
  product: State.ProductData;
  selectedPrices: State.PriceData[];
  setSelectedPrices: React.Dispatch<React.SetStateAction<State.PriceData[]>>;
  onPurchase: () => void;
}

export const FormCreateContract: React.FC<Props> = ({ product, selectedPrices, setSelectedPrices, onPurchase }) => {
  const { t } = useTranslation();
  // extract options from product prices
  const additionalOptions = product?.prices?.filter((price) => price.description.toLowerCase() !== 'basic');
  const basicOptions = product?.prices?.filter((price) => price.description.toLowerCase() === 'basic');

  const handleToggleSelectPrices = (id: string) => {
    const selectedPrice = selectedPrices.find((price) => price.id === id);

    if (selectedPrice) {
      // remove price to selectedPrices
      const newSelectedPrices = selectedPrices.filter((price) => selectedPrice.id !== price.id);
      setSelectedPrices(newSelectedPrices);
    } else {
      // add option to selectedPrices
      const price = product?.prices?.find((option) => option.id === id);

      if (price) {
        setSelectedPrices([...selectedPrices, price]);
      }
    }
  };

  // checked basic plan options
  React.useEffect(() => {
    basicOptions?.forEach((option) => {
      if (option.description.toLowerCase() === 'basic') {
        setSelectedPrices([option]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <>
      <StyledImageWrapper>
        <Image centered size="large" src={product?.images?.[0]} />
      </StyledImageWrapper>
      <Form size="large">
        {Boolean(basicOptions?.length) && (
          <Form.Field>
            <StyledLabelWrapper>
              <span>{t(Translations.LABEL_BASIC)}</span>
            </StyledLabelWrapper>
            <CheckboxProductPrices isRadio prices={basicOptions} selectedPrices={selectedPrices} />
          </Form.Field>
        )}

        {Boolean(additionalOptions?.length) && (
          <Form.Field>
            <StyledLabelWrapper>
              <span>{t(Translations.LABEL_ADDITIONAL)}</span>
            </StyledLabelWrapper>
            {/* only additional plans can be toggled */}
            <CheckboxProductPrices
              prices={additionalOptions}
              selectedPrices={selectedPrices}
              onCheckedPrice={handleToggleSelectPrices}
            />
          </Form.Field>
        )}

        <Grid columns={2} verticalAlign="middle">
          <Grid.Column>
            <StyledSpan>
              {t(Translations.LABEL_TOTAL)}:{' '}
              {selectedPrices.reduce((prev, current) => prev + (current?.amountWithTax || 0), 0)}
            </StyledSpan>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button testId="purchase" type="button" variant={ButtonVariants.PRIMARY} onClick={onPurchase}>
              {t(Translations.BUTTON_PURCHASE)}
            </Button>
          </Grid.Column>
        </Grid>
      </Form>
    </>
  );
};

const StyledImageWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(20)};
`;

const StyledLabelWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(10)};
`;

const StyledSpan = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.black};
`;
