import i18next from 'i18next';

import { AccessType, Translations } from '@/constants/index';

export const isEmail = (email: string): boolean => {
  const regEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regEx.test(email);
};

export const isExternalLink = (url: string): boolean => {
  const regEx = /^http(s)?:\/\//;

  return regEx.test(url);
};

export const capitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const splitCaps = (word: string): string =>
  word
    .replace(/([a-z])([A-Z]+)/g, (m, s1, s2) => s1 + ' ' + s2)
    .replace(/([A-Z])([A-Z]+)([^a-zA-Z0-9]*)$/, (m, s1, s2, s3) => s1 + s2.toLowerCase() + s3)
    .replace(/([A-Z]+)([A-Z][a-z])/g, (m, s1, s2) => s1.toLowerCase() + ' ' + s2);

export const snakeCase = (word: string): string =>
  splitCaps(word)
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');

export const isValidPhoneNo = (phoneNo: string): boolean => {
  const regEx = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

  return regEx.test(phoneNo);
};

export const undoSnakeCase = (word: string): string =>
  word
    .split('_')
    .map((word) => capitalize(word))
    .join(' ');

export const isJapaneseCharacter = (string: string): boolean => {
  const regEx = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/;

  return regEx.test(string);
};

export const extractProductLabel = (label: string) => {
  switch (snakeCase(label)) {
    case AccessType.BASIC:
      return i18next.t(Translations.PRODUCT_ITEM_BASIC);
    case AccessType.FRONT:
      return i18next.t(Translations.PRODUCT_ITEM_FRONT);
    case AccessType.MIDDLE_LAYER_WATER_TEMPERATURE:
      return i18next.t(Translations.PRODUCT_ITEM_MIDDLE_LAYER_WATER_TEMPERATURE);
    case AccessType.TIDE:
      return i18next.t(Translations.PRODUCT_ITEM_TIDE);
  }
};

// 1)kyoto: 京都/kyoto
// 2)saga_genkai: 佐賀(玄海)/saga_genkai
// 3)kagawa: 香川/kagawa
// 4)chiba_sotobo: 千葉(外房)/chiba_sotobo
// 5)chiba_uchibo: 千葉(内房) chiba_uchibo
// 6)chiba_kujukuri 千葉(九十九里) chiba_kujukuri
// 7)ariake: 有明/ariake
// 8)yatsushiro: 八代/yatsushiro
// 9)kagoshima_west: 鹿児島西部/kagoshima_west
// 10)sagamiwan: 相模湾/sagamiwan bay
// 11)oshika: 牡鹿/oshika
// 12)ensyunada: 遠州灘/ensyunada
// 13)surugawan: 駿河湾/surugawan bay

export const extractProductName = (name: string) => {
  const words = name.split(' ');
  const productNameWords = [];

  for (const word in words) {
    if (words[word] === '-') {
      break;
    }

    productNameWords.push(words[word]);
  }

  const productName = productNameWords.join(' ');

  switch (productName) {
    case 'Kyoto':
      return i18next.t(Translations.PRODUCT_NAME_KYOTO);
    case 'Saga Genkai':
      return i18next.t(Translations.PRODUCT_NAME_SAGA_GENKAI);
    case 'Kagawa':
      return i18next.t(Translations.PRODUCT_NAME_KAGAWA);
    case 'Chiba Sotobo':
      return i18next.t(Translations.PRODUCT_NAME_CHIBA_SATOBO);
    case 'Chiba Uchibo':
      return i18next.t(Translations.PRODUCT_NAME_CHIBA_UCHIBO);
    case 'Chiba Kujukuri':
      return i18next.t(Translations.PRODUCT_NAME_CHIBA_KUJUKURI);
    case 'Ariake':
      return i18next.t(Translations.PRODUCT_NAME_ARIAKE);
    case 'Yatsushiro':
      return i18next.t(Translations.PRODUCT_NAME_YATSUSHIRO);
    case 'Kagoshima West':
      return i18next.t(Translations.PRODUCT_NAME_KAGOSHIMA_WEST);
    case 'Sagamiwan':
      return i18next.t(Translations.PRODUCT_NAME_SAGAMIWAN);
    case 'Oshika':
      return i18next.t(Translations.PRODUCT_NAME_OSHIKA);
    case 'Ensyunada':
      return i18next.t(Translations.PRODUCT_NAME_ENSYUNADA);
    case 'Surugawan':
      return i18next.t(Translations.PRODUCT_NAME_SURUGAWAN);
    case 'Nagasaki':
      return i18next.t(Translations.PRODUCT_NAME_NAGASAKI);
    case 'Yamaguchi':
      return i18next.t(Translations.PRODUCT_NAME_YAMAGUCHI);
    case 'Kochi':
      return i18next.t(Translations.PRODUCT_NAME_KOCHI);
    case 'Wakayama':
      return i18next.t(Translations.PRODUCT_NAME_WAKAYAMA);
    case 'Hachijo':
      return i18next.t(Translations.PRODUCT_NAME_HACHIJO);
    case 'Tsushima':
      return i18next.t(Translations.PRODUCT_NAME_TSUSHIMA);
    case 'Chiba Katsuura':
      return i18next.t(Translations.PRODUCT_NAME_CHIBA_KATSUURA);
    case 'Ibaraki':
      return i18next.t(Translations.PRODUCT_NAME_IBARAKI);
    case 'Kochi West':
      return i18next.t(Translations.PRODUCT_NAME_KOCHI_WEST);
    case 'Izu East':
      return i18next.t(Translations.PRODUCT_NAME_IZU_EAST);
    case 'Izu South':
      return i18next.t(Translations.PRODUCT_NAME_IZU_SOUTH);
    case 'Miyakejima':
      return i18next.t(Translations.PRODUCT_NAME_MIYAKEJIMA);
    case 'Tokyowan':
      return i18next.t(Translations.PRODUCT_NAME_TOKYOWAN);
    case 'Osaka':
      return i18next.t(Translations.PRODUCT_NAME_OSAKA);
    case 'Aichi':
      return i18next.t(Translations.PRODUCT_NAME_AICHI);
    case 'Mie':
      return i18next.t(Translations.PRODUCT_NAME_MIE);
    case 'Tokyochiba':
      return i18next.t(Translations.PRODUCT_NAME_TOKYOCHIBA);
  }
};
