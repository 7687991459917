import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button, ButtonVariants, Input, InputPassword, LegalAgreement, Link, Message } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { getPath, isEmail } from '@/utils/index';

interface Props {
  email?: string;
  errorMessage: string;
  isLoading: boolean;
  onLogin: (params: { email: string; password: string }) => void;
}

export const FormLogin: React.FC<Props> = ({ email: userDataEmail, errorMessage, isLoading, onLogin }) => {
  const { t } = useTranslation();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const isSubmitButtonDisabled = !Boolean(email) || !isEmail(email) || !Boolean(password) || isLoading;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onLogin({ email, password });
  };

  React.useEffect(() => {
    if (userDataEmail) {
      setEmail(userDataEmail);
    }
  }, [userDataEmail, setEmail]);

  return (
    <>
      <Form size="large" onSubmit={handleSubmit}>
        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_EMAIL)}</StyledLabel>
          <Input
            fluid
            error={Boolean(errorMessage)}
            placeholder={t(Translations.PLACEHOLDER_EMAIL)}
            testId="email"
            value={email}
            onChange={handleEmailChange}
          />
        </Form.Field>

        <Form.Field>
          <StyledLabel>{t(Translations.LABEL_PASSWORD)}</StyledLabel>
          <InputPassword
            fluid
            error={Boolean(errorMessage)}
            placeholder={t(Translations.PLACEHOLDER_PASSWORD)}
            testId="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </Form.Field>

        <StyledForgotPasswordWrapper>
          <Link testId="forgot-your-password" to={getPath(Routes.PASSWORD_RESET)}>
            {t(Translations.LINK_FORGOT_PASSWORD)}
          </Link>
        </StyledForgotPasswordWrapper>

        {Boolean(errorMessage) && <Message error content={errorMessage} />}

        <StyledLegalAgreementWrapper>
          <LegalAgreement />
        </StyledLegalAgreementWrapper>

        <StyledButtonWrapper>
          <Button
            fluid
            disabled={isSubmitButtonDisabled}
            loading={isLoading}
            testId="log-in"
            variant={ButtonVariants.PRIMARY}
          >
            {t(Translations.BUTTON_LOG_IN)}
          </Button>
        </StyledButtonWrapper>
      </Form>
    </>
  );
};

const StyledLabel = styled.div`
  color: ${(props) => props.theme.colors.neutralGreyForLabels};
  margin-left: ${(props) => props.theme.pxToRem(5)};
  padding-bottom: ${(props) => props.theme.pxToRem(5)};
`;

const StyledForgotPasswordWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSizes.s};
  margin-top: ${(props) => props.theme.pxToRem(20)};
  text-align: right;
`;

const StyledLegalAgreementWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(50)};
`;

const StyledButtonWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(30)};
  text-align: center;
`;
