import { AccessType } from '@/constants/index';
import { extractProductLabel, snakeCase } from '@/utils/index';

export const formatSubscriptionItems = (subscriptionItems: State.StripeSubscriptionItemData[]) => {
  return subscriptionItems?.map((subscriptionItem) => {
    const isActive =
      subscriptionItem?.plan?.active && !Boolean(subscriptionItem?.metadata?.willExpireAtEndPeriod === 'true');
    const isBasicPlan = snakeCase(subscriptionItem?.price?.nickname || '') === AccessType.BASIC;

    // compute amount with tax rates
    let totalAmount = subscriptionItem?.price?.unit_amount || 0;
    if (totalAmount) {
      subscriptionItem?.tax_rates?.forEach((taxRate) => {
        if (!taxRate.inclusive) {
          totalAmount += totalAmount / taxRate.percentage;
        }
      });
    }

    return {
      ...subscriptionItem,
      isActive,
      isBasicPlan,
      price: {
        ...subscriptionItem.price,
        amountWithTax: totalAmount,
        label: subscriptionItem?.price?.nickname ? extractProductLabel(subscriptionItem.price.nickname) : '',
      },
    };
  });
};
