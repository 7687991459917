import { createSlice } from '@reduxjs/toolkit';

import { getProduct, getProductDataDisplay, getProducts, setDefaultSelectedRegion, setProductData } from './actions';

const initialState: State.Product = {
  errorMessage: '',
  imageFile: '',
  isLoading: false,
  list: [],
  successMessage: '',
};

const productSlice = createSlice({
  extraReducers: {
    // Get Product Data
    [`${getProduct.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
    }),
    [`${getProduct.fulfilled}`]: (state, action: { payload: State.ProductData }) => ({
      ...state,
      ...(action?.payload && { data: action.payload }),
      isLoading: false,
    }),
    [`${getProduct.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Get Product Data Display
    [`${getProductDataDisplay.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      imageFile: '',
      isLoading: true,
    }),
    [`${getProductDataDisplay.fulfilled}`]: (state, action: { payload: string }) => ({
      ...state,
      imageFile: action.payload,
      isLoading: false,
    }),
    [`${getProductDataDisplay.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      imageFile: '',
      isLoading: false,
    }),

    // Get Product List
    [`${getProducts.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
    }),
    [`${getProducts.fulfilled}`]: (state, action: { payload: State.ProductData[] }) => ({
      ...state,
      isLoading: false,
      list: action.payload,
    }),
    [`${getProducts.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Set Default Selected Region
    [`${setDefaultSelectedRegion.type}`]: (state, action: { payload: string }) => ({
      ...state,
      defaultSelectedRegion: action.payload,
    }),

    // Set Product Data
    [`${setProductData.type}`]: (state, action: { payload: State.ProductData | undefined }) => ({
      ...state,
      data: action.payload,
    }),
  },
  initialState,
  name: 'product',
  reducers: {},
});

export { productSlice };
