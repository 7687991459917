import React from 'react';
import { useParams } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

import { Message } from '@/atoms/index';
import ContainerServiceSelectionSidebar from '@/containers/ContainerServiceSelectionSidebar';
import { FooterAuth, HeaderWithNav } from '@/organisms/index';
import { PageWithSidebar, SingleColumn } from '@/templates/index';
import { undoSnakeCase } from '@/utils/index';

interface Props {
  errorMessage: string;
  image: string;
  isInit: boolean;
  getProductDataDisplay: () => void;
  openSidebar: () => void;
}

export const PageUserViewProduct: React.FC<Props> = ({
  errorMessage,
  image,
  isInit,
  getProductDataDisplay,
  openSidebar,
}) => {
  const params = useParams<{ productName: string; serviceName: string }>();

  // when productName and serviceName change, this will refetch the image
  React.useEffect(() => {
    // fetch image when initialized is finished
    if (isInit) {
      getProductDataDisplay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.productName, params.serviceName]);

  // save service selection if it's working
  React.useEffect(() => {
    if (!errorMessage && image) {
      localStorage.setItem('previousServiceSelection', JSON.stringify(params));
    }
  }, [errorMessage, image, params]);

  return (
    <PageWithSidebar>
      <ContainerServiceSelectionSidebar />
      <SingleColumn>
        <>
          <HeaderWithNav title={undoSnakeCase(params.productName as string)} onClickBurger={openSidebar} />
        </>
        <>
          {!errorMessage && <Image size="massive" src={image} />}
          {Boolean(errorMessage) && <Message content="" error={Boolean(errorMessage)} title={errorMessage} />}
        </>
        <>
          <FooterAuth />
        </>
        /.
      </SingleColumn>
    </PageWithSidebar>
  );
};
