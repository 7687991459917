import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { PrivateRoute, PublicRoute } from '@/atoms/index';
import { Routes as FNaviRoutes, SUPPORTED_LANGUAGES } from '@/constants/index';
import ContainerAuthCheckEmail from '@/containers/ContainerAuthCheckEmail';
import ContainerAuthLogin from '@/containers/ContainerAuthLogin';
import ContainerAuthPasswordReset from '@/containers/ContainerAuthPasswordReset';
import ContainerAuthRegister from '@/containers/ContainerAuthRegister';
import ContainerHome from '@/containers/ContainerHome';
import ContainerUserAddContract from '@/containers/ContainerUserAddContract';
import ContainerUserCards from '@/containers/ContainerUserCards';
import ContainerUserContracts from '@/containers/ContainerUserContracts';
import ContainerUserEditContract from '@/containers/ContainerUserEditContract';
import ContainerUserPurchase from '@/containers/ContainerUserPurchases';
import ContainerUserSettings from '@/containers/ContainerUserSettings';
import ContainerUserUsages from '@/containers/ContainerUserUsages';
import ContainerUserViewProduct from '@/containers/ContainerUserViewProduct';
import { PageUserAddCard } from '@/pages/PageUserAddCard';
import { history } from '@/state/store';
import { getPath } from '@/utils/index';

interface Props {
  isAuth: boolean;
  isLoading: boolean;
  isUserSetupCompleted: boolean;
  appInit: () => void;
}

export const App: React.FC<Props> = ({ isAuth, isLoading, isUserSetupCompleted, appInit }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const language = queryParams.get('lang');

  const renderRoutes = (): React.ReactNode => (
    <Router history={history}>
      <Routes>
        <Route element={<ContainerHome />} path={getPath(FNaviRoutes.HOME)} />

        {/* Auth Routes */}
        <Route
          element={
            <PublicRoute isAuth={isAuth} isUserSetupCompleted={isUserSetupCompleted}>
              <ContainerAuthCheckEmail />
            </PublicRoute>
          }
          path={getPath(FNaviRoutes.CHECK_EMAIL)}
        />
        <Route
          element={
            <PublicRoute isAuth={isAuth} isUserSetupCompleted={isUserSetupCompleted}>
              <ContainerAuthLogin />
            </PublicRoute>
          }
          path={getPath(FNaviRoutes.LOGIN)}
        />
        <Route
          element={
            <PublicRoute isAuth={isAuth} isUserSetupCompleted={isUserSetupCompleted}>
              <ContainerAuthRegister />
            </PublicRoute>
          }
          path={getPath(FNaviRoutes.REGISTER)}
        />
        <Route
          element={
            <PublicRoute isAuth={isAuth} isUserSetupCompleted={isUserSetupCompleted}>
              <ContainerAuthPasswordReset />
            </PublicRoute>
          }
          path={getPath(FNaviRoutes.PASSWORD_RESET)}
        />

        {/* User Cards Routes */}
        <Route
          element={
            <PrivateRoute isAuth={isAuth}>
              <PageUserAddCard />
            </PrivateRoute>
          }
          path={getPath(FNaviRoutes.USER_ADD_CARD)}
        />
        <Route
          element={
            <PrivateRoute isAuth={isAuth}>
              <ContainerUserCards />
            </PrivateRoute>
          }
          path={getPath(FNaviRoutes.USER_CARDS)}
        />

        {/* User Contracts Routes */}
        <Route
          element={
            <PrivateRoute isAuth={isAuth}>
              <ContainerUserAddContract />
            </PrivateRoute>
          }
          path={getPath(FNaviRoutes.USER_ADD_CONTRACT)}
        />
        <Route
          element={
            <PrivateRoute isAuth={isAuth}>
              <ContainerUserContracts />
            </PrivateRoute>
          }
          path={getPath(FNaviRoutes.USER_CONTRACTS)}
        />
        <Route
          element={
            <PrivateRoute isAuth={isAuth}>
              <ContainerUserEditContract />
            </PrivateRoute>
          }
          path={getPath(FNaviRoutes.USER_EDIT_CONTRACT)}
        />

        {/* User Purchases */}
        <Route
          element={
            <PrivateRoute isAuth={isAuth}>
              <ContainerUserPurchase />
            </PrivateRoute>
          }
          path={getPath(FNaviRoutes.USER_PURCHASES)}
        />

        {/* User Settings */}
        <Route
          element={
            <PrivateRoute isAuth={isAuth}>
              <ContainerUserSettings />
            </PrivateRoute>
          }
          path={getPath(FNaviRoutes.USER_SETTINGS)}
        />

        {/* User Usages */}
        <Route
          element={
            <PrivateRoute isAuth={isAuth}>
              <ContainerUserUsages />
            </PrivateRoute>
          }
          path={getPath(FNaviRoutes.USER_USAGES)}
        />

        {/* User View Product */}
        <Route element={<ContainerUserViewProduct />} path={getPath(FNaviRoutes.USER_VIEW_PRODUCT_DATA)} />
      </Routes>
    </Router>
  );

  React.useEffect(() => appInit(), [appInit]);

  React.useEffect(() => {
    if (language && SUPPORTED_LANGUAGES.includes(language)) {
      localStorage.setItem('i18nextLng', language);
    }
  }, [language]);

  if (isLoading) {
    return <Loader active content="Loading" />;
  }

  return (
    <StyledWrapper>
      <Helmet>
        <title>Fnavi Standard</title>
      </Helmet>

      {renderRoutes()}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
`;
