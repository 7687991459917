export * from './emptyItems';
export * from './routes';
export * from './string';

export const deepFind = (f: any, obj = {}): any => {
  if (Object(obj) === obj) {
    if (f(obj) === true) return obj;

    for (const [k, v] of Object.entries(obj) as any) {
      const res = deepFind(f, v);

      if (res !== undefined) return res;
    }
  }

  return undefined;
};
