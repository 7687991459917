import i18next from 'i18next';

import { AccessType, DataType } from '@/constants/index';
import { Translations } from '@/constants/index';

export enum MenuActions {
  SET_SELECTED_REGION = 'menu/SET_SELECTED_REGION',
}

export const ServiceMenu: State.MenuData[] = [
  {
    accessType: AccessType.BASIC,
    dataType: DataType.HIMAWARI,
    date: 'latest',
    label: i18next.t(Translations.MENU_LABEL_CURRENT_SATELLITE_SST),
    name: 'current-satellite-sst',
  },
  {
    accessType: AccessType.BASIC,
    dataType: DataType.GAN,
    date: 'latest',
    label: i18next.t(Translations.MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST),
    name: 'current-cloud-removal-sst',
  },
  {
    accessType: AccessType.BASIC,
    dataType: DataType.TSST,
    date: 'tomorrow',
    depth: 0,
    label: i18next.t(Translations.MENU_LABEL_TOM_SEA_SURFACE_TEMP),
    name: 'tomorrows-sea-surface-water-temperature',
  },
  {
    accessType: AccessType.TIDE,
    children: [
      {
        accessType: AccessType.TIDE,
        dataType: DataType.TCUR,
        date: 'latest',
        depth: 0,
        label: `${i18next.t(Translations.MENU_LABEL_CURRENT_TIDE)} (0m)`,
        name: 'current-tide-0m',
      },
      {
        accessType: AccessType.TIDE,
        dataType: DataType.TCUR,
        date: 'latest',
        depth: 20,
        label: `${i18next.t(Translations.MENU_LABEL_CURRENT_TIDE)} (20m)`,
        name: 'current-tide-20m',
      },
    ],
    label: i18next.t(Translations.MENU_LABEL_CURRENT_TIDE),
    name: 'current-tide',
  },
  {
    accessType: AccessType.TIDE,
    children: [
      {
        accessType: AccessType.TIDE,
        dataType: DataType.TCUR,
        date: 'tomorrow',
        depth: 0,
        label: `${i18next.t(Translations.MENU_LABEL_TOMORROWS_TIDE)} (0m)`,
        name: 'tomorrows-tide-0m',
      },
      {
        accessType: AccessType.TIDE,
        dataType: DataType.TCUR,
        date: 'tomorrow',
        depth: 20,
        label: `${i18next.t(Translations.MENU_LABEL_TOMORROWS_TIDE)} (20m)`,
        name: 'tomorrows-tide-20m',
      },
    ],
    label: i18next.t(Translations.MENU_LABEL_TOMORROWS_TIDE),
    name: 'tomorrows-tide',
  },
  {
    accessType: AccessType.MIDDLE_LAYER_WATER_TEMPERATURE,
    dataType: DataType.TSST,
    date: 'latest',
    depth: 20,
    label: i18next.t(Translations.MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP),
    name: 'current-mid-level-water-temperature',
  },
  {
    accessType: AccessType.MIDDLE_LAYER_WATER_TEMPERATURE,
    dataType: DataType.TSST,
    date: 'tomorrow',
    depth: 20,
    label: i18next.t(Translations.MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP),
    name: 'tomorrows-mid-level-water-temperature',
  },
  {
    accessType: AccessType.FRONT,
    dataType: DataType.TSEA,
    date: 'latest',
    depth: 0,
    label: i18next.t(Translations.MENU_LABEL_CURRENT_FRONT),
    name: 'current-front',
  },
  {
    accessType: AccessType.FRONT,
    dataType: DataType.TSEA,
    date: 'tomorrow',
    depth: 0,
    label: i18next.t(Translations.MENU_LABEL_TOMORROWS_FRONT),
    name: 'tomorrows-front',
  },
];
