import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import { Link, Title } from '@/atoms/index';
import { BackIcon } from '@/resources/icons';
interface Props {
  title: string;
  path: string;
}

export const HeaderWithBack: React.FC<Props> = ({ title, path }) => (
  <>
    <StyledGrid columns={1}>
      <StyledGridColumn textAlign="left">
        <Link testId="back" to={path}>
          <StyledImage alt="back" src={BackIcon} />
        </Link>

        <Title as="h2">{title}</Title>
      </StyledGridColumn>
    </StyledGrid>
  </>
);

const StyledImage = styled.img`
  cursor: pointer;
  vertical-align: middle;
`;

const StyledGrid = styled(Grid)`
  && {
    margin: 0;
  }
`;

const StyledGridColumn = styled(Grid.Column)`
  &&&& {
    padding-left: 0;
    padding-right: 0;
  }

  a,
  h2 {
    display: inline-block;
    vertical-align: middle;
  }

  a {
    margin-right: ${(props) => props.theme.pxToRem(15)};
  }
`;
