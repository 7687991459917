import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { isExternalLink } from '@/utils/index';

import { Button, Props as ButtonProps } from '../Button/Button';

interface Props extends ButtonProps {
  testId: string;
  to: string;
  isExternal?: boolean;
  target?: string;
}

export const ButtonLink: React.FC<Props> = ({ children, testId, to, isExternal, ...props }) => {
  const isEx = typeof isExternal === 'undefined' ? isExternalLink(to) : isExternal;

  return isEx ? (
    <Button as={StyledAnchor} href={to} testId={testId} {...props}>
      {children}
    </Button>
  ) : (
    <Button as={StyledLink} testId={testId} to={to} {...props}>
      {children}
    </Button>
  );
};

const StyledAnchor = styled.a`
  display: inline-block;
  min-height: 1em;
  vertical-align: baseline;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const StyledLink = styled(Link)`
  &&& {
    display: inline-block;
    min-height: 1em;
    vertical-align: baseline;
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em 0.78571429em;
    text-transform: none;
    text-shadow: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
`;
