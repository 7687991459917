import { fnavi as theme } from './fnavi';

export const global: string = `
  html {
    box-sizing: border-box;
    font-size:${theme.htmlFontSize}px !important;
  }

  body {
    background-color: ${theme.colors.neutralBrown};
    width: 100%;
    height: 100%;
  }

  #root {
    height: 100%;
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes.m};
    margin: 0;
    line-height: 1.33;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .ui, h1, h2, h3, h4, h5, h6 {
      font-family: ${theme.fontFamily};
    }
  }

  *, *::before, *::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;
