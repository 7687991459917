import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Translations } from '@/constants/index';
import { BrandTextWithTitle, FormPasswordReset } from '@/molecules/index';
import { FooterGuest } from '@/organisms/index';
import { SingleColumn } from '@/templates/index';

interface Props {
  errorMessage: string;
  isLoading: boolean;
  successMessage: string;
  passwordReset: (params: { email: string }) => void;
  setUserErrorMessage: (errorMessage: string) => void;
  setUserSuccessMessage: (message: string) => void;
}

export const PageAuthPasswordReset: React.FC<Props> = ({
  errorMessage,
  isLoading,
  passwordReset,
  setUserErrorMessage,
  setUserSuccessMessage,
  successMessage,
}) => {
  const { t } = useTranslation();
  // Clear error and success in form
  React.useEffect(() => {
    setUserErrorMessage('');
    setUserSuccessMessage('');
  }, [setUserErrorMessage, setUserSuccessMessage]);

  return (
    <SingleColumn>
      <></>
      <>
        <StyledHeaderWrapper>
          <BrandTextWithTitle as="h1" title={t(Translations.TITLE_PASSWORD_RESET)} />
        </StyledHeaderWrapper>
        <FormPasswordReset
          errorMessage={errorMessage}
          isLoading={isLoading}
          successMessage={successMessage}
          onPasswordReset={passwordReset}
        />
      </>
      <>
        <FooterGuest />
      </>
    </SingleColumn>
  );
};

const StyledHeaderWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(50)};
  margin-bottom: ${(props) => props.theme.pxToRem(70)};
`;
