import React from 'react';
import { connect } from 'react-redux';

import { PageUserSettings } from '@/pages/PageUserSettings';
import { setCardDateRetrieved } from '@/state/card/actions';
import { cardListSelector, dateRetrievedSelector } from '@/state/card/selectors';
import { AppDispatch, RootState } from '@/state/store';
import { setUserErrorMessage, setUserSuccessMessage, updateUserData } from '@/state/user/actions';
import {
  errorMessageSelector,
  isLoadingSelector,
  isUserSetupCompletedSelector,
  successMessageSelector,
  userDataSelector,
} from '@/state/user/selectors';

const mapActionCreators = (dispatch: AppDispatch) => ({
  setCardDateRetrieved(date: string): void {
    dispatch(setCardDateRetrieved(date));
  },
  setUserErrorMessage(error: string): void {
    dispatch(setUserErrorMessage(error));
  },
  setUserSuccessMessage(success: string): void {
    dispatch(setUserSuccessMessage(success));
  },
  updateUserData(params: Omit<State.UserData, 'id' | 'email'>): void {
    dispatch(updateUserData(params));
  },
});

const mapStateToProps = (state: RootState) => ({
  cardList: cardListSelector(state),
  dateRetrieved: dateRetrievedSelector(state),
  errorMessage: errorMessageSelector(state),
  isLoading: isLoadingSelector(state),
  isUserSetupCompleted: isUserSetupCompletedSelector(state),
  successMessage: successMessageSelector(state),
  userData: userDataSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageUserSettings);
