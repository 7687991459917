import axios, { AxiosResponse } from 'axios';

const host: string = process.env.REACT_APP_FIREBASE_FUNCTION_HOST!;
const url = `${host}/invoices`;

export const list = async (token: string) =>
  (await axios({
    headers: { Authorization: `Bearer ${token}` },
    method: 'GET',
    url,
  })) as unknown as AxiosResponse<State.InvoiceData[]>;
