import { createSlice } from '@reduxjs/toolkit';

import { setSidebarVisibility, setUnPurchasedModalVisibility } from './actions';

const initialState: State.Layout = {
  isSidebarVisible: false,
  isUnPurchasedModalVisible: false,
};

const layoutSlice = createSlice({
  extraReducers: {
    // Set Sidebar Visibility
    [`${setSidebarVisibility.type}`]: (state, action: { payload: boolean }) => ({
      ...state,
      isSidebarVisible: action.payload,
    }),

    // Set UnPurchased Modal Visibility
    [`${setUnPurchasedModalVisibility.type}`]: (state, action: { payload: boolean }) => ({
      ...state,
      isUnPurchasedModalVisible: action.payload,
    }),
  },
  initialState,
  name: 'layout',
  reducers: {},
});

export { layoutSlice };
