import { createSlice } from '@reduxjs/toolkit';

import {
  createCard,
  deleteCard,
  getCards,
  redirectToCheckout,
  setCardDateRetrieved,
  setCardErrorMessage,
  setCardList,
  setCardSuccessMessage,
} from './actions';

const initialState: State.Card = {
  errorMessage: '',
  isLoading: false,
  list: [],
  successMessage: '',
};

const cardSlice = createSlice({
  extraReducers: {
    // Create Card
    [`${createCard.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
      successMessage: '',
    }),
    [`${createCard.fulfilled}`]: (state, action: { payload: { message: string } }) => ({
      ...state,
      isLoading: false,
      successMessage: action.payload.message,
    }),
    [`${createCard.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Delete Card
    [`${deleteCard.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
      successMessage: '',
    }),
    [`${deleteCard.fulfilled}`]: (state, action: { payload: { message: string } }) => ({
      ...state,
      isLoading: false,
      successMessage: action.payload.message,
    }),
    [`${deleteCard.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Get Card List
    [`${getCards.pending}`]: (state) => ({
      ...state,
      errorMessage: '',
      isLoading: true,
    }),
    [`${getCards.fulfilled}`]: (state, action: { payload: State.CardData[] }) => ({
      ...state,
      isLoading: false,
      list: action.payload,
    }),
    [`${getCards.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      errorMessage: action.error.message,
      isLoading: false,
    }),

    // Redirect to Checkout
    [`${redirectToCheckout.pending}`]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [`${redirectToCheckout.fulfilled}`]: (state, action: { payload: { message: string } }) => ({
      ...state,
      isLoading: false,
    }),
    [`${redirectToCheckout.rejected}`]: (state, action: { error: Error }) => ({
      ...state,
      isLoading: false,
    }),

    // Set Card Date Retrieved
    [`${setCardDateRetrieved.type}`]: (state, action: { payload: string | undefined }) => ({
      ...state,
      dateRetrieved: action.payload,
    }),

    // Set Card List
    [`${setCardList.type}`]: (state, action: { payload: State.CardData[] }) => ({
      ...state,
      list: action.payload,
    }),

    // Set Card Error Message
    [`${setCardErrorMessage.type}`]: (state, action: { payload: string }) => ({
      ...state,
      errorMessage: action.payload,
    }),

    // Set Card Success Message
    [`${setCardSuccessMessage.type}`]: (state, action: { payload: string }) => ({
      ...state,
      successMessage: action.payload,
    }),
  },
  initialState,
  name: 'card',
  reducers: {},
});

export { cardSlice };
