import 'semantic-ui-css/semantic.min.css';
import '@/i18n/I18nProvider';
import '@/firebase/index';
import '@fontsource/roboto';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import AppContainer from '@/containers/AppContainer';
import { persistor, store } from '@/state/store';
import { ThemeProvider } from '@/theme/ThemeProvider';

import reportWebVitals from './reportWebVitals';

export const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Elements stripe={stripe}>
        <ThemeProvider>
          <AppContainer />
        </ThemeProvider>
      </Elements>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
