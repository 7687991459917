import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Routes, Translations } from '@/constants/index';
import { HeaderWithBack } from '@/organisms/index';
import { FooterAuth } from '@/organisms/index';
import { SingleColumn } from '@/templates/index';
import { getPath } from '@/utils/index';

interface Props {}

export const PageUserAddCard: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <SingleColumn>
      <>
        <HeaderWithBack path={getPath(Routes.USER_CARDS)} title="Add Card" />
      </>
      <></>
      <>
        <FooterAuth />
      </>
      /.
    </SingleColumn>
  );
};
