import * as dateFns from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxProps, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Button, ButtonVariants, Card, Checkbox, Message } from '@/atoms/index';
import { AccessType, Translations } from '@/constants/index';
import { ModalPurchaseConfirmation } from '@/molecules/index';
import { snakeCase } from '@/utils/index';

interface Props {
  contract: State.ContractData;
  isLoading: boolean;
  errorMessage: string;
  successMessage: string;
  unPurchasedItems: State.PriceData[];
  onClearMessage: () => void;
  onSubmit: (params: {
    toRecoverItems?: State.StripeSubscriptionItem[];
    toRemoveItems?: State.StripeSubscriptionItem[];
    toPurchaseItems?: State.PriceData[];
  }) => void;
}

export const EditContractItem: React.FC<Props> = ({
  contract,
  isLoading,
  errorMessage,
  successMessage,
  unPurchasedItems,
  onClearMessage,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [isBasicPlanInactive, setIsBasicPlanInactive] = React.useState(false);
  const [isPurchaseModalVisible, setIsPurchaseModalVisible] = React.useState(false);
  const [toRecoverItems, setToRecoverItems] = React.useState<State.StripeSubscriptionItem[]>([]);
  const [toRemoveItems, setToRemoveItems] = React.useState<State.StripeSubscriptionItem[]>([]);
  const [toPurchaseItems, setToPurchaseItems] = React.useState<State.PriceData[]>([]);

  const isUpdateButtonDisabled =
    (!Boolean(toRemoveItems.length) && !Boolean(toRecoverItems.length) && !Boolean(toPurchaseItems.length)) ||
    isLoading;

  const handleToggleActiveItems = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const id = data.value;
    const contractItem = contract.items?.find((contractItem) => contractItem.id === id);

    if (contractItem) {
      if (contractItem?.isBasicPlan) {
        // 1. gather all active items
        const activeItems = contract.items?.filter((contractItem) => contractItem.isActive);

        // checkbox was checked --> remove all items in toRemoveItems
        if (data.checked) {
          const activeItemsIds = activeItems?.map((activeItem) => activeItem.id);

          setToRemoveItems(toRemoveItems.filter((toRemoveItem) => !activeItemsIds?.includes(toRemoveItem.id)));
        } else {
          // checkbox was unchecked -> add all active items to be remove / to be inactive
          if (activeItems?.length) {
            // 3. set new toRemoveItems
            setToRemoveItems(activeItems);
          }
        }

        // update basic plan
        setIsBasicPlanInactive(!isBasicPlanInactive);
      } else {
        const item = toRemoveItems.find((toRemoveItem) => toRemoveItem.id === id);
        if (item) {
          // checkbox was checked -> remove item in toRemoveItems
          setToRemoveItems(toRemoveItems.filter((toRemoveItem) => toRemoveItem.id !== item.id));
        } else {
          // checkbox was unchecked -> add item to be remove / to be inactive
          setToRemoveItems([...toRemoveItems, contractItem]);
        }
      }
    }
  };

  const handleToggleInactiveItems = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const id = data.value;
    const contractItem = contract.items?.find((contractItem) => contractItem.id === id);

    if (contractItem) {
      if (contractItem?.isBasicPlan) {
        // checkbox was checked --> add basic plan to recover
        if (data.checked) {
          setToRecoverItems([contractItem]);

          // update basic plan
          setIsBasicPlanInactive(false);
        } else {
          // checkbox was unchecked -> remove all items that will be recover / active
          setToRecoverItems([]);

          // update basic plan
          setIsBasicPlanInactive(true);
        }
      } else {
        const item = toRecoverItems.find((toRecoverItem) => toRecoverItem.id === id);
        if (item) {
          // checkbox was checked -> remove item in toRecoverItems
          setToRecoverItems(toRecoverItems.filter((toRecoverItem) => toRecoverItem.id !== item.id));
        } else {
          // checkbox was unchecked -> add item to be recover / active
          setToRecoverItems([...toRecoverItems, contractItem]);
        }
      }
    }
  };

  const handleToggleUnPurchasedItems = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const id = data.value;
    const selectedPrice = toPurchaseItems.find((price) => price.id === id);

    if (selectedPrice) {
      // remove price to selectedPrices
      const newSelectedPrices = toPurchaseItems.filter((price) => selectedPrice.id !== price.id);
      setToPurchaseItems(newSelectedPrices);
    } else {
      // add option to selectedPrices
      const price = unPurchasedItems?.find((option) => option.id === id);

      if (price) {
        setToPurchaseItems([...toPurchaseItems, price]);
      }
    }
  };

  const handleToggleModalVisibility = () => setIsPurchaseModalVisible(!isPurchaseModalVisible);

  const handleClickUpdate = () => {
    if (toPurchaseItems.length) {
      // clear success and error message
      onClearMessage();

      handleToggleModalVisibility();
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    onSubmit({
      ...(toRecoverItems.length && { toRecoverItems }),
      ...(toRemoveItems.length && { toRemoveItems }),
      ...(toPurchaseItems.length && { toPurchaseItems }),
    });
  };

  // Close modal when contract update succeed
  React.useEffect(() => {
    if (successMessage || errorMessage) {
      // clear items
      setToRecoverItems([]);
      setToRemoveItems([]);
      setToPurchaseItems([]);

      console.log('clear!');

      if (isPurchaseModalVisible) {
        setIsPurchaseModalVisible(false);
        setToPurchaseItems([]);
      }
    }
  }, [isPurchaseModalVisible, successMessage, errorMessage]);

  // Check if basic plan is inactive
  React.useEffect(() => {
    const basicPlan = contract.items?.find(
      (contractItem) => snakeCase(contractItem?.price?.nickname || '') === AccessType.BASIC
    );
    if (!basicPlan?.isActive) {
      setIsBasicPlanInactive(true);
    }
  }, [contract]);

  return (
    <>
      <ModalPurchaseConfirmation
        isLoading={isLoading}
        isVisible={isPurchaseModalVisible}
        product={contract.product}
        selectedPrices={toPurchaseItems}
        onCloseModal={handleToggleModalVisibility}
        onConfirmPurchase={handleSubmit}
      />

      <StyledWrapper>
        <StyledCard
          fluid
          description={contract?.product?.displayName || contract?.product?.metadata?.label || contract?.product?.name}
          meta={t(Translations.LABEL_REGION)}
          rounded={1}
          testId="region"
        />
        <StyledTable unstackable>
          <StyledTableHeader>
            <Table.Row>
              <Table.HeaderCell width={2}>{t(Translations.LABEL_ITEM).toLocaleUpperCase()}</Table.HeaderCell>
              <Table.HeaderCell width={1}>{t(Translations.LABEL_PRICE).toLocaleUpperCase()}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={1}>
                {t(Translations.LABEL_AUTO_UPDATE).toLocaleUpperCase()}
              </Table.HeaderCell>
            </Table.Row>
          </StyledTableHeader>
          <Table.Body>
            {contract?.items?.map((contractItem) => {
              const itemWillBeRecovered = toRecoverItems.find((toRecoverItem) => toRecoverItem.id === contractItem.id);
              const itemWillBeRemoved = toRemoveItems.find((toRemoveItem) => toRemoveItem.id === contractItem.id);

              // auto-update will be checked if
              // 1 - if item is active then
              //     1.1 - check if basic plan is not inactive and item will not be removed
              // 2 - if item is inactive then
              //     2.1 - check if item will be recovered
              const isChecked = contractItem.isActive
                ? !isBasicPlanInactive && !itemWillBeRemoved
                : !isBasicPlanInactive && Boolean(itemWillBeRecovered);

              // auto-update will be disabled if
              // 1 - if item is not basic plan and basic plan is inactive
              const isDisabled = !contractItem.isBasicPlan && isBasicPlanInactive;

              return (
                <Table.Row key={contractItem?.price?.id}>
                  <Table.Cell>{contractItem?.price?.label || contractItem?.price?.nickname}</Table.Cell>
                  <Table.Cell>
                    {contractItem?.price?.amountWithTax} {contractItem?.price?.currency?.toUpperCase()}{' '}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Checkbox
                      checked={isChecked}
                      disabled={isDisabled}
                      testId={`checkbox-${contractItem.id}`}
                      value={contractItem.id}
                      onChange={(event, data) =>
                        contractItem.isActive
                          ? handleToggleActiveItems(event, data as any)
                          : handleToggleInactiveItems(event, data as any)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </StyledTable>
        <StyledCard
          fluid
          description={dateFns.format(
            dateFns.add(new Date(contract.current_period_start), { months: 1 }),
            'yyyy-MM-dd'
          )}
          meta={t(Translations.LABEL_NEXT_UPDATE).toLocaleUpperCase()}
          testId="next-update"
        />
        {Boolean(unPurchasedItems.length) && (
          <StyledTable unstackable>
            <StyledTableHeader>
              <Table.Row>
                <Table.HeaderCell width={2}>{t(Translations.LABEL_ITEM).toLocaleUpperCase()}</Table.HeaderCell>
                <Table.HeaderCell width={1}>{t(Translations.LABEL_PRICE).toLocaleUpperCase()}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={1}>
                  {t(Translations.LABEL_ADDITIONAL).toLocaleUpperCase()}
                </Table.HeaderCell>
              </Table.Row>
            </StyledTableHeader>

            <Table.Body>
              {unPurchasedItems.map((price) => {
                const isChecked = Boolean(toPurchaseItems.find((toPurchaseItem) => toPurchaseItem.id === price.id));
                return (
                  <Table.Row key={price.id}>
                    <Table.Cell>{price.label || price.description}</Table.Cell>
                    <Table.Cell>
                      {price?.amountWithTax} {price?.currency?.toUpperCase()}{' '}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Checkbox
                        checked={isChecked}
                        testId={`checkbox-${price.id}`}
                        value={price.id}
                        onChange={(event, data) => handleToggleUnPurchasedItems(event, data as any)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </StyledTable>
        )}
      </StyledWrapper>

      {Boolean(errorMessage) && <Message error content={errorMessage} />}
      {Boolean(successMessage) && <Message success content={successMessage} />}

      <StyledButtonWrapper>
        <Button
          disabled={isUpdateButtonDisabled}
          loading={isLoading}
          testId="update-contract"
          variant={ButtonVariants.PRIMARY}
          onClick={handleClickUpdate}
        >
          {t(Translations.BUTTON_UPDATE)}
        </Button>
      </StyledButtonWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(20)};
`;

const StyledCard = styled(Card)<{ rounded?: boolean }>`
  &&&& {
    background-color: transparent;
    margin-top: 0;
    margin-bottom: ${(props) => props.theme.pxToRem(10)};

    .content {
      .meta {
        color: ${(props) => props.theme.colors.primary};
        font-size: ${(props) => props.theme.fontSizes.xs};
        font-weight: ${(props) => props.theme.fontWeights.bold};
      }

      .description {
        color: ${(props) => props.theme.colors.neutralBlack};
        font-size: ${(props) => props.theme.fontSizes.s};
      }
    }
  }
`;

const StyledTable = styled(Table)`
  &&& {
    background-color: transparent;
    border-radius: 0;
    margin-bottom: ${(props) => props.theme.pxToRem(10)};
    margin-top: ${(props) => props.theme.pxToRem(0)};
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& {
    th {
      background-color: transparent;
      color: ${(props) => props.theme.colors.primary};
      font-size: ${(props) => props.theme.fontSizes.xs};
    }
  }
`;

const StyledButtonWrapper = styled.div`
  text-align: right;
`;
