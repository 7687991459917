import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const rootSelector = createSelector(
  (state: RootState): State.Layout => state.layout,
  (layout: State.Layout) => layout
);

export const isSidebarVisibleSelector = createSelector(
  rootSelector,
  (layout: State.Layout): boolean => layout.isSidebarVisible
);

export const isUnPurchasedModalVisibleSelector = createSelector(
  rootSelector,
  (layout: State.Layout): boolean => layout.isUnPurchasedModalVisible
);
