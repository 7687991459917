import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { en } from './en.resource';
import { id } from './id.resources';
import { jp } from './jp.resources';

const resources = { en, id, jp };

const defaultLanguage = 'jp';
const language = localStorage.getItem('i18nextLng') || defaultLanguage;

const languageDetector = new LanguageDetector(null, { lookupQuerystring: 'lang' });

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({ fallbackLng: 'jp', interpolation: { escapeValue: false }, lng: language, resources });

i18n.changeLanguage();

export default i18n;
