import * as dateFns from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { Image, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import { Card, Link } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { EditIcon } from '@/resources/icons';
import { getPath } from '@/utils/index';

interface Props {
  contract: State.ContractData;
}

export const ContractItem: React.FC<Props> = ({ contract }) => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledCard
        fluid
        description={contract?.product?.displayName || contract?.product?.metadata?.label || contract?.product?.name}
        meta={t(Translations.LABEL_REGION).toLocaleUpperCase()}
        right={
          <Link testId="edit-contract" to={generatePath(getPath(Routes.USER_EDIT_CONTRACT), { id: contract.id })}>
            <StyledImage floated="right" size="mini" src={EditIcon} />
          </Link>
        }
        rounded={1}
        testId="region"
      />
      <StyledTable unstackable>
        <StyledTableHeader>
          <Table.Row>
            <Table.HeaderCell width={2}>{t(Translations.LABEL_ITEM).toLocaleUpperCase()}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{t(Translations.LABEL_PRICE).toLocaleUpperCase()}</Table.HeaderCell>
          </Table.Row>
        </StyledTableHeader>
        <Table.Body>
          {contract?.items?.map((contractItem) => (
            <Table.Row key={contractItem?.price?.id}>
              <Table.Cell>{contractItem?.price?.label || contractItem?.price?.nickname}</Table.Cell>
              <Table.Cell>
                {contractItem?.price?.amountWithTax} {contractItem?.price?.currency?.toUpperCase()}{' '}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </StyledTable>
      <StyledCard
        fluid
        description={dateFns.format(new Date(contract.current_period_end), 'yyyy-MM-dd')}
        meta={t(Translations.LABEL_NEXT_UPDATE).toLocaleUpperCase()}
        testId="next-update"
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(20)};
`;

const StyledCard = styled(Card)<{ rounded?: boolean }>`
  &&&& {
    background-color: ${(props) => props.theme.colors.neutralWhite};
    ${(props) =>
      props.rounded
        ? `
    border-top-left-radius: ${props.theme.pxToRem(10)};
    border-top-right-radius: ${props.theme.pxToRem(10)};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    `
        : `
      border-radius: 0;
    `};

    margin-top: 0;
    margin-bottom: ${(props) => props.theme.pxToRem(1)};

    .content {
      .meta {
        color: ${(props) => props.theme.colors.neutralGreyForHeaders};
        font-size: ${(props) => props.theme.fontSizes.xs};
        font-weight: ${(props) => props.theme.fontWeights.bold};
      }

      .description {
        color: ${(props) => props.theme.colors.neutralBlack};
        font-size: ${(props) => props.theme.fontSizes.s};
      }
    }
  }
`;

const StyledImage = styled(Image)`
  &&& {
    width: ${(props) => props.theme.pxToRem(25)};
    height: auto;
  }
`;

const StyledTable = styled(Table)`
  &&& {
    border-radius: 0;
    margin-bottom: ${(props) => props.theme.pxToRem(1)};
    margin-top: ${(props) => props.theme.pxToRem(1)};
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& {
    th {
      background-color: ${(props) => props.theme.colors.neutralWhite};
      color: ${(props) => props.theme.colors.neutralGreyForHeaders};
      font-size: ${(props) => props.theme.fontSizes.s};
    }
  }
`;
