import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, ButtonVariants, Message } from '@/atoms/index';
import { Routes, Translations } from '@/constants/index';
import { EditContractItem } from '@/molecules/index';
import { HeaderWithBack } from '@/organisms/index';
import { FooterAuth } from '@/organisms/index';
import { SingleColumn } from '@/templates/index';
import { getPath } from '@/utils/index';

interface Props {
  contractList: State.ContractData[];
  errorMessage: string;
  isContractLoading: boolean;
  productData?: State.ProductData;
  successMessage: string;
  clearProduct: () => void;
  getProduct: (id: string) => void;
  editContract: (params: {
    contractId: string;
    toRecoverItems?: State.StripeSubscriptionItem[];
    toRemoveItems?: State.StripeSubscriptionItem[];
    toPurchaseItems?: State.PriceData[];
  }) => void;
  setContractErrorMessage: (errorMessage: string) => void;
  setContractSuccessMessage: (successMessage: string) => void;
}

export const PageUserEditContract: React.FC<Props> = ({
  contractList,
  errorMessage,
  isContractLoading,
  productData,
  successMessage,
  editContract,
  clearProduct,
  getProduct,
  setContractErrorMessage,
  setContractSuccessMessage,
}) => {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();

  const contract = contractList.find((contract) => contract.id === params.id);
  const contractItemsPriceIds = contract?.items?.map((item) => item?.price.id);
  const unPurchasedItems = productData?.prices?.filter((price) => !contractItemsPriceIds?.includes(price.id));

  const handleOnSubmit = (params: {
    toRecoverItems?: State.StripeSubscriptionItem[];
    toRemoveItems?: State.StripeSubscriptionItem[];
    toPurchaseItems?: State.PriceData[];
  }) => {
    const { toRecoverItems, toRemoveItems, toPurchaseItems } = params;

    editContract({
      contractId: contract?.id as string,
      ...(toRecoverItems?.length && { toRecoverItems }),
      ...(toRemoveItems?.length && { toRemoveItems }),
      ...(toPurchaseItems?.length && { toPurchaseItems }),
    });
  };

  const handleClearMessage = () => {
    setContractErrorMessage('');
    setContractSuccessMessage('');
  };

  // Clear error and success message
  React.useEffect(() => {
    setContractErrorMessage('');
    setContractSuccessMessage('');
  }, [setContractErrorMessage, setContractSuccessMessage]);

  // Get product
  React.useEffect(() => {
    if (contract?.product) {
      clearProduct();
      getProduct(contract.product.id);
    }
  }, [clearProduct, contract, getProduct]);

  return (
    <SingleColumn>
      <>
        <HeaderWithBack path={getPath(Routes.USER_CONTRACTS)} title={t(Translations.TITLE_EDIT_CONTRACT)} />
      </>
      <>
        {contract && (
          <>
            <EditContractItem
              contract={contract}
              errorMessage={errorMessage}
              isLoading={isContractLoading}
              successMessage={successMessage}
              unPurchasedItems={unPurchasedItems || []}
              onClearMessage={handleClearMessage}
              onSubmit={handleOnSubmit}
            />
          </>
        )}
      </>
      <>
        <FooterAuth />
      </>
      /.
    </SingleColumn>
  );
};
