import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { Checkbox } from '@/atoms/index';
import { Translations } from '@/constants/index';

interface Props {
  prices?: State.PriceData[];
  isRadio?: boolean;
  selectedPrices?: State.PriceData[];
  onCheckedPrice?: (name: string) => void;
}

export const CheckboxProductPrices: React.FC<Props> = ({ prices, isRadio, selectedPrices, onCheckedPrice }) => {
  const { t } = useTranslation();
  return (
    <>
      {prices?.map(
        (price) =>
          Boolean(price) && (
            <StyledWrapper
              isChecked={Boolean(selectedPrices?.find((selectedPrice) => selectedPrice.id === price.id))}
              key={price.id}
              onClick={() => {
                if (onCheckedPrice) {
                  return onCheckedPrice(price.id);
                }
              }}
            >
              <Checkbox
                checked={Boolean(selectedPrices?.find((selectedPrice) => selectedPrice.id === price.id))}
                label={price?.label || price.description}
                radio={isRadio}
                testId={price.id}
                value={price.id}
              />
              <span>
                {price?.amountWithTax} {t(Translations.LABEL_YEN_MONTH)}
              </span>
            </StyledWrapper>
          )
      )}
    </>
  );
};

const StyledWrapper = styled.div<{ isChecked?: boolean }>`
  ${(props) =>
    props?.isChecked
      ? `
      background-color: ${props.theme.colors.neutralWhite};
      border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.primary};

      span {
        font-weight: ${props.theme.fontWeights.black};
      }

  `
      : `
      background-color: ${props.theme.colors.neutralGreyForListItems};
      border: ${props.theme.pxToRem(1)} solid ${props.theme.colors.neutralGreyForListItemBorders};
  `};

  border-radius: ${(props) => props.theme.pxToRem(5)};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => `${props.theme.pxToRem(15)} ${props.theme.pxToRem(25)}`};
  margin-bottom: ${(props) => props.theme.pxToRem(10)};

  span {
    font-size: ${(props) => props.theme.fontSizes.s};
    // float: right;
    flex: 1;
    text-align: right;
  }

  &&& {
    label {
      font-size: ${(props) => props.theme.fontSizes.m};
    }
  }
`;
