export enum Translations {
  BUTTON_ADD_CARD = 'BUTTON_ADD_CARD',
  BUTTON_BACK = 'BUTTON_BACK',
  BUTTON_CANCEL = 'BUTTON_CANCEL',
  BUTTON_CONTRACTS = 'BUTTON_CONTRACTS',
  BUTTON_LOG_IN = 'BUTTON_LOG_IN',
  BUTTON_NEXT = 'BUTTON_NEXT',
  BUTTON_OK = 'BUTTON_OK',
  BUTTON_OKAY = 'BUTTON_OKAY',
  BUTTON_REGISTER = 'BUTTON_REGISTER',
  BUTTON_RETURN = 'BUTTON_RETURN',
  BUTTON_PURCHASE = 'BUTTON_PURCHASE',
  BUTTON_SAVE = 'BUTTON_SAVE',
  BUTTON_UPDATE = 'BUTTON_UPDATE',

  DESCRIPTION_DELETE_CARD = 'DESCRIPTION_DELETE_CARD',
  DESCRIPTION_LEGAL_AGREEMENTS = 'DESCRIPTION_LEGAL_AGREEMENTS',
  DESCRIPTION_NO_CONTRACTS = 'DESCRIPTION_NO_CONTRACTS',
  DESCRIPTION_NO_REGISTERED_CARD = 'DESCRIPTION_NO_REGISTERED_CARD',
  DESCRIPTION_UNPURCHASED_PRODUCT = 'DESCRIPTION_UNPURCHASED_PRODUCT',

  ERROR_ADDRESS_MAX_CHARACTERS = 'ERROR_ADDRESS_MAX_CHARACTERS',
  ERROR_CARD_INVALID = 'ERROR_CARD_INVALID',
  ERROR_CARD_IS_ALREADY_REGISTERED = 'ERROR_CARD_IS_ALREADY_REGISTERED',
  ERROR_CONTRACT_FAILED_TO_UPDATE = 'ERROR_CONTRACT_FAILED_TO_UPDATE',
  ERROR_EMAIL_IS_ALREADY_TAKEN = 'ERROR_EMAIL_IS_ALREADY_TAKEN',
  ERROR_INVALID_CREDENTIALS = 'ERROR_INVALID_CREDENTIALS',
  ERROR_INVALID_PHONE_NO = 'ERROR_INVALID_PHONE_NO',
  ERROR_FIELD_IS_REQUIRED = 'ERROR_FIELD_IS_REQUIRED',
  ERROR_FULL_WIDTH_DIGIT = 'ERROR_FULL_WIDTH_DIGIT',
  ERROR_NAME_MAX_CHARACTERS = 'ERROR_NAME_MAX_CHARACTERS',
  ERROR_NO_ACCESS = 'ERROR_NO_ACCESS',
  ERROR_NO_CARD = 'ERROR_NO_CARD',
  ERROR_ORG_MAX_CHARACTERS = 'ERROR_ORG_MAX_CHARACTERS',
  ERROR_PASSWORD_MINIMUM_CHARACTERS = 'ERROR_PASSWORD_MINIMUM_CHARACTERS',
  ERROR_PHONE_NO_MAX_DIGITS = 'ERROR_PHONE_NO_MAX_DIGITS',
  ERROR_PLEASE_RE_LOGIN = 'ERROR_PLEASE_RE_LOGIN',
  ERROR_SOMETHING_WENT_WRONG = 'ERROR_SOMETHING_WENT_WRONG',
  ERROR_USER_DOES_NOT_EXIST = 'ERROR_USER_DOES_NOT_EXIST',
  ERROR_USER_IS_ALREADY_EXISTING = 'ERROR_USER_IS_ALREADY_EXISTING',

  LABEL_ADDITIONAL = 'LABEL_ADDITIONAL',
  LABEL_ADDRESS = 'LABEL_ADDRESS',
  LABEL_AUTO_UPDATE = 'LABEL_AUTO_UPDATE',
  LABEL_BASIC = 'LABEL_BASIC',
  LABEL_BILLING = 'LABEL_BILLING',
  LABEL_BUILDING = 'LABEL_BUILDING',
  LABEL_CARD_BILLING_ADDRESS = 'LABEL_CARD_BILLING_ADDRESS',
  LABEL_CARD_BILLING_CITY = 'LABEL_CARD_BILLING_CITY',
  LABEL_CARD_BILLING_COUNTRY = 'LABEL_CARD_BILLING_COUNTRY',
  LABEL_CARD_BILLING_LINE_ONE = 'LABEL_CARD_BILLING_LINE_ONE',
  LABEL_CARD_BILLING_LINE_TWO = 'LABEL_CARD_BILLING_LINE_TWO',
  LABEL_CARD_BILLING_STATE = 'LABEL_CARD_BILLING_STATE',
  LABEL_CARD_CVC = 'LABEL_CARD_CVC',
  LABEL_CARD_DETAILS = 'LABEL_CARD_DETAILS',
  LABEL_CARD_EXPIRATION = 'LABEL_CARD_EXPIRATION',
  LABEL_CARD_NOT_REGISTERED = 'LABEL_CARD_NOT_REGISTERED',
  LABEL_CARD_NUMBER = 'LABEL_CARD_NUMBER',
  LABEL_CREATE_CONTACT = 'LABEL_CREATE_CONTACT',
  LABEL_DATE = 'LABEL_DATE',
  LABEL_DELETE_CARD = 'LABEL_DELETE_CARD',
  LABEL_EDIT_BILLING = 'LABEL_EDIT_BILLING',
  LABEL_EMAIL = 'LABEL_EMAIL',
  LABEL_EMAIL_ADDRESS = 'LABEL_EMAIL_ADDRESS',
  LABEL_ITEM = 'LABEL_ITEM',
  LABEL_FULL_NAME = 'LABEL_FULL_NAME',
  LABEL_MUNICIPALITIES = 'LABEL_MUNICIPALITIES',
  LABEL_NAME = 'LABEL_NAME',
  LABEL_NEW_ACCOUNT = 'LABEL_NEW_ACCOUNT',
  LABEL_NEXT_UPDATE = 'LABEL_NEXT_UPDATE',
  LABEL_LOGOUT = 'LABEL_LOGOUT',
  LABEL_ORGANIZATION = 'LABEL_ORGANIZATION',
  LABEL_PHONE_NO = 'LABEL_PHONE_NO',
  LABEL_PASSWORD = 'LABEL_PASSWORD',
  LABEL_POST_CODE = 'LABEL_POST_CODE',
  LABEL_PREFECTURES = 'LABEL_PREFECTURES',
  LABEL_PRICE = 'LABEL_PRICE',
  LABEL_PURCHASE_ITEMS = 'LABEL_PURCHASE_ITEMS',
  LABEL_PURCHASE_CONFIRMATION_DETAILS = 'LABEL_PURCHASE_CONFIRMATION_DETAILS',
  LABEL_REGION = 'LABEL_REGION',
  LABEL_REGION_NAME = 'LABEL_REGION_NAME',
  LABEL_SEA_AREA_SELECTION = 'LABEL_SEA_AREA_SELECTION',
  LABEL_SERVICE = 'LABEL_SERVICE',
  LABEL_SERVICE_SELECTION = 'LABEL_SERVICE_SELECTION',
  LABEL_SETTINGS = 'LABEL_SETTINGS',
  LABEL_TELEPHONE_NO = 'LABEL_TELEPHONE_NO',
  LABEL_TOP_PAGE = 'LABEL_TOP_PAGE',
  LABEL_TOTAL = 'LABEL_TOTAL',
  LABEL_USAGE = 'LABEL_USAGE',
  LABEL_UNPURCHASED_PRODUCT = 'LABEL_UNPURCHASED_PRODUCT',
  LABEL_YEN_MONTH = 'LABEL_YEN_MONTH',

  LINK_CONTACT = 'LINK_CONTACT',
  LINK_FORGOT_PASSWORD = 'LINK_FORGOT_PASSWORD',
  LINK_PRIVACY_POLICY = 'LINK_PRIVACY_POLICY',
  LINK_TERMS_OF_SERVICE = 'LINK_TERMS_OF_SERVICE',

  MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST = 'MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST',
  MENU_LABEL_CURRENT_FRONT = 'MENU_LABEL_CURRENT_FRONT',
  MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP = 'MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP',
  MENU_LABEL_CURRENT_SATELLITE_SST = 'MENU_LABEL_CURRENT_SATELLITE_SST',
  MENU_LABEL_CURRENT_TIDE = 'MENU_LABEL_CURRENT_TIDE',
  MENU_LABEL_TOMORROWS_FRONT = 'MENU_LABEL_TOMORROWS_FRONT',
  MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP = 'MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP',
  MENU_LABEL_TOMORROWS_TIDE = 'MENU_LABEL_TOMORROWS_TIDE',
  MENU_LABEL_TOM_SEA_SURFACE_TEMP = 'MENU_LABEL_TOM_SEA_SURFACE_TEMP',

  MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST_DESC = 'MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST_DESC',
  MENU_LABEL_CURRENT_FRONT_DESC = 'MENU_LABEL_CURRENT_FRONT_DESC',
  MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP_DESC = 'MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP_DESC',
  MENU_LABEL_CURRENT_SATELLITE_SST_DESC = 'MENU_LABEL_CURRENT_SATELLITE_SST_DESC',
  MENU_LABEL_CURRENT_TIDE_DESC = 'MENU_LABEL_CURRENT_TIDE_DESC',
  MENU_LABEL_TOMORROWS_FRONT_DESC = 'MENU_LABEL_TOMORROWS_FRONT_DESC',
  MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP_DESC = 'MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP_DESC',
  MENU_LABEL_TOMORROWS_TIDE_DESC = 'MENU_LABEL_TOMORROWS_TIDE_DESC',
  MENU_LABEL_TOM_SEA_SURFACE_TEMP_DESC = 'MENU_LABEL_TOM_SEA_SURFACE_TEMP_DESC',

  PLACEHOLDER_EMAIL = 'PLACEHOLDER_EMAIL',
  PLACEHOLDER_NAME = 'PLACEHOLDER_NAME',
  PLACEHOLDER_PASSWORD = 'PLACEHOLDER_PASSWORD',
  PLACEHOLDER_SELECT_PRODUCT = 'PLACEHOLDER_SELECT_PRODUCT',

  PRODUCT_ITEM_BASIC = 'PRODUCT_ITEM_BASIC',
  PRODUCT_ITEM_FRONT = 'PRODUCT_ITEM_FRONT',
  PRODUCT_ITEM_MIDDLE_LAYER_WATER_TEMPERATURE = 'PRODUCT_ITEM_MIDDLE_LAYER_WATER_TEMPERATURE',
  PRODUCT_ITEM_TIDE = 'PRODUCT_ITEM_TIDE',

  PRODUCT_NAME_KYOTO = 'PRODUCT_NAME_KYOTO',
  PRODUCT_NAME_SAGA_GENKAI = 'PRODUCT_NAME_SAGA_GENKAI',
  PRODUCT_NAME_KAGAWA = 'PRODUCT_NAME_KAGAWA',
  PRODUCT_NAME_CHIBA_SATOBO = 'PRODUCT_NAME_CHIBA_SATOBO',
  PRODUCT_NAME_CHIBA_UCHIBO = 'PRODUCT_NAME_CHIBA_UCHIBO',
  PRODUCT_NAME_CHIBA_KUJUKURI = 'PRODUCT_NAME_CHIBA_KUJUKURI',
  PRODUCT_NAME_ARIAKE = 'PRODUCT_NAME_ARIAKE',
  PRODUCT_NAME_YATSUSHIRO = 'PRODUCT_NAME_YATSUSHIRO',
  PRODUCT_NAME_KAGOSHIMA_WEST = 'PRODUCT_NAME_KAGOSHIMA_WEST',
  PRODUCT_NAME_SAGAMIWAN = 'PRODUCT_NAME_SAGAMIWAN',
  PRODUCT_NAME_OSHIKA = 'PRODUCT_NAME_OSHIKA',
  PRODUCT_NAME_ENSYUNADA = 'PRODUCT_NAME_ENSYUNADA',
  PRODUCT_NAME_SURUGAWAN = 'PRODUCT_NAME_SURUGAWAN',
  PRODUCT_NAME_NAGASAKI = 'PRODUCT_NAME_NAGASAKI',
  PRODUCT_NAME_YAMAGUCHI = 'PRODUCT_NAME_YAMAGUCHI',
  PRODUCT_NAME_KOCHI = 'PRODUCT_NAME_KOCHI',
  PRODUCT_NAME_WAKAYAMA = 'PRODUCT_NAME_WAKAYAMA',
  PRODUCT_NAME_HACHIJO = 'PRODUCT_NAME_HACHIJO',
  PRODUCT_NAME_TSUSHIMA = 'PRODUCT_NAME_TSUSHIMA',
  PRODUCT_NAME_CHIBA_KATSUURA = 'PRODUCT_NAME_CHIBA_KATSUURA',
  PRODUCT_NAME_IBARAKI = 'PRODUCT_NAME_IBARAKI',
  PRODUCT_NAME_KOCHI_WEST = 'PRODUCT_NAME_KOCHI_WEST',
  PRODUCT_NAME_IZU_EAST = 'PRODUCT_NAME_IZU_EAST',
  PRODUCT_NAME_IZU_SOUTH = 'PRODUCT_NAME_IZU_SOUTH',
  PRODUCT_NAME_MIYAKEJIMA = 'PRODUCT_NAME_MIYAKEJIMA',
  PRODUCT_NAME_TOKYOWAN = 'PRODUCT_NAME_TOKYOWAN',
  PRODUCT_NAME_OSAKA = 'PRODUCT_NAME_OSAKA',
  PRODUCT_NAME_AICHI = 'PRODUCT_NAME_AICHI',
  PRODUCT_NAME_MIE = 'PRODUCT_NAME_MIE',
  PRODUCT_NAME_TOKYOCHIBA = 'PRODUCT_NAME_TOKYOCHIBA',

  SUBTITLE_PASSWORD_RECOVERY = 'SUBTITLE_PASSWORD_RECOVERY',
  SUCCESS_CARD_REGISTRATION = 'SUCCESS_CARD_REGISTRATION',
  SUCCESS_CONTRACT_UPDATED = 'SUCCESS_CONTRACT_UPDATED',
  SUCCESS_PASSWORD_RESET_LINK = 'SUCCESS_PASSWORD_RESET_LINK',
  SUCCESS_PROFILE_UPDATE = 'SUCCESS_PROFILE_UPDATE',

  TITLE_ADD_CONTRACT = 'TITLE_ADD_CONTRACT',
  TITLE_CARD = 'TITLE_CARD',
  TITLE_CHECK_EMAIL = 'TITLE_CHECK_EMAIL',
  TITLE_CONTRACTS = 'TITLE_CONTRACTS',
  TITLE_CREDIT_CARD_REGISTRATION = 'TITLE_CREDIT_CARD_REGISTRATION',
  TITLE_EDIT_CONTRACT = 'TITLE_EDIT_CONTRACT',
  TITLE_LOG_IN = 'TITLE_LOG_IN',
  TITLE_PASSWORD_RESET = 'TITLE_PASSWORD_RESET',
  TITLE_PURCHASE_HISTORY = 'TITLE_PURCHASE_HISTORY',
  TITLE_REGISTER = 'TITLE_REGISTER',
  TITLE_SETTINGS = 'TITLE_SETTINGS',
  TITLE_USAGE = 'TITLE_USAGE',
}
