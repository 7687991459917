import React from 'react';
import { connect } from 'react-redux';

import { PageUserContracts } from '@/pages/PageUserContracts';
import { cardListSelector } from '@/state/card/selectors';
import { contractListSelector } from '@/state/contract/selectors';
import { productUnPurchasedListSelector } from '@/state/product/selectors';
import { AppDispatch, RootState } from '@/state/store';

const mapActionCreators = (dispatch: AppDispatch) => ({});

const mapStateToProps = (state: RootState) => ({
  cardList: cardListSelector(state),
  contractList: contractListSelector(state),
  productUnPurchasedList: productUnPurchasedListSelector(state),
});

export default connect(mapStateToProps, mapActionCreators)(PageUserContracts);
