import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';

const rootSelector = createSelector(
  (state: RootState): State.User => state.user,
  (user: State.User) => user
);

export const userDataSelector = createSelector(
  rootSelector,
  (user: State.User): State.UserData | undefined => user?.data
);

export const errorMessageSelector = createSelector(rootSelector, (user: State.User): string => user.errorMessage);

export const isAuthenticatedSelector = createSelector(
  userDataSelector,
  (userData: State.UserData | undefined): boolean => Boolean(userData?.id)
);

export const isLoadingSelector = createSelector(rootSelector, (user: State.User): boolean => user.isLoading);

export const isUserSetupCompletedSelector = createSelector(
  userDataSelector,
  (userData: State.UserData | undefined): boolean => Boolean(userData?.name) && Boolean(userData?.organization)
);

export const successMessageSelector = createSelector(rootSelector, (user: State.User): string => user.successMessage);
