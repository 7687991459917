import { CardExpiryElement } from '@stripe/react-stripe-js';
import { StripeCardExpiryElementChangeEvent, StripeCardExpiryElementOptions } from '@stripe/stripe-js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Translations } from '@/constants/index';

interface Props {
  error: string;
  handleInputChange: (name: string, label: string) => (elementData: StripeCardExpiryElementChangeEvent) => void;
}

const CARD_EXPIRY_ELEMENT_OPTIONS: StripeCardExpiryElementOptions = {
  style: {
    base: {
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: 'lightgrey',
      },
      color: 'black',
      fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      fontWeight: 400,
      iconColor: 'black',
    },
    invalid: {
      color: 'red',
      iconColor: 'red',
    },
  },
};

export const CardExpirationDate: React.FC<Props> = ({ error, handleInputChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledLabel>{t(Translations.LABEL_CARD_EXPIRATION)}</StyledLabel>
      <CardExpiryElement
        options={CARD_EXPIRY_ELEMENT_OPTIONS}
        onChange={handleInputChange('cardExpiryDate', 'Card Expiration Date')}
      />
      <StyledErrorMessage>{error}</StyledErrorMessage>
    </>
  );
};

const StyledLabel = styled.div`
  text-align: left;
  width: 100%;
  padding-bottom: ${(props) => props.theme.pxToRem(10)};
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  width: 100%;
  color: ${(props) => props.theme.colors.neutralRed};
  margin-top: ${(props) => props.theme.pxToRem(5)};
  font-size: ${(props) => props.theme.pxToRem(16)};
`;
