export enum DataType {
  HIMAWARI = 'himawari',
  GAN = 'gan',
  TSST = 'tsst',
  TCUR = 'tcur',
  TSEA = 'tsea',
}

export enum AccessType {
  BASIC = 'basic',
  FRONT = 'front',
  TIDE = 'tide',
  MIDDLE_LAYER_WATER_TEMPERATURE = 'middle_layer_water_temperature',
}
