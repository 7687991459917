import React from 'react';
import styled from 'styled-components';

import { Container, TopBar } from '@/atoms/index';

interface Props {
  children: React.ReactNode[];
}

export const SingleColumn: React.FC<Props> = ({ children }) => {
  return (
    <StyledWrapper>
      <StyledContentWrapper>
        <TopBar />
        <StyledHeaderWrapper>
          <Container>{children?.[0] && children[0]}</Container>
        </StyledHeaderWrapper>
        <Container>{children[1]}</Container>
      </StyledContentWrapper>

      {children?.[2] && <StyledFooterWrapper>{children[2]}</StyledFooterWrapper>}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledHeaderWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.pxToRem(10)};
`;

const StyledContentWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  margin-bottom: ${(props) => props.theme.pxToRem(30)};
`;

const StyledFooterWrapper = styled.div`
  text-align: center;
  flex-shrink: 0;
`;
