import { createAsyncThunk } from '@reduxjs/toolkit';

import { Customer } from '@/collections/index';
import firebase from '@/firebase/index';

import { getCards } from '../card/actions';
import { getContracts } from '../contract/actions';
import { getProducts } from '../product/actions';
import { setUserData } from '../user/actions';
import { AppActions } from './constants';

export const initialize = createAsyncThunk(AppActions.APP_INIT, async (_, thunkAPI) => {
  try {
    const rehydrate = () =>
      new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            // Get customer
            let customer;
            try {
              customer = await new Customer().getCustomer(user.uid);
              if (!customer) {
                return reject('User does not exist');
              }
            } catch (error) {
              return reject('User does not exist');
            }

            if (customer?.stripeId) {
              // fetch user contracts
              await thunkAPI.dispatch(getContracts(user.uid));

              // fetch user cards
              await thunkAPI.dispatch(getCards());
            }

            // set user data
            thunkAPI.dispatch(setUserData({ ...customer, id: user.uid } as unknown as State.UserData));

            return resolve('User retrieved');
          } else {
            return reject(new Error('User does not exist'));
          }
        });
      });

    try {
      await rehydrate();
    } catch (error) {
      thunkAPI.dispatch(setUserData(undefined));

      await firebase.auth().signOut();
    }

    // fetch products
    await thunkAPI.dispatch(getProducts());

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
});
