import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';

import { EmptyIcon } from '@/resources/icons';

export const EmptyPlaceHolder: React.FC = () => {
  return (
    <Grid style={{ height: '70vh' }} textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image centered size="tiny" src={EmptyIcon} />
        <StyledTextWrapper>
          <p>Empty</p>
        </StyledTextWrapper>
      </Grid.Column>
    </Grid>
  );
};

const StyledTextWrapper = styled.div`
  margin-top: ${(props) => props.theme.pxToRem(10)};

  p {
    color: ${(props) => props.theme.colors.neutralBlackForIcons};
  }
`;
