import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';

import { Routes, Translations } from '@/constants/index';
import { FooterGuest } from '@/organisms/index';
import { HowToUseIcon, LogoutIcon, NewRegistrationIcon, OceanIcon, SettingsIcon } from '@/resources/icons';
import Logo from '@/resources/logo.png';
import { SingleColumn } from '@/templates/index';
import { getPath } from '@/utils/index';

interface Props {
  isAuth: boolean;
  isUserSetupCompleted: boolean;
  logout: () => void;
}

export const PageHome: React.FC<Props> = ({ isAuth, isUserSetupCompleted, logout }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnClickService = () => {
    if (isAuth) {
      const previousServiceSelection = localStorage.getItem('previousServiceSelection');
      if (previousServiceSelection) {
        const params = JSON.parse(previousServiceSelection);
        navigate(
          generatePath(getPath(Routes.USER_VIEW_PRODUCT_DATA), {
            productName: params.productName,
            serviceName: 'current-cloud-removal-sst',
          })
        );
      } else if (!isUserSetupCompleted) {
        navigate(getPath(Routes.USER_SETTINGS));
      } else {
        navigate(getPath(Routes.USER_USAGES));
      }
    } else {
      navigate(getPath(Routes.LOGIN));
    }
  };

  const handleOnClickNewAccount = () => navigate(getPath(Routes.CHECK_EMAIL));

  const handleOnClickSettings = () => navigate(getPath(Routes.USER_SETTINGS));

  const handleOnClickUsages = () => navigate(getPath(Routes.USER_USAGES));

  const handleOnClickLogout = () => logout();

  return (
    <SingleColumn>
      <></>
      <StyledContent>
        <StyledImage size="medium" src={Logo} />

        <StyledGrid celled="internally">
          <Grid.Row>
            <Grid.Column width={8} onClick={handleOnClickService}>
              <Image centered size="mini" src={OceanIcon} />
              <h4>{t(Translations.LABEL_SERVICE)}</h4>
            </Grid.Column>
            {!isAuth && (
              <Grid.Column width={8} onClick={handleOnClickNewAccount}>
                <Image centered size="mini" src={NewRegistrationIcon} />
                <h4>{t(Translations.LABEL_NEW_ACCOUNT)}</h4>
              </Grid.Column>
            )}
            {isAuth && (
              <Grid.Column width={8} onClick={handleOnClickSettings}>
                <Image centered size="mini" src={SettingsIcon} />
                <h4>{t(Translations.LABEL_SETTINGS)}</h4>
              </Grid.Column>
            )}
          </Grid.Row>

          {isAuth && (
            <Grid.Row>
              <Grid.Column width={8} onClick={handleOnClickUsages}>
                <Image centered size="mini" src={HowToUseIcon} />
                <h4>{t(Translations.LABEL_USAGE)}</h4>
              </Grid.Column>
              <Grid.Column width={8} onClick={handleOnClickLogout}>
                <Image centered size="mini" src={LogoutIcon} />
                <h4>{t(Translations.LABEL_LOGOUT)}</h4>
              </Grid.Column>
            </Grid.Row>
          )}
        </StyledGrid>
      </StyledContent>
      <>
        <FooterGuest />
      </>
    </SingleColumn>
  );
};

const StyledContent = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: ${(props) => props.theme.pxToRem(20)} 0;
`;

const StyledGrid = styled(Grid)`
  text-align: center;
  max-width: ${(props) => props.theme.pxToRem(350)};
  &&&& {
    .row .column {
      padding: 20px;
    }
    cursor: pointer;
  }
`;

const StyledImage = styled(Image)`
  padding: ${(props) => props.theme.pxToRem(50)};
`;
