import { Translations } from '@/constants/index';

export const en = {
  translation: {
    [Translations.BUTTON_ADD_CARD]: 'Add Card',
    [Translations.BUTTON_BACK]: 'Back',
    [Translations.BUTTON_CANCEL]: 'Cancel',
    [Translations.BUTTON_CONTRACTS]: 'Setting / Purchase',
    [Translations.BUTTON_LOG_IN]: 'Log In',
    [Translations.BUTTON_NEXT]: 'Next',
    [Translations.BUTTON_REGISTER]: 'Register',
    [Translations.BUTTON_RETURN]: 'Return',
    [Translations.BUTTON_OK]: 'OK',
    [Translations.BUTTON_OKAY]: 'Okay',
    [Translations.BUTTON_PURCHASE]: 'Purchase',
    [Translations.BUTTON_SAVE]: 'Save',
    [Translations.BUTTON_UPDATE]: 'Update',

    [Translations.DESCRIPTION_DELETE_CARD]: 'Are you sure you want to delete your card?',
    [Translations.DESCRIPTION_LEGAL_AGREEMENTS]: 'If you continue, you agree to our _ and _',
    [Translations.DESCRIPTION_NO_CONTRACTS]: 'Add now to see your availed contracts here',
    [Translations.DESCRIPTION_NO_REGISTERED_CARD]: 'Please registrate your credit card on Settings page.',
    [Translations.DESCRIPTION_UNPURCHASED_PRODUCT]: 'Please purchase the option from Settings/ Purchase on Top Page.',

    [Translations.ERROR_ADDRESS_MAX_CHARACTERS]: 'Address should be 128 characters maximum only.',
    [Translations.ERROR_CARD_INVALID]:
      'Sorry, there has been a problem with your card. Please contact your card issuer',
    [Translations.ERROR_CARD_IS_ALREADY_REGISTERED]: 'Card is already registered. Please use another card',
    [Translations.ERROR_CONTRACT_FAILED_TO_UPDATE]: 'Contract failed to update',
    [Translations.ERROR_EMAIL_IS_ALREADY_TAKEN]: 'Email is already taken',
    [Translations.ERROR_INVALID_CREDENTIALS]: 'Invalid credentials',
    [Translations.ERROR_INVALID_PHONE_NO]: 'Please put valid phone no.',
    [Translations.ERROR_FIELD_IS_REQUIRED]: 'is required.',
    [Translations.ERROR_FULL_WIDTH_DIGIT]: 'Full-width digit is not allowed',
    [Translations.ERROR_NAME_MAX_CHARACTERS]: 'Full name should be 128 characters maximum only.',
    [Translations.ERROR_NO_ACCESS]: 'No Access',
    [Translations.ERROR_NO_CARD]: 'No Available Card',
    [Translations.ERROR_ORG_MAX_CHARACTERS]: 'Organization should be 128 characters maximum only.',
    [Translations.ERROR_PASSWORD_MINIMUM_CHARACTERS]: 'Password should be minimum 8 characters.',
    [Translations.ERROR_PHONE_NO_MAX_DIGITS]: 'Phone no. should be 11 digits maximum only.',
    [Translations.ERROR_PLEASE_RE_LOGIN]: 'Please re-login',
    [Translations.ERROR_SOMETHING_WENT_WRONG]: 'Something went wrong',
    [Translations.ERROR_USER_DOES_NOT_EXIST]: 'User does not exist',
    [Translations.ERROR_USER_IS_ALREADY_EXISTING]: 'User is already exist',

    [Translations.LABEL_ADDITIONAL]: 'Additional',
    [Translations.LABEL_ADDRESS]: 'Address',
    [Translations.LABEL_AUTO_UPDATE]: 'Auto-Update',
    [Translations.LABEL_BASIC]: 'Basic',
    [Translations.LABEL_BILLING]: 'Billing',
    [Translations.LABEL_BUILDING]: 'Building',
    [Translations.LABEL_CARD_BILLING_ADDRESS]: 'Billing Address',
    [Translations.LABEL_CARD_BILLING_CITY]: 'City',
    [Translations.LABEL_CARD_BILLING_COUNTRY]: 'Country',
    [Translations.LABEL_CARD_BILLING_LINE_ONE]: 'Line One',
    [Translations.LABEL_CARD_BILLING_LINE_TWO]: 'Line Two',
    [Translations.LABEL_CARD_BILLING_STATE]: 'State',
    [Translations.LABEL_CARD_CVC]: 'Card Cvc',
    [Translations.LABEL_CARD_DETAILS]: 'Card Details',
    [Translations.LABEL_CARD_EXPIRATION]: 'Card Expiration Date',
    [Translations.LABEL_CARD_NOT_REGISTERED]: 'No Card Registrated',
    [Translations.LABEL_CARD_NUMBER]: 'Card Number',
    [Translations.LABEL_CREATE_CONTACT]: 'Add Sea Area',
    [Translations.LABEL_DATE]: 'Date',
    [Translations.LABEL_DELETE_CARD]: 'Delete Card',
    [Translations.LABEL_EDIT_BILLING]: 'Edit Billing',
    [Translations.LABEL_EMAIL]: 'E-mail',
    [Translations.LABEL_EMAIL_ADDRESS]: 'E-mail Address',
    [Translations.LABEL_ITEM]: 'Item',
    [Translations.LABEL_FULL_NAME]: 'Full name',
    [Translations.LABEL_MUNICIPALITIES]: 'Municipalities',
    [Translations.LABEL_NAME]: 'Name',
    [Translations.LABEL_NEW_ACCOUNT]: 'New Account',
    [Translations.LABEL_NEXT_UPDATE]: 'Next Update',
    [Translations.LABEL_LOGOUT]: 'Logout',
    [Translations.LABEL_ORGANIZATION]: 'Organization',
    [Translations.LABEL_PHONE_NO]: 'Phone No.',
    [Translations.LABEL_PASSWORD]: 'Password',
    [Translations.LABEL_PRICE]: 'Price',
    [Translations.LABEL_POST_CODE]: 'Post Code',
    [Translations.LABEL_PREFECTURES]: 'Prefectures',
    [Translations.LABEL_PURCHASE_ITEMS]: 'Purchased Items',
    [Translations.LABEL_PURCHASE_CONFIRMATION_DETAILS]: 'Confirmation of purchase details',
    [Translations.LABEL_REGION]: 'Region',
    [Translations.LABEL_REGION_NAME]: 'Region Name',
    [Translations.LABEL_SEA_AREA_SELECTION]: 'Sea Area Selection',
    [Translations.LABEL_SERVICE]: 'Service',
    [Translations.LABEL_SERVICE_SELECTION]: 'Service Selection',
    [Translations.LABEL_SETTINGS]: 'Settings',
    [Translations.LABEL_TELEPHONE_NO]: 'Telephone No.',
    [Translations.LABEL_TOP_PAGE]: 'Go to Top Page',
    [Translations.LABEL_TOTAL]: 'Total',
    [Translations.LABEL_USAGE]: 'Usage',
    [Translations.LABEL_UNPURCHASED_PRODUCT]: 'Unpurchased Product Option',
    [Translations.LABEL_YEN_MONTH]: 'yen/month',

    [Translations.MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST]: 'Current Cloud Removal SST',
    [Translations.MENU_LABEL_CURRENT_FRONT]: 'Current Front',
    [Translations.MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP]: 'Current mid-level water temperature',
    [Translations.MENU_LABEL_CURRENT_SATELLITE_SST]: 'Current Satellite SST ',
    [Translations.MENU_LABEL_CURRENT_TIDE]: 'Current Tide',
    [Translations.MENU_LABEL_TOMORROWS_FRONT]: "Tomorrow's Front",
    [Translations.MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP]: "Tomorrow's mid-water temperature",
    [Translations.MENU_LABEL_TOMORROWS_TIDE]: "Tomorrow's Tide",
    [Translations.MENU_LABEL_TOM_SEA_SURFACE_TEMP]: "Tomorrow's Sea Surface Water Temperature",

    [Translations.MENU_LABEL_CURRENT_CLOUD_REMOVAL_SST_DESC]:
      'Surface seawater temperature with cloud-covered areas complemented with AI technology',
    [Translations.MENU_LABEL_CURRENT_FRONT_DESC]:
      'Estimated current front based on the data calculated by supercomputers',
    [Translations.MENU_LABEL_CURRENT_MID_LEVEL_WATER_TEMP_DESC]:
      'Current mid-level water temperature as calculated by supercomputers (20m)',
    [Translations.MENU_LABEL_CURRENT_SATELLITE_SST_DESC]:
      'Surface seawater temperature of the Himawari geostationary satellites',
    [Translations.MENU_LABEL_CURRENT_TIDE_DESC]: 'Current tide as calculated by supercomputers (0m or 20m)',
    [Translations.MENU_LABEL_TOMORROWS_FRONT_DESC]:
      "Estimated tomorrow's front based on the data calculated by supercomputers",
    [Translations.MENU_LABEL_TOMORROWS_MID_LEVEL_WATER_TEMP_DESC]:
      "Tomorrow's mid-level water temperature as calculated by supercomputers (20m)",
    [Translations.MENU_LABEL_TOMORROWS_TIDE_DESC]: "Tomorrow's tide as calculated by supercomputers (0m or 20m)",
    [Translations.MENU_LABEL_TOM_SEA_SURFACE_TEMP_DESC]:
      "Tomorrow's surface seawater temperature as calculated by supercomputers",

    [Translations.LINK_CONTACT]: 'Contact',
    [Translations.LINK_FORGOT_PASSWORD]: 'Forgot your password?',
    [Translations.LINK_PRIVACY_POLICY]: 'Privacy Policy',
    [Translations.LINK_TERMS_OF_SERVICE]: 'Terms of Service',

    [Translations.PLACEHOLDER_EMAIL]: 'Enter Email',
    [Translations.PLACEHOLDER_NAME]: 'Enter Name',
    [Translations.PLACEHOLDER_PASSWORD]: 'Enter Password',
    [Translations.PLACEHOLDER_SELECT_PRODUCT]: 'Select product',

    [Translations.PRODUCT_ITEM_BASIC]: 'Basic',
    [Translations.PRODUCT_ITEM_FRONT]: 'Front',
    [Translations.PRODUCT_ITEM_MIDDLE_LAYER_WATER_TEMPERATURE]: 'Middle Layer Water Temperature',
    [Translations.PRODUCT_ITEM_TIDE]: 'Tide',

    [Translations.PRODUCT_NAME_KYOTO]: 'Kyoto',
    [Translations.PRODUCT_NAME_SAGA_GENKAI]: 'Saga Genkai',
    [Translations.PRODUCT_NAME_KAGAWA]: 'Kagawa',
    [Translations.PRODUCT_NAME_CHIBA_SATOBO]: 'Chiba Sotobo',
    [Translations.PRODUCT_NAME_CHIBA_UCHIBO]: 'Chiba Uchibo',
    [Translations.PRODUCT_NAME_CHIBA_KUJUKURI]: 'Chiba Kujukuri',
    [Translations.PRODUCT_NAME_ARIAKE]: 'Ariake',
    [Translations.PRODUCT_NAME_YATSUSHIRO]: 'Yatsushiro',
    [Translations.PRODUCT_NAME_KAGOSHIMA_WEST]: 'Kagoshima West',
    [Translations.PRODUCT_NAME_SAGAMIWAN]: 'Sagamiwan Bay',
    [Translations.PRODUCT_NAME_OSHIKA]: 'Oshika',
    [Translations.PRODUCT_NAME_ENSYUNADA]: 'Ensyunada',
    [Translations.PRODUCT_NAME_SURUGAWAN]: 'Surugawan',
    [Translations.PRODUCT_NAME_NAGASAKI]: 'Nagasaki',
    [Translations.PRODUCT_NAME_YAMAGUCHI]: 'Yamaguchi',
    [Translations.PRODUCT_NAME_KOCHI]: 'Kochi',
    [Translations.PRODUCT_NAME_WAKAYAMA]: 'Wakayama',
    [Translations.PRODUCT_NAME_HACHIJO]: 'Hachijo',
    [Translations.PRODUCT_NAME_TSUSHIMA]: 'Tsushima',
    [Translations.PRODUCT_NAME_CHIBA_KATSUURA]: 'Chiba Katsuura',
    [Translations.PRODUCT_NAME_IBARAKI]: 'Ibaraki',
    [Translations.PRODUCT_NAME_KOCHI_WEST]: 'Kochi West',
    [Translations.PRODUCT_NAME_IZU_EAST]: 'Izu East',
    [Translations.PRODUCT_NAME_IZU_SOUTH]: 'Izu South',
    [Translations.PRODUCT_NAME_MIYAKEJIMA]: 'Miyakejima',
    [Translations.PRODUCT_NAME_TOKYOWAN]: 'Tokyowan',
    [Translations.PRODUCT_NAME_OSAKA]: 'Osaka',
    [Translations.PRODUCT_NAME_AICHI]: 'Aichi',
    [Translations.PRODUCT_NAME_MIE]: 'Mie',
    [Translations.PRODUCT_NAME_TOKYOCHIBA]: 'Tokyochiba',

    [Translations.SUCCESS_CARD_REGISTRATION]: 'Card is successfully registered',
    [Translations.SUCCESS_CONTRACT_UPDATED]: 'Contract was updated successfully',
    [Translations.SUCCESS_PASSWORD_RESET_LINK]: 'Password reset link successfully sent!',

    [Translations.TITLE_ADD_CONTRACT]: 'Add Contract',
    [Translations.TITLE_CARD]: 'Card',
    [Translations.TITLE_CHECK_EMAIL]: 'Check Email',
    [Translations.TITLE_CONTRACTS]: 'Contract Information',
    [Translations.TITLE_CREDIT_CARD_REGISTRATION]: 'Credit Card Registration',
    [Translations.TITLE_EDIT_CONTRACT]: 'Edit Contract',
    [Translations.TITLE_LOG_IN]: 'Login',
    [Translations.TITLE_PASSWORD_RESET]: 'Password Reset',
    [Translations.TITLE_PURCHASE_HISTORY]: 'Purchase History',
    [Translations.TITLE_REGISTER]: 'Register',
    [Translations.TITLE_SETTINGS]: 'Settings',
    [Translations.TITLE_USAGE]: 'Usage',
  },
};
